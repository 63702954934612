import { ActivatedRouteSnapshot, RouterStateSnapshot, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { CaseComponent } from './case/case.component';
import { AuthGuardService } from './services/authguard.service';
import { LoginComponent } from './login/login.component';
import { ProfileComponent } from './settings/users/profile/profile.component';
import { SettingsComponent } from './settings/settings.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { UsersComponent } from './settings/users/users.component';
import { GroupsComponent } from './settings/groups/groups.component';
import { GroupComponent } from './settings/groups/group/group.component';
import { SettingsNavigationService } from './settings/settings-navigation.service';
import { NoSettingsComponent } from './settings/no-settings.component';
import { AcceptInvitationComponent } from './accept-invitation/accept-invitation.component';
import { AccountsComponent } from './settings/accounts/accounts.component';
import { AccountComponent } from './settings/accounts/account/account.component';
import { DashboardNavigationService } from './dashboard/dashboard-navigation.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FlowsComponent } from './dashboard/flows/flows.component';
import { HelpComponent } from './help/help.component';
import { AboutComponent } from './help/about/about.component';
import { HelpDocumentComponent } from './help/help-document/help-document.component';
import { NoHelpDocumentComponent } from './help/help-document/no-help-document.component';
import { HelpVideoComponent } from './help/help-video/help-video.component';
import { ReportsNavigationService } from './reports/reports-navigation.service';
import { ReportsComponent } from './reports/reports.component';
import { UsageReportComponent } from './reports/usage-report/usage-report.component';
import { DetailedReportComponent } from './reports/detailed-report/detailed-report.component';
import { NotFoundComponent } from './tools/not-found/not-found.component';

export const appRoutes: Routes = [
    {
        path: 'home',
        component: HomeComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'case/:caseId',
        component: CaseComponent,
        canActivate: [AuthGuardService],
        canDeactivate: [
            // https://v17.angular.io/api/router/CanDeactivateFn
            // _cr = currentRoute, _cs = currentState, ns = nextState
            // We only need the nextState (destination URL) for our evaluation
            (c: CaseComponent, _cr: ActivatedRouteSnapshot, _cs: RouterStateSnapshot, ns: RouterStateSnapshot) => {
                return c.canLeave(ns);
            }
        ],
        children: [
            {
                path: '',
                redirectTo: 'overview',
                pathMatch: 'full'
            },
            {
                path: 'overview',
                component: CaseComponent,
            },
            {
                path: 'dicom-viewer',
                component: CaseComponent,
            },
            {
                path: 'dbs-lead-location',
                component: CaseComponent,
            },
            {
                path: 'not-found',
                component: CaseComponent,
            },
            {
                path: 'tools',
                component: CaseComponent,
            },
        ]
    },
    {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        // No authentication guard since this must be accessible by people who have forgotten their passwords.
    },
    {
        path: 'reset-password',
        component: ResetPasswordComponent,
        // No authentication guard since this must be accessible by people who have forgotten their passwords.
    },
    {
        path: 'accept-invitation',
        component: AcceptInvitationComponent,
        // No authentication guard since this must be accessible by people who do not yet have passwords
    },
    {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [AuthGuardService],
        children: [
            {
                path: '',
                pathMatch: 'full',
                component: NoSettingsComponent,
                canActivate: [SettingsNavigationService],
            },
            {
                path: 'users',
                component: UsersComponent,
                canActivate: [SettingsNavigationService],
            },
            {
                path: 'user/:userId',
                component: ProfileComponent,
            },
            {
                path: 'groups',
                component: GroupsComponent,
                canActivate: [SettingsNavigationService],
            },
            {
                path: 'group/:groupId',
                component: GroupComponent,
            },
            {
                path: 'accounts',
                component: AccountsComponent,
                canActivate: [SettingsNavigationService],
            },
            {
                path: 'account/:accountId',
                component: AccountComponent,
            },
            {
                path: 'not-found',
                component: NotFoundComponent,
            },
        ]
    },
    {
        path: 'help',
        component: HelpComponent,
        canActivate: [AuthGuardService],
        children: [
            {
                path: '',
                redirectTo: 'about',
                pathMatch: 'full'
            },
            {
                path: 'about',
                component: AboutComponent
            },
            {
                path: 'doc',
                component: HelpDocumentComponent,
            },
            {
                path: 'video',
                component: HelpVideoComponent,
            },
            {
                path: 'na',
                component: NoHelpDocumentComponent,
            },
            {
                path: 'not-found',
                component: NotFoundComponent,
            },
        ]
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuardService],
        children: [
            {
                path: '',
                pathMatch: 'full',
                component: NotFoundComponent,
                canActivate: [DashboardNavigationService],
            },
            {
                path: 'flows',
                component: FlowsComponent,
                canActivate: [DashboardNavigationService],
            },
        ]
    },
    {
        path: 'reports',
        component: ReportsComponent,
        canActivate: [AuthGuardService],
        children: [
            {
                path: '',
                pathMatch: 'full',
                component: NotFoundComponent,
                canActivate: [ReportsNavigationService],
            },
            {
                path: 'overview',
                component: UsageReportComponent,
                canActivate: [ReportsNavigationService],
            },
            {
                path: 'detailed',
                component: DetailedReportComponent,
                canActivate: [ReportsNavigationService],
            },

        ]
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: '**',
        redirectTo: 'home'
    },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home'
    }
];

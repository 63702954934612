import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, map, skip, startWith } from 'rxjs/operators';

export function atLeastOneOf(sources: Array<Observable<boolean>>): Observable<boolean> {
    // Given an array of observables, each emitting booleans, combine them such that the resulting
    // observable emits true if the most recent value from any of the component values was true.

    // If there are no sources, then emit a single false value.
    if (sources.length === 0) {
        return of(false);
    }

    // If there is only a single source, then just emit distinct successive values from that source.
    if (sources.length === 1) {
        return sources[0].pipe(distinctUntilChanged());
    }

    // We want to see results any time any of the source observables emits a value, because the "or"
    // below can short-circuit. To do this, we'll add a "false" prefix to each observable that will
    // emit a value immediately. Once each of the prefixed observables gets past its single fixed "false"
    // value, additional "real" values from the original observables will trigger additional outputs
    // from combineLatest() below.
    const withPrefix = sources.map(x => x.pipe(startWith(false)));

    return combineLatest(withPrefix).pipe(
        skip(1),  // Wait for at least one of the prefixed observables to get past the prefix
        map(data => data.some(x => x)),  // The result emits true if any of the sources emits true
        distinctUntilChanged(), // Only report transitions from false to true or true to false
    );
}


export function replay<T>(observable: Observable<T>, n: number): Observable<T> {
    // Make the most recent `n` values emitted by the given observable available to new subscribers.
    const result = new ReplaySubject<T>(n);
    observable.subscribe(result);
    return result;
}

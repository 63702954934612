<div class="main-nav-notifications-container" *ngIf="notifications.length > 0">
    <button mat-icon-button [matMenuTriggerFor]="notificationsMenu">
        <mat-icon style="color: white;">{{icon}}</mat-icon>
    </button>
    <span *ngIf="unread" class="notification-dot"></span>
    <mat-menu #notificationsMenu>
        <button mat-menu-item *ngFor="let n of notifications" (click)="showNotification(n)" [ngClass]="nClass(n)">
            <span>{{n.title}}</span>
        </button>
    </mat-menu>
</div>

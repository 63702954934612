import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { Subscriptions } from '../../../tools/subscriptions.class';
import { User, UserService } from '../../../services/user.service';
import { AuthenticationEvent, AuthService } from '../../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PermissionsService } from '../../../services/permissions.service';
import { Permission, Permissions } from '../../../security/permissions.class';
import { MatDialog } from '@angular/material/dialog';
import { AllFeatures } from '../../../security/feature.class';
import { AllUserPermissions } from '../../../security/user-permissions.class';
import { AllRoles, Role } from '../../../security/role.class';
import { TransferCasesComponent, TransferQueries, TransferUser } from '../transfer-cases/transfer-cases.component';
import { ConfirmationTemplate, ConfirmCancelDialogComponent } from '../../../tools/confirm-dialog.component';
import { Case, CaseService } from '../../../case/case.service';
import { Observable } from 'rxjs';
import { Account, Archived, ContextInformation } from '../../../services/api.service';
import { AccountService } from 'src/app/services/account.service';
import { AutoCompleteItem } from '../../../tools/context-item.class';

interface UserProfileForm {
    email: FormControl<string>;
    first_name: FormControl<string>;
    last_name: FormControl<string>;
    phone: FormControl<string>;
    account_id: FormControl<string>;
    permissions: FormArray<FormControl<boolean>>;
    enabled_features: FormArray<FormControl<boolean>>;
    role: FormControl<Role>;
    disabled: FormControl<boolean>;
    home_context: FormControl<ContextInformation>;
}

export class ProfileTransferQueries implements TransferQueries {
    constructor(private caseService: CaseService, private userService: UserService) {

    }

    getCases(oldUserId: string, archived: Archived = Archived.exclude): Observable<Case[]> {
        return this.caseService.getCasesForUser(oldUserId, {archived: Archived[archived]});
    }

    getUsers(): Observable<TransferUser[]> {
        return this.userService.getUsers();
    }
}

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit, OnDestroy {
    private currentUserId: string = null;  // current authenticated user
    public userId: string = null;
    public visibleAccounts: Account[] = [];

    public permitTransferCase = false;
    public permitDelete = false;
    public permitViewAccount = false;
    public permitViewCases = true;  // if you can see the user, you can see their cases
    flash = {
        timer: null,
        visible: false,
        messageKey: '',
        isError: false,
        timeout: 1500,
        callback: null
    };

    emailControl = new FormControl<string>({value: '', disabled: true});
    firstNameControl = new FormControl<string>({value: '', disabled: true});
    lastNameControl = new FormControl<string>({value: '', disabled: true});
    phoneControl = new FormControl<string>({value: '', disabled: true});
    accountIdControl = new FormControl<string | null>({value: null, disabled: true});
    contextControl = new FormControl<ContextInformation | null>({value: null, disabled: true});
    permissionsArray = new FormArray<FormControl<boolean>>(AllUserPermissions.map(() => new FormControl<boolean>({
        value: false,
        disabled: true
    })));
    enabledFeaturesArray = new FormArray<FormControl<boolean>>(AllFeatures.map(() => new FormControl<boolean>({
        value: false,
        disabled: true
    })));
    roleControl = new FormControl<Role | null>({value: null, disabled: true});
    disabledControl = new FormControl<boolean>({value: false, disabled: true});

    userProfileForm = new FormGroup<UserProfileForm>({
        email: this.emailControl,
        first_name: this.firstNameControl,
        last_name: this.lastNameControl,
        phone: this.phoneControl,
        account_id: this.accountIdControl,
        permissions: this.permissionsArray,
        enabled_features: this.enabledFeaturesArray,
        role: this.roleControl,
        disabled: this.disabledControl,
        home_context: this.contextControl
    });

    private lifetimeSubscriptions = new Subscriptions();
    private subscriptions = new Subscriptions();

    constructor(private activatedRoute: ActivatedRoute, private userService: UserService,
                private authService: AuthService, private permissionService: PermissionsService,
                private caseService: CaseService, private accountService: AccountService,
                private router: Router, private dialog: MatDialog) {
    }

    get accounts(): Account[] {
        return this.visibleAccounts;
    }

    get roles(): Role[] {
        return AllRoles;
    }

    get permitSave(): boolean {
        return this.formKeys().some(key => this.userProfileForm.controls[key].enabled);
    }

    ngOnInit() {
        this.lifetimeSubscriptions.add(this.authService.onAuthState, (event: AuthenticationEvent) => {
            if (event.authenticated) {
                this.lifetimeSubscriptions.add(this.activatedRoute.params, () => this.loadDetails());
            }
        });
    }

    private loadDetails(): void {
        const routedSnapshotUserId = this.activatedRoute.snapshot.params.userId;
        this.currentUserId = this.authService.currentUserId;
        this.userId = routedSnapshotUserId ? routedSnapshotUserId : this.currentUserId;

        this.subscriptions.cancel();
        this.refreshUserPermissions();

        this.userProfileForm.markAsPristine();
        this.loadUser();
        this.loadAccounts();
    }

    private refreshUserPermissions() {
        this.subscriptions.add(this.permissionService.permissions(), () => {
            const prefetchPermissions = this.formKeys().map(key => Permissions.userModify(this.userId, [key]));
            prefetchPermissions.push(Permissions.anyCaseTransfer());
            prefetchPermissions.push(Permissions.userDelete(this.userId));
            prefetchPermissions.push(Permissions.userAdmin());

            this.permissionService.prefetch(prefetchPermissions);

            this.forEachFormControl((key, control) => {
                this.subscriptions.add(
                    this.permissionService.hasPermission(Permissions.userModify(this.userId, [key])),
                    allowed => {
                        allowed ? control.enable({onlySelf: true}) : control.disable({onlySelf: true});
                    },
                    error => {
                        console.log(error.toString());
                    }
                );
            });

            this.subscriptions.add(this.permissionService.hasPermission(Permissions.anyCaseTransfer()), allowed => {
                this.permitTransferCase = allowed;
            });

            this.subscriptions.add(this.permissionService.hasPermission(Permissions.userDelete(this.userId)), allowed => {
                this.permitDelete = allowed && this.userId !== this.currentUserId;
            });

            this.subscriptions.add(this.permissionService.hasPermission(Permissions.userAdmin()), allowed => {
                this.permitViewAccount = allowed;
            });
        });
    }

    ngOnDestroy(): void {
        this.userId = null;
        this.lifetimeSubscriptions.cancel();
        this.subscriptions.cancel();
        this.clearTimer();
    }

    formKeys(): string[] {
        return Object.keys(this.userProfileForm.controls);
    }

    forEachFormControl(f: (key: string, control: AbstractControl) => void) {
        Object.keys(this.userProfileForm.controls).forEach(k => f(k, this.userProfileForm.controls[k]));
    }

    saveUser() {
        const updates = {} as any;
        this.forEachFormControl((key, control) => {
            // Only update fields that were allowed to change and that have changed
            if (control.enabled && control.dirty) {
                updates[key] = control.value;
            }
        });

        // We cannot save null account id's, so make sure they are not present.
        if (updates.account_id !== undefined && updates.account_id === null) {
            delete updates.account_id;
        }

        // Transform the permissions value (if any) to the form expected by the API
        if (this.permissionsArray.enabled && updates.permissions !== undefined) {
            updates.permissions = this.asStringArray(updates.permissions, AllUserPermissions);
        }

        // Transform the enabled features value (if any) to the form expected by the API
        if (this.enabledFeaturesArray.enabled && updates.enabled_features !== undefined) {
            updates.enabled_features = this.asStringArray(updates.enabled_features, AllFeatures);
        }

        this.userService.updateUser(this.userId, updates).subscribe({
            next: () => {
                this.loadUser();
                this.flashMessage('settingsUserProfileUpdateSuccess', false);
            },
            error: () => {
                this.flashMessage('settingsUserProfileUpdateFailure', true);
            }
        });
    }

    getPermissionLabel(index: number): string {
        return AllUserPermissions[index].key;
    }

    getFeatureLabel(index: number): string {
        return AllFeatures[index].key;
    }

    disableSave(): boolean {
        return !this.permitSave || !this.userProfileForm.dirty;
    }

    get accountId(): string | null {
        return this.accountIdControl.value;
    }

    hasAccount(): boolean {
        return this.accountId !== null;
    }

    canViewAccount(): Permission | null {
        return this.accountId ? Permissions.accountView(this.accountId) : null;
    }

    accountTarget(): string[] | null {
        return this.accountId ? ['/settings/account', this.accountId] : null;
    }

    viewCases(metaKey: boolean) {
        // the userId key need to match the USER_CONTEXT_KEY in context-item.class.ts
        const extras = {queryParams: {userId: this.userId}};
        const url = this.router.serializeUrl(this.router.createUrlTree(['/home'], extras));
        metaKey ? window.open(url, '_blank') : this.router.navigate(['/home'], extras).then();
    }

    transferCases() {
        const transferCasesDialogOptions = {
            height: '640px',
            width: '960px',
            data: {
                queries: new ProfileTransferQueries(this.caseService, this.userService),
                oldUserId: this.userId
            },
        };
        /*let dialogRef =*/
        this.dialog.open(TransferCasesComponent, transferCasesDialogOptions);
    }

    deleteUser() {
        this.subscriptions.add(this.caseService.countCasesForUser(this.userId, Archived.include), caseCount => {
            const confirmationTemplate: ConfirmationTemplate = {
                // keys to lang.json translate document
                title: 'settingsUsersDeleteUserDialogTitle',
                contentText: 'settingsUsersDeleteUserDialogContent',
                cancelText: 'cancel',
                actionText: 'delete'
            };
            if (caseCount > 0) {
                confirmationTemplate.contentText = 'settingsUsersDeleteUserDialogContentWithCases';
                confirmationTemplate.preliminaryConfirmationText = `settingsUsersDeleteUserDialogPrelim`;
            }
            const dialogRef = this.dialog.open(ConfirmCancelDialogComponent, {
                width: '400px', data: confirmationTemplate
            });

            this.subscriptions.add(dialogRef.afterClosed(), data => {
                if (data && this.permitDelete) {
                    this.userService.deleteUser(this.userId).subscribe({
                        next: () => {
                            this.permitDelete = false;
                            this.flashMessage('settingsUsersProfileDeleteSuccess', false, () => {
                                // noinspection JSIgnoredPromiseFromCall
                                this.router.navigate(['/settings/users']);
                            });
                        },
                        error: () => {
                            this.flashMessage('settingsUsersProfileDeleteError', true);
                        }
                    });
                }
            });
        });
    }

    private loadUser() {
        this.userService.getUser(this.userId).subscribe({
            next: (userData: User) => {
                const transformedData = {...userData} as any;
                if (transformedData.permissions !== undefined) {
                    transformedData.permissions = this.asBooleanArray(transformedData.permissions, AllUserPermissions);
                }
                if (transformedData.enabled_features !== undefined) {
                    transformedData.enabled_features = this.asBooleanArray(transformedData.enabled_features, AllFeatures);
                }
                this.forEachFormControl((key, control) => {
                    if (Object.prototype.hasOwnProperty.call(transformedData, key)) {
                        control.setValue(transformedData[key]);
                    }
                });
                this.userProfileForm.markAsPristine();
            },
            error: () => {
                this.router.navigate(['settings/not-found']).then();
            }
        });
    }

    private loadAccounts() {
        this.subscriptions.limit(this.accountService.getAccounts()).subscribe(accounts => {
            this.visibleAccounts = accounts;
            this.visibleAccounts.sort((a, b) => a.name.localeCompare(b.name));
        });
    }

    private flashMessage(messageKey: string, isError: boolean, callback: any = null) {
        // Trigger any existing callback before replacing it.
        this.triggerFlashCallback();

        this.clearTimer();

        this.flash.visible = true;
        this.flash.messageKey = messageKey;
        this.flash.isError = isError;
        this.flash.callback = callback;

        this.flash.timer = setTimeout(() => {
            this.flash.visible = false;
            this.flash.timer = null;
            this.triggerFlashCallback();
        }, this.flash.timeout);
    }

    private clearTimer(): void {
        if (this.flash.timer) {
            clearTimeout(this.flash.timer);
            this.flash.timer = null;
        }
    }

    private triggerFlashCallback() {
        if (this.flash.callback) {
            const callback = this.flash.callback;
            this.flash.callback = null;
            callback();
        }
    }

    private asBooleanArray(features: string[], choices: { name: string }[]): boolean[] {
        return choices.map(f => features.includes(f.name));
    }

    private asStringArray(features: boolean[], choices: { name: string }[]): string[] {
        return choices.filter((_f, index) => features[index]).map(f => f.name);
    }

    public onContextChange(event: AutoCompleteItem) {
        const newContext: ContextInformation = event ? event.contextInfo : null;
        if (this.contextControl.value === null && newContext === null) {
            return;
        }
        if (this.contextControl.value === null || newContext === null || !Object.keys(newContext).every(k => newContext[k] === this.contextControl.value[k])) {
            this.contextControl.setValue(newContext);
            this.contextControl.markAsDirty();
            return;
        }
    }
}

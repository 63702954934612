<mat-card appearance="outlined" class="results-review-container">
    <mat-card-title class="sis-card-title">
        <p class="mat-typography results-review-title">Results Review</p>
    </mat-card-title>
    <mat-card-content class="results-review-content">
        <mat-card appearance="outlined" class="results-review-card">
            <mat-card-content class="results-review-card-content">
                <app-case-admin-create-mrb
                        class="results-review-create-mrb"
                        *ngIf="allowedAdvancedReview"
                        [caseUpdated]="caseUpdated">
                </app-case-admin-create-mrb>
            </mat-card-content>
        </mat-card>
        <mat-card appearance="outlined" class="results-review-view-logs-card">
            <mat-card-title class="results-review-view-log-title">View Log Files</mat-card-title>
            <mat-card-content class="results-review-log-selection">
                <mat-select class="results-review-flow-selection"
                            [(ngModel)]="selectedFlow"
                            [disabled]="logElements.size === 0">
                    <mat-option *ngFor="let f of flowsList" [value]="f">{{formatFlow(f)}}</mat-option>
                </mat-select>
                <button mat-raised-button
                        [disabled]="disableSubmitFlow()"
                        (click)="showFlowLog()">
                    View Log
                </button>
            </mat-card-content>
        </mat-card>
    </mat-card-content>
</mat-card>

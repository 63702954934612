import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuItem } from '../tools/menu-item/menu-item.interface';
import { filter } from 'rxjs/operators';
import { Subscriptions } from '../tools/subscriptions.class';

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit, OnDestroy {

    public static snapshotRoute = '/reports/overview';
    public static detailsRoute = '/reports/detailed';

    readonly OVERVIEW = 'overview';
    readonly DETAILED = 'detailed';

    reportsMenu: Array<MenuItem> = [
        {titleKey: 'reportsMenuSnapshot', route: ReportsComponent.snapshotRoute},
        {titleKey: 'reportsMenuDetails', route: ReportsComponent.detailsRoute}
    ];

    private subscriptions = new Subscriptions();
    selectedView = this.OVERVIEW;

    constructor(public router: Router) {
    }

    ngOnInit(): void {
        this.subscriptions.limit(this.router.events).pipe(
            filter(event => event instanceof NavigationEnd)).subscribe(
            (event: NavigationEnd) => this.onRouterEvent(event.url)
        );
        this.onRouterEvent(this.router.routerState.snapshot.url);
    }

    private onRouterEvent(url: string): void {
        for (const view of [this.OVERVIEW, this.DETAILED]) {
            if (url.endsWith(view)) {
                this.selectedView = view;
                return;
            }
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.cancel();
    }
}

<mat-card>
    <mat-card-title class="upload-header">
        <div class="upload-header-text">
            <span class="mat-typography upload-title">{{ uploadType }} DICOM</span>
            <span class="mat-typography upload-sub-title" i18n="@@caseUploadDicomSubTitle">
                {{ 'caseUploadDicomSubTitle'|translate: {'formatsList': supportedFormats.join(', ')} }}
            </span>
        </div>
    </mat-card-title>
    <mat-card-content>
        <div class="upload-report">
            <p class="mat-typography upload-report-item" i18n="@@caseUploadDicomUploadedFilesTitle">
                <b>{{ 'caseUploadDicomUploadedFilesTitle'|translate }} (&ge; {{ getMinFileCount() }}):</b>
                <span [ngClass]="getFileCountClass()"> {{ getFileCount() }}</span>
            </p>
            <div class="mat-typography upload-report-details">
                <div class="upload-report-sequence">
                    <p style="margin: 0 10px 0 0;" i18n="@@caseUploadDicomDetectedSequenceTitle">
                        <b>{{ 'caseUploadDicomDetectedSequenceTitle'|translate }}:</b>&nbsp;
                    </p>
                    <mat-icon
                            *ngIf="hasSequence()"
                            [ngClass]="getSequenceClass()"
                            [matTooltip]="getUploadTooltip()| translate: {'type': uploadType}"
                            matTooltipPosition="after">
                        done
                    </mat-icon>
                </div>
                <p style="margin: 0 0 0 0;" i18n="@@caseUploadDicomSliceThicknessTitle">
                    <b>{{ 'caseUploadDicomSliceThicknessTitle'|translate }}&nbsp;(&le;&nbsp;{{ getMaxSpacing() }}
                        mm):</b>
                    <span *ngIf="getSliceThickness() > -1"
                          [ngClass]="thicknessClass()">&nbsp;{{ getSliceThickness().toFixed(2) }}</span>
                </p>
                <p style="margin: 0 0 0 0;" i18n="@@caseUploadDicomSpacingTitle">
                    <span>
                        <span><b>{{ 'caseUploadDicomSpacingTitle'|translate }}&nbsp;(mm&nbsp;|&nbsp;X,Y</b></span>
                        <!-- if we have an x and y spacing but no z, it is an "old" report, do not show the Z -->
                        <span *ngIf="!(xSpacing !== null && ySpacing !== null && zSpacing.length === 0)"><b>,Z</b></span>
                        <span><b>&nbsp;|&nbsp;&le;&nbsp;{{ getMaxSpacing() }}mm):&nbsp;</b></span>
                    </span>
                    <span *ngIf="xSpacing !== null" [ngClass]="spacingClass(xSpacing)">{{ xSpacing.toFixed(2) }}</span>
                    <span *ngIf="ySpacing !== null">,&nbsp;</span>
                    <span *ngIf="ySpacing !== null" [ngClass]="spacingClass(ySpacing)">{{ ySpacing.toFixed(2) }}</span>
                    <span *ngIf="zSpacing.length > 0">,&nbsp;</span>
                    <span *ngIf="zSpacing.length > 0">
                        <span *ngIf="zSpacing.length > 1">[</span>
                        <span [ngClass]="spacingClass(zSpacing[0])">{{ zSpacing[0].toFixed(2) }}</span>
                        <span *ngIf="zSpacing.length > 1">,&nbsp;</span>
                        <span *ngIf="zSpacing.length > 1"
                              [ngClass]="spacingClass(zSpacing[1])">{{ zSpacing[1].toFixed(2) }}</span>
                        <span *ngIf="zSpacing.length > 1">]</span>
                    </span>
                </p>
            </div>
            <p class="mat-typography upload-report-item" style="margin-bottom: 10px;"
               i18n="@@caseUploadDicomUploadErrors" matTooltip="{{uploadErrors}}">
                <b>{{ 'caseUploadDicomUploadErrors'|translate }}:</b> {{ uploadErrors }}
            </p>
        </div>
        <div class="upload-progress">
            <mat-progress-bar
                    mode="{{getProgressMode()}}"
                    color="primary"
                    value="{{progress}}">
            </mat-progress-bar>
        </div>
        <div class="upload-progress-report">
            <p class="mat-typography" *ngIf="!uploadError">{{ progressMessage }}</p>
            <p class="mat-typography upload-error" *ngIf="uploadError">{{ uploadError.message }}</p>
        </div>
    </mat-card-content>
</mat-card>

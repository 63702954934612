import { Injectable, OnDestroy } from '@angular/core';
import { ApiService, PermissionsResponseItem } from './api.service';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { AuthenticationState, AuthService } from './auth.service';
import { Subscriptions } from '../tools/subscriptions.class';
import { switchMap } from 'rxjs/operators';

// A permission is an array, each element of which is a string or an array of strings
type PermissionElement = string | string[];
type Permission = PermissionElement[];

export class PermissionAllowed {
    permission: Permission;
    allowed: boolean;
}

class CacheNode {
    allowed: Subject<boolean>;
    children: { [key: string]: CacheNode };

    constructor() {
        this.allowed = null;
        this.children = {};
    }
}

@Injectable()
export class PermissionsService implements OnDestroy {

    /*
     * A typical usage pattern should use a double subscription. The outer subscription is used to
     * regenerate the inner subscriptions when the effective permissions change (e.g., the user logs
     * in or out). The inner subscriptions will *not* broadcast changes when the permissions change,
     * they only emit when the results for the current user become available.
     *
     *    permissionsService.permissions().subscribe(() => {
     *        permissionsService.hasPermission(permission).subscribe((allowed) => {
     *            // use `allowed`
     *        });
     *    }
     */

    // A subject that will emit a value every time permissions change (due to a user logging in or out,
    // for example).
    private refresh: Subject<any>;

    // The root of a tree of cache nodes. The entry for a particular permission is found by walking the tree
    // using the elements of the permission.
    private cacheRoot = new CacheNode();

    // A single subject to use to respond to permission requests when there is no current user.
    private disallowed = new BehaviorSubject<boolean>(false);

    // A single subject to use to respond to permission requests when authentication is pending. No values will ever
    // be sent from this subject and it never completes.
    private pending = new Subject<boolean>();

    // A single subject to inform clients whether any permission requests are outstanding.
    public readonly anyPending = new BehaviorSubject<boolean>(false);
    private pendingCount = 0;

    private lifetimeSubscriptions = new Subscriptions();
    private perAuthSubscriptions = new Subscriptions();

    constructor(private api: ApiService, private auth: AuthService) {
        this.refresh = new BehaviorSubject<any>(true);

        this.lifetimeSubscriptions.add(auth.onAuthState, () => this.invalidate());
    }

    public ngOnDestroy() {
        this.lifetimeSubscriptions.cancel();
        this.perAuthSubscriptions.cancel();
    }

    public permissions(): Observable<any> {
        return this.refresh.asObservable();
    }

    public hasPermission(permission: Permission): Observable<boolean> {
        return this.permissions().pipe(switchMap(_ => this.hasPermissionImpl(permission)));
    }

    public hasPermissions(permissions: Permission[]): Observable<PermissionAllowed> {
        return this.permissions().pipe(
            switchMap(_ => this.hasPermissionsImpl(permissions))
        );
    }

    public prefetch(permissions: Permission[]): void {
        // Submit a single request for multiple permissions simultaneously. Responses for individual permissions
        // can then be obtained via hasPermission().
        this.hasPermissionsImpl(permissions);
    }

    public batch(subscriptions: Subscriptions, requests: Array<[Permission, (allowed: boolean) => any]>) {
        // Submit a batch of permission requests, and subscribe to each of the responses using the paired callback.
        const permissions: Array<Permission> = requests.map(pair => pair[0]);

        this.prefetch(permissions);

        requests.forEach(value => {
            subscriptions.add(this.hasPermission(value[0]), value[1]);
        });
    }

    public invalidate() {
        // Cancel any outstanding requests
        this.perAuthSubscriptions.cancel();

        // Recreate the cache
        this.cacheRoot = new CacheNode();

        // Signal that no requests are outstanding
        if (this.pendingCount > 0) {
            this.anyPending.next(false);
            this.pendingCount = 0;
        }

        // Signal any refresh subscribers that they should re-request permissions
        this.refresh.next(true);
    }

    private hasPermissionImpl(permission: Permission): Observable<boolean> {
        const cacheNode = this.cacheNode(permission);
        if (cacheNode.allowed !== null) {
            return cacheNode.allowed;
        }

        switch (this.auth.getAuthenticationState()) {
            case AuthenticationState.Valid:
                // Since we are caching the results, we want new subscribers to see any past responses already received
                // (or responses already requested but not yet received).
                cacheNode.allowed = new ReplaySubject<boolean>(1);
                this.requestStarting();
                this.perAuthSubscriptions.add(this.api.getPermission(permission),
                    (response) => {
                        cacheNode.allowed.next(response.allowed);
                    },
                    null,
                    () => {
                        this.requestFinished();
                    }
                );
                break;

            case AuthenticationState.Invalid:
                cacheNode.allowed = this.disallowed;
                break;

            case AuthenticationState.Pending:
                cacheNode.allowed = this.pending;
                break;
        }

        return cacheNode.allowed;
    }

    private hasPermissionsImpl(permissions: Permission[]): Observable<PermissionAllowed> {
        const result = new ReplaySubject<PermissionAllowed>();

        // We only need to prefetch permissions that have not already been fetched
        const permissionsToFetch: Permission[] = [];
        const authState = this.auth.getAuthenticationState();
        let count = 0;

        function emit(permission: Permission, allowed: boolean) {
            result.next({permission, allowed});
            count += 1;
            if (count === permissions.length) {
                result.complete();
            }
        }

        for (const permission of permissions) {
            const cacheNode = this.cacheNode(permission);
            // If we already have a subject for the permission, subscribe to its outputs and
            // forward them to our subject.
            if (cacheNode.allowed) {
                this.perAuthSubscriptions.add(cacheNode.allowed, allowed => {
                    emit(permission, allowed);
                });
            }
            else if (authState === AuthenticationState.Valid) {
                // Create a new subject for this permission; it won't be used to satisfy this invocation,
                // but other invocations of either hasPermission() or hasPermissions() can be satisfied by
                // the response retrieved from our API call below.
                cacheNode.allowed = new ReplaySubject<boolean>(1);
                permissionsToFetch.push(permission);
            }
            else if (authState === AuthenticationState.Invalid) {
                // If there is no authenticated user, nothing is allowed.
                cacheNode.allowed = this.disallowed;
                emit(permission, false);
            }
            else if (authState === AuthenticationState.Pending) {
                // If there is no authenticated user yet, report nothing.
                cacheNode.allowed = this.pending;
            }
        }

        if (permissionsToFetch.length > 0) {
            this.requestStarting();
            this.perAuthSubscriptions.add(this.api.getPermissions(permissionsToFetch),
                (permissionResponseItems: PermissionsResponseItem[]) => {
                    for (const item of permissionResponseItems) {
                        const cacheNode = this.cacheNode(item.permission);
                        // We should only get results back for permissions that we requested, and subjects were
                        // created above for those permissions. Still, be safe.
                        if (cacheNode.allowed) {
                            cacheNode.allowed.next(item.allowed);
                        }
                        emit(item.permission, item.allowed);
                    }
                },
                error => {
                    console.log(`Error fetching permissions ${permissionsToFetch}: ${error}`);
                },
                () => {
                    this.requestFinished();
                }
            );
        }
        else {
            result.complete();
        }

        return result.asObservable();
    }

    private requestStarting() {
        this.pendingCount += 1;
        if (this.pendingCount === 1) {
            this.anyPending.next(true);
        }
    }

    private requestFinished() {
        if (this.pendingCount === 0) {
            // This would imply getting multiple responses to a single request, or some kind of programming error.
        }
        else {
            this.pendingCount -= 1;
            if (this.pendingCount === 0) {
                this.anyPending.next(false);
            }
        }
    }

    private cacheNode(permission: Permission): CacheNode {
        // Walk the entry tree to find the correct entry, creating it (and any intermediate entries) if necessary.
        let node = this.cacheRoot;
        for (const p of permission) {
            const k: string = p === null ? '*null*' : (typeof p === 'string') ? p : p.join('+');
            if (k in node.children) {
                node = node.children[k];
            }
            else {
                node = node.children[k] = new CacheNode();
            }
        }
        return node;
    }
}

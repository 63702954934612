<h2 mat-dialog-title i18n="@@settingsGroupsAddMemberTitle">{{'settingsGroupsAddMemberTitle'|translate}}</h2>
<form [formGroup]="addMemberForm" novalidate>
    <mat-dialog-content>
        <div *ngIf="queryPending">
            <p class="mat-typography">
                {{'settingsGroupsAddMemberCandidatesPending'|translate}}
            </p>
        </div>
        <div *ngIf="!queryPending && candidateUsers.length > 0">
            <p>
                <mat-form-field style="width: 80%" *ngIf="candidateUsers.length > 0" data-testid="group-invite-select">
                    <mat-label>{{'settingsGroupsAddMemberUserPlaceholder'|translate}}</mat-label>
                    <input matInput type="text" [formControl]="user" [matAutocomplete]="auto">
                    <mat-autocomplete
						#auto="matAutocomplete"
                        [displayWith]="displayUser">
                        <mat-option *ngFor="let ca of filteredUsers | async" [value]="ca" data-testid="group-invite-users">
                            {{displayUser(ca)}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </p>
            <p>
                <mat-checkbox [formControl]="groupAdmin" i18n="@@settingsGroupsAddMemberIsAdmin"
                              data-testid="group-invite-admin">
                    {{'settingsGroupsAddMemberIsAdmin'|translate}}
                </mat-checkbox>
            </p>
            <p>
                <mat-checkbox [formControl]="caseManager" i18n="@@settingsGroupsAddMemberCanEditCases"
                              data-testid="group-invite-case-manager">
                    {{'settingsGroupsAddMemberCanEditCases'|translate}}
                </mat-checkbox>
            </p>
        </div>
        <div *ngIf="!queryPending && candidateUsers.length === 0">
            <p class="mat-typography">
                {{'settingsGroupsAddMemberNoCandidatesAvailable'|translate}}
            </p>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button i18n="@@settingsGroupsAddMemberButtonCancel"
                [mat-dialog-close]=null mat-raised-button data-testid="group-invite-cancel">
            {{'cancel'|translate}}
        </button>
        <button *ngIf="!queryPending && candidateUsers.length > 0"
                i18n="@@settingsGroupsAddMemberButtonInvite"
                (click)="onInvite()"
                [disabled]="disableInvitation()"
                data-testid="group-invite-confirm"
                mat-raised-button>
            {{'invite'|translate}}
        </button>
    </mat-dialog-actions>
</form>

import { Injectable } from '@angular/core';
import { NavigationService, NavigationServiceEntry, stableTransitions } from '../tools/navigation.service';
import { PermissionsService } from '../services/permissions.service';
import { AuthService } from '../services/auth.service';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Permissions } from '../security/permissions.class';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class ReportsNavigationService extends NavigationService {

    constructor(permissionsService: PermissionsService, authService: AuthService, router: Router) {
        super(permissionsService, authService, router);
    }

    canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
        if (state.url.endsWith('/overview')) {
            return this.maybeRedirectChild(this.canActivateReports());
        }
        if (state.url.endsWith('/detailed')) {
            return this.maybeRedirectChild(this.canActivateReports());
        }
        else if (state.url.endsWith('/reports')) {
            const table = [
                new NavigationServiceEntry(this.canActivateReports(), 'overview'),
                new NavigationServiceEntry(this.canActivateReports(), 'detailed'),
            ];
            return this.maybeRedirect(state, table);
        }
        else {
            // This should only be used as a guard on paths that it knows how to handle.
            return false;
        }
    }

    public canActivateReports(): Observable<boolean> {
        const canActivateSubject = this.subscriptions.limit(this.permissionsService.permissions().pipe(
            switchMap(() => this.permissionsService.hasPermission(Permissions.reportsQuery()))
        ));
        return stableTransitions(canActivateSubject, this.permissionsService);
    }
}

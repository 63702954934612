<div *ngIf="showProgressBar()" class="upper-spacing-bar">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<div *ngIf="showContainer()" class="flex-container -full">
    <div class="dicom-container">
        <div class="upper-spacing-bar"></div>
        <div id="papaya1"></div>
    </div>
    <div *ngIf="showDownloadButtons()" class="dicom-selector-container">
        <div class="upper-spacing-bar"></div>
        <mat-radio-group style="margin-top: 20px;" [value]="activeItem.alpha" [disabled]="disableButtons()">
            <mat-radio-button style="margin-bottom: 20px;" *ngFor="let item of availableItems()"
                              [value]="item.alpha"
                              (change)="activateAlpha(item)">
                {{item.alpha.nameKey|translate}}
            </mat-radio-button>
        </mat-radio-group>
    </div>
</div>

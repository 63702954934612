<mat-sidenav-container class="fill-parent">
    <mat-sidenav class="sis-sidenav" mode="side" opened="true" disableClose>
        <div class="side-nav-top-space"></div>
        <mat-nav-list>
            <app-menu-item
                class="mat-typography"
                *ngFor="let item of helpMenuItems"
                [item]="item">
            </app-menu-item>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="sis-sidenav-content">
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>

import {Component, Input, OnDestroy} from '@angular/core';
import {Permission} from '../security/permissions.class';
import {PermissionsService} from '../services/permissions.service';
import {Observable, of, Subject} from 'rxjs';
import {Router} from '@angular/router';
import {first, switchMap, takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-secure-link',
    templateUrl: './secure-link.component.html',
    styleUrls: ['./secure-link.component.scss']
})
export class SecureLinkComponent implements OnDestroy {

    @Input() permission: Permission | null;
    @Input() target: string[];
    @Input() hideWhenForbidden = false;

    private destroyed$ = new Subject();

    constructor(private router: Router, private permissionsService: PermissionsService) {
    }

    ngOnDestroy() {
        this.destroyed$.next(null);
    }

    public canNavigate(): Observable<boolean> {
        if (!this.permission) {
            return of(false);
        }
        else {
            return this.permissionsService.permissions().pipe(
                switchMap(() => this.permissionsService.hasPermission(this.permission)),
                takeUntil(this.destroyed$)
            );
        }
    }

    public navigate() {
        this.canNavigate().pipe(first()).subscribe(allowed => {
            if (allowed) {
                this.router.navigate(this.target).then();
            }
        });
    }
}

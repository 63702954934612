import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ElectrodeModelService } from './electrode-model.service';
import { Subject } from 'rxjs';
import { Subscriptions } from '../../tools/subscriptions.class';
import { Case, CaseService, SubmitPostopRequirements } from '../case.service';
import { PermissionsService } from '../../services/permissions.service';
import { Permissions } from '../../security/permissions.class';
import { Features } from '../../security/feature.class';
import { ElectrodeSelectionResponse } from '../../services/api.service';
import { Elements } from '../case.constants';
import { ElectrodeModel } from './electrode-model.constants';

export interface ElectrodesUpdate {
    userDriven: boolean;
    leftSelection: ElectrodeModel;
    rightSelection: ElectrodeModel;
}

@Component({
    selector: 'app-case-electrode-models',
    templateUrl: './electrode-model.component.html',
    styleUrls: ['./electrode-model.component.scss']
})
export class ElectrodeModelComponent implements OnInit, OnDestroy {

    @Input() caseUpdated: Subject<Case>;
    @Output() electrodesUpdate = new EventEmitter<ElectrodesUpdate>();

    private availableModels: Array<ElectrodeModel> = [];
    caseData: Case = null;
    private serviceReady: boolean;
    private subscriptions = new Subscriptions();
    private allowAutoDetectElectrode = false;

    private defaultLeft: ElectrodeModel;
    private defaultRight: ElectrodeModel;
    private lastSavedLeft: ElectrodeModel;
    private lastSavedRight: ElectrodeModel;
    selectedLeft: ElectrodeModel | null = null;
    selectedRight: ElectrodeModel | null = null;

    constructor(private caseService: CaseService, private emService: ElectrodeModelService, private permissionService: PermissionsService) {
    }

    get electrodes(): Array<ElectrodeModel> {
        return this.availableModels;
    }

    ngOnInit() {
        this.permissionService.permissions().subscribe(() => {
                this.permissionService.hasPermission(Permissions.featureAvailable(Features.ENABLE_AUTO_DETECT)).subscribe(
                    (allowed: boolean) => {
                        this.allowAutoDetectElectrode = allowed;
                        this.initSelection(allowed);
                        this.availableModels = this.emService.getAvailableModels(this.allowAutoDetectElectrode);
                        // first, update the caseData for the active case
                        this.subscriptions.add(this.caseUpdated, (data: Case) => {
                            this.serviceReady = false;
                            this.caseData = data;
                            this.readLatestSelection(this.caseData);
                        });
                    }
                );
            }
        );
    }

    public initSelection(autoDetect: boolean): void {
        this.defaultLeft = autoDetect ? this.emService.autoDetect : this.emService.noSelection;
        this.defaultRight = autoDetect ? this.emService.autoDetect : this.emService.noSelection;
        this.lastSavedLeft = this.defaultLeft;
        this.lastSavedRight = this.defaultRight;
    }

    private readLatestSelection(caseData: Case) {
        // if we have the electrode selection in the data element, we can read it from case data
        const es = this.caseService.latestElement(caseData, Elements.USER_ELECTRODE_MODELS_SELECTION);
        if (es && es.value) {
            this.updateLatestSelection(es.value as ElectrodeSelectionResponse);
        }
        else {
            // Load the selection from the server, on the response, update the current selection
            this.subscriptions.add(this.emService.loadLatestSaved(this.caseData),
                (esData: ElectrodeSelectionResponse | null) => this.updateLatestSelection(esData)
            );
        }
    }

    private updateLatestSelection(data: ElectrodeSelectionResponse): void {
        if (data && ElectrodeModelService.ELECTRODES in data) {
            const electrodes = data[ElectrodeModelService.ELECTRODES];
            this.lastSavedLeft = this.emService.payloadToModel(electrodes.left);
            this.lastSavedRight = this.emService.payloadToModel(electrodes.right);
        }
        this.selectedLeft = this.lastSavedLeft;
        this.selectedRight = this.lastSavedRight;
        this.notifySelectionUpdate(false, this.lastSavedLeft, this.lastSavedRight);
        this.serviceReady = true;
    }

    private notifySelectionUpdate(fromUser: boolean, lModel: ElectrodeModel, rModel: ElectrodeModel): void {
        this.electrodesUpdate.next({userDriven: fromUser, leftSelection: lModel, rightSelection: rModel});
    }

    ngOnDestroy() {
        this.subscriptions.cancel();
        this.caseData = null;
    }

    onLeftSelection() {
        if (this.selectedLeft !== this.lastSavedLeft) {
            this.notifySelectionUpdate(true, this.selectedLeft, this.selectedRight);
        }
    }

    onRightSelection() {
        if (this.selectedRight !== this.lastSavedRight) {
            this.notifySelectionUpdate(true, this.selectedLeft, this.selectedRight);
        }
    }

    get electrodeSelectionPrompt(): string {
        return this.promptElectrodeSelection() ? 'caseElectrodeModelPromptSelection' : '';
    }

    /**
     * The warning to select a lead model should be presented if:
     * We have a new postop image uploaded and the 2 sides are selected as None
     */
    public promptElectrodeSelection(): boolean {
        const missingElements = this.caseService.itemsMissingForPostopSubmission(this.caseData, this.allowAutoDetectElectrode);
        const missingSelection = this.compareArrays(missingElements, [SubmitPostopRequirements.MISSING_ELECTRODE_SELECTION]);
        const invalidSelection = this.compareArrays(missingElements, [SubmitPostopRequirements.INVALID_ELECTRODE_SELECTION]);
        return this.serviceReady && this.caseService.hasNewPostopImage(this.caseData) && (missingSelection || invalidSelection);
    }

    private compareArrays(a: Array<any>, b: Array<any>): boolean {
        return (a.length === b.length && a.every((val, index) => val === b[index]));
    }
}

import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import * as THREE from 'three';
import * as sc from './stl.constants';
import { LoadStlReport, StlViewService } from './stl-view.service';
import { decompress, isCompressed, isNIFTI, NIFTI1, NIFTI2, readHeader, readImage } from 'nifti-reader-js';
import { TrackballControls } from './trackball-controls';
import { combineLatest, concatMap, from, interval, Observable, reduce, Subject } from 'rxjs';
import { DataElement } from '../../data-element.class';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Subscriptions } from '../../../tools/subscriptions.class';
import { ElementDisplayEvent } from '../stl-options/stl-options.component';
import { ElectrodeContext, RollChangeEvent } from '../lead-sim/lead-simulator-controller.component';
import { ElectrodeDescription, ElectrodesDetectionReport, HeadPose } from '../../../services/api.service';
import { Case } from '../../case.service';

import * as cc from '../../case.constants';
import { DbsTarget, DbsTargets } from '../../case.constants';
import { skip, take } from 'rxjs/operators';
import { flipVector } from '../../../tools/coordinate-systems.constants';
import { degToRad } from 'three/src/math/MathUtils';

export interface Center {
    position: THREE.Vector3;
    maxZ: number;
}

export interface NiftiDimensions {
    cols: number;
    colsDimension: number;
    rows: number;
    rowsDimension: number;
    numOfSlices: number;
    slicesDimension: number;
    sliceSize: number;
}

export interface SlicesReport {
    count: number;
    index: number;
}

interface Axes {
    renderer: THREE.WebGLRenderer;
    viewElem: Element;
    camera: THREE.Camera;
    scene: THREE.Scene;
    target: THREE.Vector3;
    box: THREE.Object3D;
}

enum Models {
    Main, Axes, All
}

@Component({
    selector: 'app-case-stl-view',
    templateUrl: './stl-view.component.html',
    styleUrls: ['./stl-view.component.scss'],
    // the service is injected on component level and will be destroyed with the Component
    providers: [StlViewService],
    animations: [
        trigger('showStlView', [
            state('enabled', style({opacity: 1})),
            state('disabled', style({opacity: 0})),
            transition('enabled => disabled', animate('500ms')),
            transition('disabled => enabled', animate('500ms'))
        ])
    ],
})

export class StlViewComponent implements OnInit, OnDestroy {

    static STL_VIEW_BG_COLOR = 0x343434;
    static AXIS_LENGTH = 11;
    static HOME_CAMERA_Z = 400.0;
    static LIGHT_OFFSET = 200.0;

    @Input() caseData: Case;
    @Input() caseUpdated: Subject<Case>;
    @Input() approvedTargets: Array<cc.DbsTarget>;
    @Input() dataElements: Array<DataElement>;
    @Input() viewElem: HTMLElement;
    @Input() height: number;
    @Input() width: number;

    @Input() imageSlicesData: Observable<ArrayBuffer>;
    @Input() elementDisplayEventHandler: Observable<ElementDisplayEvent>;
    @Input() sliceChangedEvent: Observable<number>;
    @Input() imageOpacityEvent: Observable<number>;
    @Input() changeCoordinatesObservable: Observable<cc.CoordinateSystem>;
    @Input() postopHeadPoseObservable: Subject<HeadPose>;
    @Input() slicesLoadedReport: Subject<SlicesReport>;
    @Input() electrodeRollListener: Observable<RollChangeEvent>;
    @Input() electrodeContextListener: Observable<ElectrodeContext>;
    @Input() electrodesReportReady: Observable<ElectrodesDetectionReport>;

    @Output() updateStlLoaded: EventEmitter<LoadStlReport> = new EventEmitter<LoadStlReport>();

    @ViewChild('scontainer', {static: true}) scontainer: ElementRef;

    public stlEnabled = 'disabled';
    private subscriptions: Subscriptions = new Subscriptions();
    private mRotateDown = false;
    private mRotateRight = false;
    private axes: Axes | null = null;
    private mZoomIn: boolean = false;
    private mZoomOut: boolean = false;
    private mRotateUp: boolean = false;
    private mRotateLeft: boolean = false;
    private cameraHomePosition: THREE.Vector3 = null;
    private cameraHomeUp: THREE.Vector3 = null;
    private centerResult: Center = null;
    private renderer: THREE.WebGLRenderer = new THREE.WebGLRenderer({preserveDrawingBuffer: false, alpha: true});
    private scene: THREE.Scene = new THREE.Scene();
    private camera: THREE.PerspectiveCamera;
    private light1: THREE.PointLight = new THREE.PointLight(0xFFFFFF, 1, 0, 0);
    private light2: THREE.PointLight = new THREE.PointLight(0xFFFFFF, 1, 0, 0);
    private controls: TrackballControls;
    private selectedTarget: DbsTarget = null;
    private electrodesReport: ElectrodesDetectionReport = null;
    private headPoseReport: HeadPose = null;
    private selectedElectrodeSide: string = null;
    private selectedElectrodeIndex: number = -1;

    private niftiHeader: NIFTI1 | NIFTI2 | null = null;
    private niftiImage: ArrayBuffer | null = null;
    private niiDims: NiftiDimensions = null;
    private niftiData: Uint8Array | null = null;
    private imageTexture: THREE.DataArrayTexture | null = null;

    private cs: cc.CoordinateSystem = cc.IMAGE_CS;
    private rotationAngles: THREE.Vector3 = new THREE.Vector3();
    private trxToImageCs: THREE.Matrix4 = new THREE.Matrix4();
    private rotateImgToStandard: THREE.Matrix4 = new THREE.Matrix4();
    private trxImgToCcs: THREE.Matrix4 = new THREE.Matrix4();

    constructor(private stlViewService: StlViewService) {
        this.scene.background = new THREE.Color(StlViewComponent.STL_VIEW_BG_COLOR);
        this.scene.add(new THREE.AmbientLight(0x606060));
        this.scene.add(this.stlViewService.groupAll);
        this.light1.position.set(StlViewComponent.LIGHT_OFFSET, 0, StlViewComponent.LIGHT_OFFSET);
        this.scene.add(this.light1);
        this.light2.position.set(-StlViewComponent.LIGHT_OFFSET, 0, StlViewComponent.LIGHT_OFFSET);
        this.scene.add(this.light2);
    }

    // utility methods
    @HostListener('window:resize')
    public onResize() {
        if (this.controls && this.controls.handleResize) {
            this.controls.handleResize();
        }
        this.render();
    }

    public resetCameraPosition() {
        this.cameraToHome();
    }

    get mCom(): THREE.Vector3 | null {
        return this.calculateCenter()?.position;
    }

    public rotateUp(start: boolean) {
        this.mRotateUp = start;
        this.mRotateDown = false;
    }

    public rotateDown(start: boolean) {
        this.mRotateDown = start;
        this.mRotateUp = false;
    }

    public rotateLeft(start: boolean) {
        this.mRotateLeft = start;
        this.mRotateRight = false;
    }

    public rotateRight(start: boolean) {
        this.mRotateRight = start;
        this.mRotateLeft = false;
    }

    public zoomIn(start: boolean) {
        this.mZoomIn = start;
        this.mZoomOut = false;
    }

    public zoomOut(start: boolean) {
        this.mZoomOut = start;
        this.mZoomIn = false;
    }

    private applyRotationsAndZoom() {
        if (this.mZoomIn) {
            this.controls.zoomOut(0.98);
        }
        if (this.mZoomOut) {
            this.controls.zoomIn(0.98);
        }
        if (this.mRotateUp) {
            this.controls.rotateUp(-Math.PI / 140);
        }
        if (this.mRotateDown) {
            this.controls.rotateUp(Math.PI / 140);
        }
        if (this.mRotateLeft) {
            this.controls.rotateLeft(-Math.PI / 140);
        }
        if (this.mRotateRight) {
            this.controls.rotateLeft(Math.PI / 140);
        }
    }

    public ngOnInit() {
        this.stlViewService.initParts(this.caseData);
        this.camera = new THREE.PerspectiveCamera(35, 1, 0.1, 20000);
        this.camera.position.set(0, 0, StlViewComponent.HOME_CAMERA_Z);
        this.controls = new TrackballControls(this.camera, this.viewElem);
        this.controls.rotateSpeed = 0.75;
        this.controls.staticMoving = false;
        this.controls.dynamicDampingFactor = .45;
        this.controls.addEventListener({type: 'change'}.type, () => {
            this.render();
        });
        this.selectedTarget = DbsTargets.get(this.caseData.target);
        this.subscriptions.add(this.electrodesReportReady, (report: ElectrodesDetectionReport) => {
            this.onElectrodeReportReady(report);
        });
    }

    public ngOnDestroy() {
        if (this.axes !== null && this.axes.renderer) {
            this.axes.renderer.forceContextLoss();
            this.axes.renderer.domElement = null;
            this.axes.renderer = null;
        }
        this.stlEnabled = 'disabled';
        this.subscriptions.cancel();
    }

    private onElectrodeReportReady(report: ElectrodesDetectionReport) {
        this.electrodesReport = report;
        // use local STL files if we have an electrode report
        // Download STLs from the server for cases without the electrode report
        !report.noReportAvailable ? this.loadStlFiles() : this.downloadStlFiles();
    }

    private loadStlFiles() {
        const urlSource = from(this.dataElements).pipe(
            concatMap(e => this.stlViewService.getStlUrls(this.caseData, e, true)),
            reduce((a, v) => a.concat(this.selectUrls(v)), new Array<string>())
        );
        this.subscriptions.add(urlSource,
            (stlUrls) => {
                stlUrls = this.stlViewService.filterApproved(this.approvedTargets, stlUrls);
                const downloadSubscriptions: Array<Subject<any>> = [
                    this.postopHeadPoseObservable,
                    // download the STL files for STN, RN, GPe, GPi, Vim, Vc from the server
                    this.stlViewService.loadParts(this.caseData.id, stlUrls),
                    // for each electrode download the electrode elements from assets
                    ...this.electrodesReport.electrodes.map(electrode =>
                        this.stlViewService.loadElectrode(electrode.model, electrode.side.toLowerCase(), electrode.side_index)
                    ),
                ];
                // wait for all the downloads to finish successfully
                this.subscriptions.add(combineLatest(downloadSubscriptions), ([hpr]) => {
                    this.setupRotationAngles(hpr);
                    this.applyElectrodeReport();
                    this.handleLoadedParts(this.calculateCenter());
                    this.calculateTrxToCcs(this.mCom, this.rotationAngles);
                    this.setupAxesView(this.rotationAngles, hpr !== null);
                    this.subscribeToCaseUpdates();
                    this.subscribeToRollChangeEvents();
                    this.subscribeToSlicesData();
                    this.subscribeToCoordinatesChange();
                });
            }
        );
    }

    /***
     * This method is maintained for backward compatibility.
     * New cases have electrode-report to apply on "local" STL electrodes files.
     * Old cases with no electrode report will also not include the head pose, CCS and other new features.
     * @private
     */
    private downloadStlFiles() {
        const urlSource = from(this.dataElements).pipe(
            concatMap(e => this.stlViewService.getStlUrls(this.caseData, e, false)),
            reduce((a, v) => a.concat(this.selectUrls(v)), new Array<string>())
        );
        this.subscriptions.add(urlSource,
            (stlUrls) => {
                this.subscriptions.add(this.stlViewService.loadParts(this.caseData.id, stlUrls), () => {
                    this.handleLoadedParts(this.calculateCenter());
                    this.subscribeToRollChangeEvents();
                    this.subscribeToSlicesData();
                });
            }
        );
    }

    /***
     * This method look at STL urls and find the best STL for a structure.
     * If the method finds the best match - it will return the best. If the second best is found, it is returned
     * only if we do not have the preferred yet. If we have a preferred, the preferred will be returned to indicate
     * that the structure was found. If the structure was not found null will be returned.
     * @param structure (STN, RN, GPE, GPI, VIM, VC, VO, MD, PU)
     * @param url - the url that will be searched
     * @param currentBest - the current best match
     * @private
     */
    private findUrl(structure: string, url: string, currentBest: string): string | null {
        if (url.search(new RegExp(`/${structure}_.*smooth.stl$`)) >= 0) {
            // if we found the preferred url - we return it
            return url;
        }
        if (url.search(new RegExp(`/${structure}_.*.stl$`)) >= 0) {
            // if we found the second best url we return only if we do not have a preferred yet
            if (currentBest === null) {
                return url;
            }
            else {
                return currentBest;
            }
        }
        // we did not find the structure in the url, we return null
        return null;
    }

    private selectUrls(urls: Array<string>): Array<string> {
        const selectedUrls = [];

        const structures = this.caseData.supported_structures.map(soi => soi.toUpperCase());
        // we start with an empty map => we only add soi and url when we have a valid one.
        const bestUrls = new Map<string, string>();

        for (const url of urls) {
            if (!url.endsWith('.stl')) {
                continue;
            }

            for (const s of structures) {
                const soiUrl = this.findUrl(s, url, bestUrls.get(s));
                if (soiUrl !== null) {
                    bestUrls.set(s, soiUrl);
                    break;
                }
            }

            if (url.search(/\/(Contact|Tip|Shaft|Marker).*\.stl$/) >= 0) {
                selectedUrls.push(url);
            }
        }
        selectedUrls.push(...bestUrls.values());
        return selectedUrls;
    }

    private createAxesView(parentViewElem: HTMLElement, useHead: boolean): Observable<Axes> {
        const axesReady = new Subject<Axes>();
        const axesViewElem = parentViewElem.parentElement.getElementsByClassName('axes-view')[0];

        // orientation axes area
        const w = axesViewElem.clientWidth;
        const h = axesViewElem.clientHeight;

        const renderer: THREE.WebGLRenderer = new THREE.WebGLRenderer({preserveDrawingBuffer: false, alpha: true});
        renderer.setSize(w, h);
        axesViewElem.appendChild(renderer.domElement);
        const camera = new THREE.PerspectiveCamera(35, 1, 0.1, 1000);
        const scene = new THREE.Scene();
        const target = new THREE.Vector3();
        let boxObservable: Observable<THREE.Object3D>;
        if (useHead) {
            boxObservable = this.stlViewService.createAxesHead(target, this.caseData.id);
        }
        else {
            boxObservable = this.stlViewService.createAxesBox(StlViewComponent.AXIS_LENGTH);
        }

        boxObservable.subscribe((box: THREE.Object3D) => {
            function axesPointLight(x: number, y: number, z: number): THREE.PointLight {
                const l = new THREE.PointLight(0xEEEEEE, 1, 0, 0);
                l.position.set(x, y, z);
                return l;
            }
            if (useHead) {
                scene.add(axesPointLight(0, 20, 20)); // front top light
                scene.add(axesPointLight(0, -20, 20)); // back top light
                scene.add(axesPointLight(20, 0, 0)); // right side light
                scene.add(axesPointLight(-20, 0, 0)); // left side light
            }
            else {
                scene.add(new THREE.AmbientLight(0x555555));
            }
            scene.add(box);
            axesReady.next({renderer, viewElem: axesViewElem, camera, scene, target, box});
            axesReady.complete();
        });

        return axesReady;
    }

    private lightingFollowCamera() {

        // Home camera position is at offset C = (0, 0, HOME_CAMERA_Z)
        // Home light positions are at L1 = (LIGHT_OFFSET, 0, LIGHT_OFFSET) and L2 = (-LIGHT_OFFSET, 0, LIGHT_OFFSET)
        // Home camera orientation is R = (0, 1, 0)
        // Adopt a coordinate system where unit z = C/||C||, unit Y = R, unit x = Y x Z
        // In this coordinate system,
        //     C' = (0, 0, 1),
        //     R' = (0, 1, 0),
        //    L1' = (LIGHT_OFFSET, 0, LIGHT_OFFSET)
        //    L2' = (-LIGHT_OFFSET, 0, LIGHT_OFFSET)
        // Then as camera position is updated, we can generate a new basis from the camera
        // position and orientation and convert L1' and L2' back to the scene coordinates

        const z = this.camera.position.clone().sub(this.controls.target).normalize();
        const y = this.camera.up.normalize();
        const x = new THREE.Vector3().crossVectors(y, z);

        const m = new THREE.Matrix4().makeBasis(x, y, z);

        const d = StlViewComponent.LIGHT_OFFSET;
        const l1 = new THREE.Vector3(d, 0, d).applyMatrix4(m).add(this.controls.target);
        const l2 = new THREE.Vector3(-d, 0, d).applyMatrix4(m).add(this.controls.target);

        this.light1.position.copy(l1);
        this.light2.position.copy(l2);
    }

    private axesFollowCamera() {
        const masterPosition = this.camera.position;
        const masterOffset = masterPosition.clone().sub(this.controls.target);

        // angle from z-axis around y-axis
        // noinspection JSSuspiciousNameCombination
        const theta = Math.atan2(masterOffset.x, masterOffset.z);

        // angle from y-axis
        // noinspection JSSuspiciousNameCombination
        const phi = Math.atan2(Math.sqrt(masterOffset.x * masterOffset.x + masterOffset.z * masterOffset.z),
            masterOffset.y);

        const radius = 4 * StlViewComponent.AXIS_LENGTH;

        const newOffset = new THREE.Vector3(radius * Math.sin(phi) * Math.sin(theta),
            radius * Math.cos(phi),
            radius * Math.sin(phi) * Math.cos(theta));

        this.axes.camera.position.copy(this.axes.target).add(newOffset);
        this.axes.camera.up.copy(this.camera.up);
        this.axes.camera.lookAt(this.axes.target);
    }

    private cameraToHome() {
        this.zoomIn(false);
        this.zoomOut(false);
        this.rotateLeft(false);
        this.rotateRight(false);
        this.rotateUp(false);
        this.rotateDown(false);
        this.moveCameraHome();
    }

    /**
     * This method moves the camera back from the current position to the home position.
     * The method will move the camera in steps to animate the position of the element back to the original
     * position.
     */
    private moveCameraHome(): void {
        // if the camera position is in the home position, we do not need to move it
        if (this.camera.position.equals(this.cameraHomePosition)) {
            return;
        }
        // make sure the controls is set on the COM point
        this.controls.target = this.mCom.clone();

        // Get the current camera position - start of our arc we move on
        const v0 = this.camera.position.clone().sub(this.mCom); // vector from COM to current position
        const r0 = v0.length(); // length from COM to current position
        const nv0 = v0.clone().normalize(); // Direction from COM to current position
        const v1 = this.cameraHomePosition.clone().sub(this.mCom); // vector from COM to home position
        const r1 = v1.length(); // length from COM to home position
        const nv1 = v1.clone().normalize(); // Direction from COM to home position

        const theta = Math.acos(nv0.dot(nv1));  // calculate the angle between the 2 vectors (com->home, com-> current)
        let nv3: THREE.Vector3;
        if (theta !== 0) {
            // calculate the vector that is orthogonal to the com-home/com-current vectors
            // we will rotate around this axis
            const nv2 = nv0.clone().cross(nv1).normalize();
            nv3 = nv2.clone().cross(nv0);
        }
        else {
            // if theta is 0 we can stay in place (rotate around z axis)
            nv3 = nv0;
        }

        // When we rotate the camera we need to keep the camera point up compared to our rotation
        // The camera center is (0, 0, 0), in the home position it is (0, 1, 0) since Y is anterior
        const up0 = this.camera.up.clone().normalize(); // current camera direction
        const up1 = this.cameraHomeUp.clone().normalize(); // Home camera direction
        // Calculate the rotation angle around the z axis between current and home up
        const phi = Math.acos(up0.dot(up1));
        let up3: THREE.Vector3;

        if (phi !== 0) {
            const up2 = up0.clone().cross(up1).normalize();
            up3 = up2.clone().cross(up0);
        }
        else {
            up3 = up0;
        }

        // set the interval and frame arguments 15 x 40 = 600ms
        const intervalMs = 15;
        const frames = 40;
        interval(intervalMs).pipe(take(frames)).subscribe(i => {
            // Set the camera position based on the progress, actually moving the camera on the arc between
            // current position and home position around on the place of the 2 vectors (com-current -> com-home)
            const progress = (i + 1) / frames;

            const setCameraPosition = () => {
                // calculate the position on the arc based on progress (0-1)
                const r = (1 - progress) * r0 + (progress * r1);
                const t = progress * theta;
                const cosPart = nv0.clone().multiplyScalar(r * Math.cos(t));
                const sinPart = nv3.clone().multiplyScalar(r * Math.sin(t));
                const v = this.mCom.clone().add(cosPart).add(sinPart);
                this.camera.position.copy(v);
            };

            const setCameraUp = () => {
                // on the camera up, the origin is (0, 0, 0), no radius
                const p = progress * phi;
                const upCosPart = up0.clone().multiplyScalar(Math.cos(p));
                const upSinPart = up3.clone().multiplyScalar(Math.sin(p));
                const up = upCosPart.clone().add(upSinPart);
                this.camera.lookAt(this.mCom);
                // Since there is a little offset each time, make the final be the origin
                this.camera.up.copy(progress === 1 ? this.cameraHomeUp : up);
            };

            setCameraPosition();
            setCameraUp();
            this.render();
        });
    }

    private loop() {
        this.applyRotationsAndZoom();
        this.controls.update();
        requestAnimationFrame(() => this.loop());
    }

    private render() {
        this.resizeRenderer(this.renderer, this.viewElem);
        this.lightingFollowCamera();
        this.renderer.render(this.scene, this.camera);

        if (this.axes !== null) {
            this.resizeRenderer(this.axes.renderer, this.axes.viewElem);
            this.axesFollowCamera();
            this.axes.renderer.render(this.axes.scene, this.axes.camera);
        }
    }

    private handleNiftiData(niftiBuffer: ArrayBuffer): void {
        if (isCompressed(niftiBuffer)) {
            niftiBuffer = decompress(niftiBuffer);
        }
        if (isNIFTI(niftiBuffer)) {
            this.niftiHeader = readHeader(niftiBuffer);
            this.niftiImage = readImage(this.niftiHeader, niftiBuffer);
            // We always get NIfTI with Float32 values between 0-1 for the T1_PLANE Image.
            // Three.js and WEBGL prefers UInt8. We will convert Float32 to UInt8 when we get the NIfTI Data
            // based on - https://github.com/mrdoob/three.js/blob/master/examples/webgl2_materials_texture2darray.html#L145
            this.niftiData = this.setupNiftiData(this.niftiHeader, this.niftiImage);
            const center = this.calculateCenter().position;
            // read the nifti parameters
            this.niiDims = this.readNiftiDimensions(this.niftiHeader);
            const sliceIndex = Math.round((center.z - this.niftiHeader.qoffset_z) / this.niiDims.slicesDimension);
            this.imageTexture = this.prepareTexture(this.niftiData, this.niiDims);
            if (this.addSliceAsTexture(this.imageTexture, sliceIndex)) {
                // Inform the world that we have the Nifti data
                this.slicesLoadedReport.next({count: this.niiDims.numOfSlices, index: sliceIndex});
            }
        }
    }

    private prepareTexture(imageData: Uint8Array, imgDims: NiftiDimensions): THREE.DataArrayTexture {
        const dataTexture: THREE.DataArrayTexture = new THREE.DataArrayTexture(imageData, imgDims.cols, imgDims.rows, imgDims.numOfSlices);
        dataTexture.format = THREE.RedFormat;
        dataTexture.wrapT = dataTexture.wrapS = THREE.ClampToEdgeWrapping;
        dataTexture.magFilter = THREE.LinearFilter;
        dataTexture.needsUpdate = true;
        return dataTexture;
    }

    private subscribeToSlicesData() {
        this.subscriptions.add(this.imageSlicesData, (niftiBuffer: ArrayBuffer) => {
            if (niftiBuffer === null) {
                this.slicesLoadedReport.next(null);
                this.slicesLoadedReport.complete();
            }
            else {
                this.handleNiftiData(niftiBuffer);
                this.subscriptions.add(this.sliceChangedEvent, (index: number) => this.onSliceChanged(index));
                this.subscriptions.add(this.imageOpacityEvent, (opacity: number) => this.onImageOpacityChanged(opacity));
                this.render();
            }
        });
    }

    private subscribeToCoordinatesChange(): void {
        this.subscriptions.add(this.changeCoordinatesObservable, (cs: cc.CoordinateSystem) => {
            this.setImageCoordinateSystem(cs);
        });
    }

    /**
     * This method set up the 3D model axes in the scene - either the cube or the 3D head model.
     * @param rotationAngles - received from the HeadPose report
     * @param useHead - if we have a HeadPose report we use the head. Else we use the Cube.
     * @private
     */
    private setupAxesView(rotationAngles: THREE.Vector3, useHead: boolean): void {
        this.createAxesView(this.viewElem, useHead).subscribe((axes: Axes) => {
            this.axes = axes;
            // if we have an HPR, here, we should apply the rotation to the head model.
            // The model is in the standard CS (i.e. 0, 0, 0) and the initial display is in the image CS =>
            // so we need to apply rotation only (since the center of the model is already at 0,0,0).
            this.applyTransformation(rotationAngles.x, rotationAngles.y, rotationAngles.z, true, Models.Axes);
        });
    }

    private subscribeToElementDisplayEvents() {
        this.subscriptions.add(this.elementDisplayEventHandler, event => {
            this.onElementDisplayEvent(event as ElementDisplayEvent);
        });
    }

    private subscribeToElectrodeContextChange() {
        this.subscriptions.add(this.electrodeContextListener, event => {
            this.onElectrodeContextChange(event);
        });
    }

    /**
     * This method sets the rotation angles for the head pose if we have a head pose report.
     * If there is no report, the method will assign 0 to all rotations.
     * @param hpr - HeadPose report (as defined in the API)
     * @private
     */
    private setupRotationAngles(hpr: HeadPose): void {
        this.headPoseReport = hpr;
        if (this.headPoseReport !== null) {
            const flip = flipVector(this.headPoseReport.coordinate_system);
            this.rotationAngles.set(
                flip.y * flip.z * this.headPoseReport.pitch,
                flip.x * flip.z * this.headPoseReport.yaw,
                flip.x * flip.y * this.headPoseReport.roll
            );
        }
        else {
            this.rotationAngles.set(0, 0, 0);
        }
    }

    private subscribeToCaseUpdates() {
        this.subscriptions.add(this.caseUpdated.pipe(skip(1)), (data: Case) => {
            if (data.target !== this.selectedTarget.key) {
                this.selectedTarget = cc.DbsTargets.get(data.target);
                this.updateStlLoaded.emit(this.stlViewService.getStlLoadReport());
                this.render();
            }
        });
    }

    private subscribeToRollChangeEvents() {
        this.subscriptions.add(this.electrodeRollListener, (rollEvent) => {
            this.rollElectrode(rollEvent.side, rollEvent.index, rollEvent.from, rollEvent.to);
        });
    }

    private resizeRenderer(renderer: THREE.Renderer, viewElem: Element) {
        renderer.setSize(viewElem.clientWidth, viewElem.clientHeight);
        this.camera.aspect = viewElem.clientWidth / viewElem.clientHeight;
        this.camera.updateProjectionMatrix();
    }

    /**
     * Once the STL files have been downloaded, we set up the camera and the scene to reflect the position of the STL
     * files.
     * @param center - as calculated by the calculateCenter method.
     * @private
     */
    private handleLoadedParts(center: Center) {
        this.cameraHomePosition = new THREE.Vector3(center.position.x, center.position.y, center.maxZ + StlViewComponent.HOME_CAMERA_Z);
        this.cameraHomeUp = this.camera.up.clone();

        this.cameraToHome();

        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(this.viewElem.clientWidth, this.viewElem.clientHeight);
        // Since the size is not dynamic we need to handle resize when the component is created
        if (this.controls && this.controls.handleResize) {
            this.controls.handleResize();
        }
        this.renderer.setClearColor(StlViewComponent.STL_VIEW_BG_COLOR, 1);
        this.viewElem.appendChild(this.renderer.domElement);
        this.renderer.domElement.style.position = 'relative';
        const stlLoadReport = this.stlViewService.getStlLoadReport();
        this.updateStlLoaded.emit(stlLoadReport);
        this.stlEnabled = 'enabled';
        this.loop();
        this.subscribeToElectrodeContextChange();
        this.subscribeToElementDisplayEvents();
    }

    /**
     * This method is where we calculate the center of the 3D view (mCom).
     * We can calculate the center in 3 different ways:
     * 1. The selected target is approved, and we have STL files for the structures - we use  the STL files.
     * 2. If we do not have an approved segmentation for the target, but we have the Head Pose report - we use the HPR
     * 3. We do not have approved segmentation for the target, and we do not have HPR - we use the center of the
     *    bounding box around the available STL files.
     * @private
     */
    private calculateCenter(): Center {
        // Option 1
        if (this.centerResult === null && this.approvedTargets.includes(this.selectedTarget)) {
            this.centerResult = this.centerFromStlFiles();
        }
        // Option 2
        if (this.centerResult === null && this.headPoseReport !== null) {
            // try and set the center based on the head pose report
            this.centerResult = this.centerFromHeadPose();
        }
        // Option 3
        if (this.centerResult === null) {
            this.centerResult = this.centerFromBoundingBox();
        }
        return this.centerResult;
    }

    private centerFromStlFiles(): Center | null {
        if (this.selectedTarget.centerCalcObj === null) {
            return null;
        }
        const structures = this.selectedTarget.centerCalcObj.map(key => this.stlViewService.getObject(key));
        if (structures.some(s => !s)) {
            return null;
        }
        const centers = structures.map(s => this.getCenter(s));
        return {
            maxZ: centers.map(c => c.maxZ).reduce((a, x) => Math.max(a, x)),
            position: centers.reduce((a, c) => a.add(c.position), new THREE.Vector3()).divideScalar(centers.length)
        };
    }

    /**
     * This method assumes that the HPR exist. Caller should check that HPR is valid before the call.
     * @private
     */
    private centerFromHeadPose(): Center {
        const centerResult = this.selectCcsCenter();
        const center = centerResult.position.clone();
        const flip = flipVector(this.headPoseReport.coordinate_system);
        center.multiply(flip);
        const flippedHprCenter = new THREE.Vector3(...this.headPoseReport.center).multiply(flip);
        const T = new THREE.Matrix4().makeTranslation(...flippedHprCenter.toArray());
        // convert the pitch, yaw and roll from degrees to radians
        const rAngles = new THREE.Vector3(...this.rotationAngles.toArray().map(degToRad));
        // calculate the rotation matrices for the 3 axes
        const pitch = new THREE.Matrix4().makeRotationX(rAngles.x);
        const yaw = new THREE.Matrix4().makeRotationY(rAngles.y);
        const roll = new THREE.Matrix4().makeRotationZ(rAngles.z);
        // calculate the full transformation from CCS to Image - from right to left:
        // Translation multiplied by pitch * yaw * roll
        const ccsToImageTransform = T.clone().multiply(pitch).multiply(yaw).multiply(roll);
        center.applyMatrix4(ccsToImageTransform);
        return {position: center, maxZ: center.z + 3};
    }

    /**
     * Center will be set based on selected target and approved targets.
     * If the selected target is approved, the center will be the CCS position of this target.
     * if the selected target is not approved, the center will be the first approved target
     * @private
     */
    private selectCcsCenter(): Center {
        let center: THREE.Vector3;
        switch (this.selectedTarget) {
            case cc.StnTarget:
                center = sc.CCS_CENTER_STN;
                break;
            case cc.GpTarget:
                center = sc.CCS_CENTER_GPI;
                break;
            case cc.VimTarget:
                center = sc.CCS_CENTER_VIM;
                break;
            default:
                // if target is ALL, set the center to be the CCS center
                center = new THREE.Vector3(0, 0, 0);
        }
        return {maxZ: center.z + 3, position: center};
    }

    /**
     * Calculates the center of the scene based on the existing available brain structures and contacts.
     * @private
     */
    private centerFromBoundingBox(): Center | null {
        const unionBox: THREE.Box3 = new THREE.Box3();
        const avoid = [cc.MARKER, cc.TIP, cc.ELECTRODE, cc.RING, cc.T1_PLANE];
        // get all existing objects, filter out the marker, tip and shaft and accumulate the bounding box.
        this.stlViewService.allObjects.filter(obj => avoid.every(el => !obj.name.includes(el))
        ).forEach((obj => unionBox.union(new THREE.Box3().setFromObject(obj))));
        // return the calculated center
        return {maxZ: unionBox.max.z + 3, position: unionBox.getCenter(new THREE.Vector3())};
    }

    private getCenter(target: THREE.Object3D): Center {
        const box3: THREE.Box3 = new THREE.Box3().setFromObject(target);
        const x = (box3.max.x + box3.min.x) / 2;
        const y = (box3.max.y + box3.min.y) / 2;
        const z = (box3.max.z + box3.min.z) / 2;
        return {maxZ: box3.max.z, position: new THREE.Vector3(x, y, z)};
    }

    private onImageOpacityChanged(value: number) {
        // change the material of the texture on the service
        this.stlViewService.updatePlaneOpacity(value);
        this.render();
    }

    private onSliceChanged(index: number) {
        // add the selected slice
        this.addSliceAsTexture(this.imageTexture, index);
        // if we have a head pose report we need to apply the correct transform to the plane when it is added
        const planeMesh = this.stlViewService.getPlaneMesh();
        if (planeMesh !== null) {
            const t: THREE.Matrix4 = (this.cs.id === cc.IMAGE_CS.id) ? this.trxToImageCs : this.trxToImageCs.clone().invert();
            this.applyForMesh(planeMesh, t);
        }
        this.render();
    }

    private addSliceAsTexture(texture: THREE.DataArrayTexture, sliceIndex: number): boolean {
        if (this.imageTexture !== null) {
            // calculate slice position
            const slicePosition = this.calculateSlicePosition(this.niftiHeader, this.niiDims, sliceIndex);
            // add the mesh. If we have another mesh for the plane it will be removed
            this.stlViewService.addPlane(
                slicePosition, texture, sliceIndex,
                this.niiDims.cols * this.niiDims.colsDimension,
                this.niiDims.rows * this.niiDims.rowsDimension
            );
            return true;
        }
        return false;
    }

    private readNiftiDimensions(niftiHeader: NIFTI1 | NIFTI2): NiftiDimensions {
        return {
            cols: niftiHeader.dims[1],
            colsDimension: niftiHeader.pixDims[1],
            rows: niftiHeader.dims[2],
            rowsDimension: niftiHeader.pixDims[2],
            numOfSlices: niftiHeader.dims[3],
            slicesDimension: niftiHeader.pixDims[3],
            sliceSize: niftiHeader.dims[1] * this.niftiHeader.dims[2]
        };
    }

    private calculateSlicePosition(niftiHeader: any, dims: NiftiDimensions, sliceIndex: number): THREE.Vector3 {
        const x = niftiHeader.qoffset_x + (((dims.cols - 1) / 2) * dims.colsDimension);
        const y = niftiHeader.qoffset_y + (((dims.rows - 1) / 2) * dims.rowsDimension);
        const z = niftiHeader.qoffset_z + (sliceIndex * dims.slicesDimension);
        return new THREE.Vector3(x, y, z);
    }

    private onElectrodeContextChange(event: ElectrodeContext) {
        // before switching, reset the current selection
        if (this.electrodeSelected()) {
            this.stlViewService.clearElectrodeSelection(cc.ELECTRODE, this.selectedElectrodeSide, this.selectedElectrodeIndex);
        }
        if (event.side && (event.sideIndex > 0)) {
            // Clear current selection
            // switch to another electrode
            this.selectedElectrodeSide = event.side;
            this.selectedElectrodeIndex = event.sideIndex;
            this.stlViewService.setElectrodeSelection(cc.ELECTRODE, this.selectedElectrodeSide, this.selectedElectrodeIndex);
        }
        else {
            // switch to no selection
            this.selectedElectrodeSide = null;
            this.selectedElectrodeIndex = -1;
        }
        this.render();
    }

    /**
     * After we finished downloading the STL elements of the electrodes we can apply the transformations
     * on the elements that will bring them to their correct place in the scene.
     */
    private applyElectrodeReport() {
        this.electrodesReport.electrodes.forEach(electrode => {
            const flip = flipVector(electrode.coordinate_system);
            // Compute the main transformation of the electrode
            const T = this.computeElectrodeTrx(electrode, flip);
            // Compute the translation that fixes the delta between expected to actual COM of contacts
            const D = this.computeElectrodeCorrection(electrode, T, flip);
            // Apply the overall transformation to all the electrode elements
            this.applyTrxOnElectrode(D.multiply(T), electrode.side.toLowerCase(), electrode.side_index);
        });
        this.render();
    }

    /**
     * Compute the main transformation of the electrode elements inside the 3D scene
     * @param electrode - dictionary with electrode data as prepared on the server during electrode detection
     * @param flip - Either a Vector(1,1,1), that does nothing, or a Vector that flips LPS to RAS
     */
    private computeElectrodeTrx(electrode: ElectrodeDescription, flip: THREE.Vector3): THREE.Matrix4 {
        const tip = new THREE.Vector3(...electrode.tip).multiply(flip);
        const pitch = flip.y * flip.z * electrode.pitch * Math.PI / 180;
        const rX = new THREE.Matrix4().makeRotationX(pitch);
        const yaw = flip.x * flip.z * electrode.yaw * Math.PI / 180;
        const rY = new THREE.Matrix4().makeRotationY(yaw);
        const rZ = new THREE.Matrix4().makeRotationZ(Math.PI / 2);
        // Create T the transformation matrix for the electrode:
        // The tip location is the translation
        // pitch (rX) and yaw (rY) are applied to align with the detected axis
        // rZ is the rotation of the template to point anterior by default before the roll is applied
        // T is the matrix that include all factors and applied to the electrode elements
        const T: THREE.Matrix4 = new THREE.Matrix4().makeTranslation(tip.x, tip.y, tip.z);
        return T.multiply(rX).multiply(rY).multiply(rZ);
    }

    /***
     * Compute the correction that we need to apply to make the electrodes center, as calculated in the server
     * the actual center of the STL files that were loaded from a template
     * @param electrode - json object with data from server
     * @param T the transformation that was calculated before the correction
     * @param flip LPS-RAS vector to be applied as needed
     */
    private computeElectrodeCorrection(electrode: ElectrodeDescription, T: THREE.Matrix4, flip: THREE.Vector3) {
        // Get the label of the top contact - if there is more than 1 select the first one.
        const label = electrode.rings[electrode.rings.length - 1].contacts[0];
        // Get the center of the top contact as calculated by the server electrode detection
        const center = electrode.rings[electrode.rings.length - 1].center;
        // Apply LPS to the server RAS if needed
        const expectedTopCom = new THREE.Vector3(...center).multiply(flip);
        // get the COM of the Mesh of the top contact
        const actualTopCom: THREE.Vector3 = this.stlViewService.getInitialTopRingCom(electrode, label);
        // Apply the transformation from template position to the calculated position
        actualTopCom.applyMatrix4(T);
        // calculate the delta between expected position and the actual position
        const delta = new THREE.Vector3().subVectors(expectedTopCom, actualTopCom);
        // return the translation that "fixes" the delta
        return new THREE.Matrix4().makeTranslation(delta.x, delta.y, delta.z);
    }

    /**
     * Sets the orientation of the electrode elements to the provided rollAngle
     * Based on http://paulbourke.net/geometry/rotate/
     * @param side - a string with the side of the electrode (left or right)
     * @param index - the index of the electrode on the specified side
     * @param fromAngle - the current angle of the element to rotate
     * @param toAngle the new roll rotation of the electrode (radians)
     */
    private rollElectrode(side: string, index: number, fromAngle: number, toAngle: number) {
        const electrode = this.electrodesReport.electrodes.find(e => e.side.toLowerCase() === side && e.side_index === index);
        if (!electrode) {
            console.error(`Could not find the electrode for ${side}, ${index}`);
            return;
        }
        const flip = flipVector(electrode.coordinate_system);
        const axis = new THREE.Vector3(...electrode.axis).multiply(flip);
        const a = axis.x;
        const b = axis.y;
        const c = axis.z;
        const d = Math.sqrt(Math.pow(b, 2) + Math.pow(c, 2));

        const tip = new THREE.Vector3(...electrode.tip).multiply(flip);

        const T: THREE.Matrix4 = new THREE.Matrix4().makeTranslation(-tip.x, -tip.y, -tip.z);
        const TInv: THREE.Matrix4 = T.clone().invert();
        const Rx: THREE.Matrix4 = new THREE.Matrix4();
        let RxInv: THREE.Matrix4;
        const Ry: THREE.Matrix4 = new THREE.Matrix4();

        if (d) {
            Rx.set(1, 0, 0, 0, 0, c / d, -b / d, 0, 0, b / d, c / d, 0, 0, 0, 0, 1);
            RxInv = Rx.clone().invert();
        }

        Ry.set(d, 0, -a, 0, 0, 1, 0, 0, a, 0, d, 0, 0, 0, 0, 1);
        const RyInv = Ry.clone().invert();
        const Rz: THREE.Matrix4 = new THREE.Matrix4().makeRotationZ(toAngle - fromAngle);
        const trx = this.trxToImageCs.clone().invert().multiply(TInv).multiply(RxInv).multiply(RyInv).multiply(Rz).multiply(Ry).multiply(Rx).multiply(T).multiply(this.trxToImageCs);

        this.applyTrxOnElectrode(trx, side, index);
        this.render();
    }

    private applyTrxOnElectrode(T: THREE.Matrix4, side: string, sideIndex: number) {
        const meshes = this.stlViewService.getElectrodeMeshes(side, sideIndex);
        meshes.forEach(mesh => mesh.applyMatrix4(T));
    }

    private onElementDisplayEvent(event: ElementDisplayEvent) {
        this.stlViewService.updateElementDisplay(event.tag, event.visible);
        this.render();
    }

    /**
     * @param niftiHeader - a NIFTI1 | NIFTI2 parameter
     * @param niftiImage - an ArrayBuffer with the image data in Float32 format
     * @private
     * @return the image data as a Uint8Array
     * @throws Error - if the image data in the NIfTI file is not Float32 throws an error.
     */
    private setupNiftiData(niftiHeader: NIFTI1 | NIFTI2, niftiImage: ArrayBuffer): Uint8Array {
        if (niftiHeader.datatypeCode === NIFTI1.TYPE_FLOAT32) {
            const floatImgData: Float32Array = new Float32Array(niftiImage);
            const uInt8ImgData: Uint8Array = new Uint8Array(floatImgData.length);
            floatImgData.forEach((fValue: number, i: number) => uInt8ImgData[i] = fValue * 255);
            return uInt8ImgData;
        }
        throw new Error(`Invalid NIfTI data type code (expected - ${NIFTI1.TYPE_FLOAT32}, received: ${niftiHeader.datatypeCode})`);
    }

    private electrodeSelected(): boolean {
        return this.selectedElectrodeSide && this.selectedElectrodeIndex > -1;
    }

    /**
     * This method calculates the transformation that is required align the image coordinates into the
     * "Canonical Coordinates System" (CCS).
     * Our human head 3D model is "in" the CCS => center around AC @ (0,0,0) -  upward, forward.
     * @param com - the center if the scene, calculated in "calculateCenter" method.
     * @param rotationAngles - the rotations in the 3 axes as calculated by and received from the back end.
     * @private
     */
    private calculateTrxToCcs(com: THREE.Vector3, rotationAngles: THREE.Vector3) {
        // The COM is the center of the 3D view and where the camera looks, calculate the translation from this point
        // to the (0, 0, 0). The inverse is the translation from the COM to the (0, 0, 0)
        const T = new THREE.Matrix4().makeTranslation(...com.toArray()).invert();
        // convert the pitch, yaw and roll from degrees to radians
        const rAngles = rotationAngles.clone().multiplyScalar(degToRad(1));
        // calculate the rotation matrices for the 3 axes
        const pitch = new THREE.Matrix4().makeRotationX(rAngles.x);
        const yaw = new THREE.Matrix4().makeRotationY(rAngles.y);
        const roll = new THREE.Matrix4().makeRotationZ(rAngles.z);
        // calculate the combined rotation matrix
        this.rotateImgToStandard = pitch.clone().multiply(yaw).multiply(roll).invert();
        // calculate the full transformation from image to CCS - from right to left:
        // Move to origin(T) -> apply the rotation -> bring back to COM (T.invert)
        this.trxImgToCcs = T.clone().invert().multiply(this.rotateImgToStandard).multiply(T);
    }

    /**
     * Sets the component coordinate system (CS) to either the image CS or a standard CS
     * where the head is upward and forward and the center of the head (based on ac/pc) is (0,0,0)
     * @param cs a CS object to set as the current system CS
     * @private
     */
    private setImageCoordinateSystem(cs: cc.CoordinateSystem): void {
        if (cs !== this.cs) {
            switch (cs.id) {
                case cc.IMAGE_CS.id:
                    // if we are in the image CS going back means the identity matrix
                    this.trxToImageCs = new THREE.Matrix4();
                    this.applyTransformation(this.rotationAngles.x, this.rotationAngles.y, this.rotationAngles.z, true, Models.All);
                    break;
                case cc.STANDARD_CS.id:
                    // save the inverse of the transformation to allow going back to the IMAGE_CS
                    this.trxToImageCs = this.trxImgToCcs.clone().invert();
                    this.applyTransformation(-this.rotationAngles.x, -this.rotationAngles.y, -this.rotationAngles.z, false, Models.All);
                    break;
            }
            this.cs = cs;
        }
    }

    private applyForMesh(mesh: THREE.Mesh, t: THREE.Matrix4): void {
        // if this Mesh is a container for other Mesh objects (contacts), we should apply the transform
        // on the children and not on the container
        (mesh.children.length === 0) ? mesh.applyMatrix4(t) : mesh.children.forEach(m => m.applyMatrix4(t));
    }

    /**
     * apply the transformation of rotating the whole 3D space around the center point.
     * @param pitch - rotation around X Axis in degrees
     * @param yaw - rotation around Y Axis in degrees
     * @param roll - rotation around Z Axis in degrees
     * @param fw - if true the rotations will be applied as x*y*z. if false - z*y*x
     * @param applyTo - Main model, Axes model or All models (through their matching Mesh objects)
     * @private
     */
    private applyTransformation(pitch: number, yaw: number, roll: number, fw: boolean, applyTo: Models): void {

        const elementsMeshes: Array<THREE.Mesh> = this.stlViewService.getAllMeshes();
        const axesMesh: THREE.Mesh = this.axes.box.children[0] as THREE.Mesh;

        const frames = 30; // number of movement events
        const intervalMs = 10; // interval in ms
        const T = new THREE.Matrix4().makeTranslation(...this.mCom.toArray()).invert();

        let lastTrx = new THREE.Matrix4();
        let lastAxesTrx = new THREE.Matrix4();

        // We are going to apply the pitch, yaw and roll in <total/frames> steps.
        // Also - converting degrees to radians
        const pyr = new THREE.Vector3(pitch, yaw, roll).multiplyScalar(Math.PI / (180 * frames));

        // the animation will be applied in 30 frames every 10ms over 300 ms
        interval(intervalMs).pipe(take(frames)).subscribe(i => {
            // The algorithm applies the rotation in intervals of (theta/frames) * (i + 1)
            // The 3 rotations (pitch, yaw, roll) are calculated from the rotationAngles vector and then combined
            // with the required order x*y*z (for image -> head pose) and z*y*x (head-pose -> image)
            const xRm = new THREE.Matrix4().makeRotationX((i + 1) * pyr.x);
            const yRm = new THREE.Matrix4().makeRotationY((i + 1) * pyr.y);
            const zRm = new THREE.Matrix4().makeRotationZ((i + 1) * pyr.z);
            const rotation = fw ? xRm.clone().multiply(yRm).multiply(zRm) : zRm.clone().multiply(yRm).multiply(xRm);

            if ([Models.Main, Models.All].includes(applyTo)) {
                // combine the translation to the rotation
                const trx = T.clone().invert().multiply(rotation).multiply(T);
                // before we apply the trx we need to "cancel" the last trx applied to "go" back to the start point
                const appliedTrx = trx.clone().multiply(lastTrx.invert());
                // and save the last applied transform
                lastTrx = trx;
                elementsMeshes.forEach(mesh => this.applyForMesh(mesh, appliedTrx));
            }

            if ([Models.Axes, Models.All].includes(applyTo)) {
                // For the axes, we will do the same trx calculations but without the
                // translation (since the origin is 0,0,0)
                const axesTrx = rotation.clone();
                const appliedAxesTrx = axesTrx.clone().multiply(lastAxesTrx.invert());
                lastAxesTrx = axesTrx;
                // apply the transforms to the Mesh objects of the elements and the axes cube
                this.applyForMesh(axesMesh, appliedAxesTrx);
            }

            this.render();
        });
    }
}

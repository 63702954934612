import { Injectable } from '@angular/core';
import { ApiService, ClfDicomParams, ClfDicomResponse } from '../../../services/api.service';
import { Observable } from 'rxjs';
import { ImageUse } from '../../dicom.constants';
import { SeriesId } from '../dicom.class';
import { SelectedDicomInfo } from './select-dicom.component';

@Injectable()
export class SelectDicomService {

    private selectedDicomInfo: Map<SeriesId, SelectedDicomInfo> = new Map<SeriesId, SelectedDicomInfo>();

    constructor(private apiService: ApiService) {
    }

    public select(newImgUse: ImageUse, seriesId: SeriesId, patientId: string): void {
        this.selectedDicomInfo.forEach((selectedData, sid) => {
            if (sid === seriesId || selectedData.iu === newImgUse || (newImgUse === ImageUse.none && sid === seriesId)) {
                // series or newImgUse are in the map
                this.selectedDicomInfo.delete(sid);
            }
        });
        if (newImgUse !== ImageUse.none) {
            this.selectedDicomInfo.set(seriesId, {iu: newImgUse, patientId});
        }
    }

    public has(imgUse: ImageUse): boolean {
        return Array.from(this.selectedDicomInfo.values()).some(sd => imgUse === sd.iu);
    }

    public patientIdsMismatch(currentIds: Array<string>): boolean {
        const selectedImageUse = Array.from(this.selectedDicomInfo.values());
        const selectedPatientIds: Set<string> = new Set(selectedImageUse.map(x => x.patientId));
        // if we have more than 1 selected patient IDs -> mismatch
        // if the patient IDs of the selected (to upload) images is different from previously uploaded Ids -> mismatch
        return selectedPatientIds.size > 1 || !currentIds.every(id => selectedPatientIds.has(id));
    }

    public hasSeries(id: string) {
        return this.selectedDicomInfo.has(id);
    }

    public clearSelection(): void {
        this.selectedDicomInfo.clear();
    }

    public getSelection(): Map<SeriesId, SelectedDicomInfo> {
        // return the content of the selection ordered by the ImageUse value
        return new Map([...this.selectedDicomInfo.entries()].sort(
            (a, b) => a[1].iu - b[1].iu
        ));
    }

    public isSelected(): boolean {
        return this.selectedDicomInfo.size > 0;
    }

    public classifyDicom(dicomParams: Array<ClfDicomParams>): Observable<ClfDicomResponse> {
        return this.apiService.classifyDicom(dicomParams);
    }
}

<form class="user-profile-container fill-parent" [formGroup]="userProfileForm" (ngSubmit)="saveUser()">
    <mat-card appearance="outlined" class="panel panel-primary user-profile-card">
        <mat-card-title i18n="@@settingsUsersProfileTitle" class="sis-card-title">
            {{ 'settingsUsersProfileTitle'|translate }}
        </mat-card-title>
        <mat-card-content>
            <div class="profile-card-content-container">
                <div class="profile-first-row">
                    <div class="profile-flex-row-form">
                        <mat-form-field class="profile-form-item">
                            <mat-label>{{ 'settingsUsersProfilePlaceholderEmail'|translate }}</mat-label>
                            <input matInput type="email" style="text-transform: lowercase" [formControl]="emailControl">
                        </mat-form-field>
                        <br>
                        <mat-form-field class="profile-form-item">
                            <mat-label>{{ 'settingsUsersProfilePlaceholderFirstName'|translate }}</mat-label>
                            <input matInput type="text" [formControl]="firstNameControl">
                        </mat-form-field>
                        <br>
                        <mat-form-field class="profile-form-item">
                            <mat-label>{{ 'settingsUsersProfilePlaceholderLastName'|translate }}</mat-label>
                            <input matInput type="text" [formControl]="lastNameControl">
                        </mat-form-field>
                        <br>
                        <mat-form-field class="profile-form-item">
                            <mat-label>{{ 'settingsUsersProfilePlaceholderPhoneNumber'|translate }}</mat-label>
                            <input matInput type="tel" [formControl]="phoneControl">
                        </mat-form-field>
                        <br>
                        <div class="profile-form-item-account">
                            <mat-form-field *ngIf="permitViewAccount" class="profile-form-item">
                                <mat-label>{{ 'settingsUsersProfilePlaceholderAccount'|translate }}</mat-label>
                                <mat-select [formControl]="accountIdControl">
                                    <mat-option *ngFor="let account of accounts" [value]="account.id">
                                        {{ account.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <app-secure-link
                                    class="profile-account-link"
                                    *ngIf="disableSave() && hasAccount()"
                                    [permission]="canViewAccount()"
                                    [target]="accountTarget()"
                                    [hideWhenForbidden]="true">
                                <mat-icon>link</mat-icon>
                            </app-secure-link>
                        </div>
                        <br>
                        <app-context-selector
                                [forUser]="userId"
                                [widthPercentage]="95"
                                [defaultLabel]="'settingsUsersProfileDefaultHomeContext'"
                                (contextChanged)="onContextChange($event)">
                        </app-context-selector>
                        <br>
                    </div>
                    <div class="profile-first-row-action">
                        <button *ngIf="permitViewCases" mat-raised-button
                                class="action-button"
                                i18n="@@settingsUsersProfileButtonViewCases"
                                style="margin-bottom: 10px;"
                                [disabled]="!disableSave()"
                                type="button"
                                (click)="viewCases($event.metaKey)">
                            {{ 'settingsUsersProfileButtonViewCases'|translate }}
                        </button>
                        <button *ngIf="permitTransferCase" mat-raised-button
                                class="action-button"
                                i18n="@@settingsUsersProfileButtonTransferCases"
                                style="margin-bottom: 10px;  letter-spacing: normal;"
                                (click)="transferCases()">
                            {{ 'settingsUsersProfileButtonTransferCases'|translate }}
                        </button>
                        <button *ngIf="permitDelete" mat-raised-button
                                class="action-button"
                                i18n="@@settingsUsersProfileButtonDeleteUser"
                                color="warn"
                                (click)="deleteUser()">
                            {{ 'delete'|translate }}
                        </button>
                    </div>
                </div>
                <div *ngIf="permissionsArray.enabled">
                    <span class="mat-typography" i18n="@@settingsUsersProfilePermissionsTitle">
                        <strong>{{ 'settingsUsersProfilePermissionsTitle'|translate }}</strong>
                    </span>
                    <div formArrayName="permissions" class="permission-list">
                        <mat-checkbox *ngFor="let control of permissionsArray.controls; let i = index"
                                      class="permission-item"
                                      [checked]="control.value"
                                      [formControlName]="i">
                            {{ getPermissionLabel(i) | translate }}
                        </mat-checkbox>
                    </div>
                </div>
                <div *ngIf="roleControl.enabled">
                    <span class="mat-typography" i18n="@@settingsUsersProfileRolesTitle">
                        <strong>{{ 'settingsUsersProfileRolesTitle'|translate }}</strong>
                    </span>
                    <div class="role-section">
                        <mat-radio-group class="role-section" [formControl]="roleControl">
                            <mat-radio-button class="role-item" *ngFor="let role of roles" [value]="role.name">
                                {{ role.labelKey | translate }}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
                <div class="profile-last-row">
                    <button mat-raised-button
                            class="profile-button"
                            color="primary"
                            type="submit"
                            i18n="@@settingsUsersProfileButtonSave"
                            [disabled]="disableSave()">
                        {{ 'save'|translate }}
                    </button>
                    <mat-checkbox
                            class="profile-disable-user"
                            *ngIf="disabledControl.enabled"
                            i18n="@@settingsUsersProfileCheckboxDisabled"
                            [formControl]="disabledControl">
                        {{ 'settingsUsersProfileCheckboxDisabled'|translate }}
                    </mat-checkbox>
                </div>
                <div class="profile-flash-section">
                    <p *ngIf="flash.visible && !flash.isError"
                       class="flash-message mat-typography">
                        {{ flash.messageKey | translate }}
                    </p>
                    <p *ngIf="flash.visible && flash.isError"
                       class="flash-message flash-error mat-typography">
                        {{ flash.messageKey | translate }}
                    </p>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
    <mat-card appearance="outlined" class="panel panel-primary user-features-card" *ngIf="enabledFeaturesArray.enabled">
        <mat-card-title class="sis-card-title" i18n="@@settingsUsersProfileFeaturesTitle">
            {{ 'settingsUsersProfileFeaturesTitle'|translate }}
        </mat-card-title>
        <mat-card-content>
            <div>
                <div formArrayName="enabled_features" class="feature-list">
                    <mat-checkbox *ngFor="let control of enabledFeaturesArray.controls; let i = index"
                                  class="feature-item"
                                  [checked]="control.value"
                                  [formControlName]="i">
                        {{ getFeatureLabel(i) | translate }}
                    </mat-checkbox>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</form>

<mat-card appearance="outlined" class="upload-transform-box">
    <mat-card-title class="upload-header">
        <div class="upload-header-text">
            <span class="mat-typography upload-title">{{ titleKey | translate }}</span>
            <span class="mat-typography upload-sub-title">Supported: {{supportedFormats.join(', ')}}</span>
        </div>
        <button mat-raised-button
                class="upload-button"
                (click)="upload(filesInput)"
                [disabled]="!filesInput.files.length || !!currentUpload || !canUpload">
            Upload
        </button>
    </mat-card-title>
    <mat-card-content>
        <div class="upload-upload">
            <input #filesInput
                   style="width: 180px; font-size: 12px; margin-left: 10px"
                   type="file"
                   [disabled]="currentUpload || !canUpload"/>
        </div>
        <div class="upload-report">
            <p class="mat-typography upload-report-item"><b>Uploaded Files:</b>&nbsp;<span
                [ngClass]="getFileCountClass()">{{getFileCount()}}</span></p>
            <div class="mat-typography upload-report-details">
                <p style="margin: 0 0 0 0;"><b></b>&nbsp;<span></span></p>
                <p style="margin: 0 0 0 0;"><b></b>&nbsp;<span></span></p>
                <p style="margin: 0 0 0 0;"><b></b>&nbsp;<span></span></p>
                <p style="margin: 0 0 0 0;"><b></b>&nbsp;<span></span></p>
            </div>
            <p class="mat-typography upload-report-item" style="margin-bottom: 10px;"><b>Errors:</b>
                {{uploadErrors}}</p>
        </div>
        <div class="upload-progress">
            <mat-progress-bar
                mode="{{getProgressMode()}}"
                color="primary"
                value="{{progress}}">
            </mat-progress-bar>
        </div>
        <div class="upload-progress-report">
            <p class="mat-typography" *ngIf="!uploadError">{{progressMessage}}</p>
            <p class="mat-typography upload-error" *ngIf="uploadError">{{uploadError.message}}</p>
        </div>
    </mat-card-content>
</mat-card>

<h2 mat-dialog-title i18n="@@settingsGroupsNewGroupTitle">{{'settingsGroupsNewGroupTitle'|translate}}</h2>
<form>
    <mat-dialog-content>
        <p>
            <mat-form-field style="margin: auto; width: 100%;">
                <mat-label>{{'settingsGroupsNewGroupNamePlaceholder'|translate}}</mat-label>
                <input matInput name="groupName" data-testid="create-group-name" [(ngModel)]="newGroup.name" required>
            </mat-form-field>
        </p>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-raised-button
                i18n="@@settingsGroupsNewGroupCancel"
                data-testid="create-group-cancel"
                [mat-dialog-close]=null>
            {{'cancel'|translate}}
        </button>
        <button mat-raised-button
                i18n="@@settingsGroupsNewGroupSave"
                data-testid="create-group-confirm"
                (click)="onSubmit()"
                [disabled]="!enableSubmission()">
            {{'save'|translate}}
        </button>
    </mat-dialog-actions>
</form>

<div class="fill-parent">
    <div style="height: 50px; width: 100%;"></div>
    <div class="flows-main">
        <div class="mat-elevation-z8">
            <mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="case_id" i18n="@@dashboardFlowsTableColumnCaseId">
                    <mat-header-cell *matHeaderCellDef
                                     mat-sort-header>{{'dashboardFlowsTableColumnCaseId'|translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let flowRow" matTooltip="Flow ID: [{{flowRow.id}}]">
                        {{flowRow.case_id}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="name" i18n="@@dashboardFlowsTableColumnName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="mat-column-300">
                        {{'name'|translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let flowRow" class="mat-column-300">
                        {{getFlowName(flowRow.code)}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="submitted" i18n="@@dashboardFlowsTableColumnSubmitted">
                    <mat-header-cell *matHeaderCellDef
                                     mat-sort-header>{{'dashboardFlowsTableColumnSubmitted'|translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let flowRow">{{flowRow.submitted | localDate}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="started" i18n="@@dashboardFlowsTableColumnStarted">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'started'|translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let flowRow">{{flowRow.start_time | localDate}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="progress" i18n="@@dashboardFlowsTableColumnProgress">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'progress'|translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let flowRow">
                        <mat-progress-bar
                                style="width: 40%; margin-right: 10px"
                                [value]="100*flowRow.progress">
                        </mat-progress-bar>
                        {{flowRow.progress | percent}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="environment" i18n="@@dashboardFlowsTableColumnEnvironment">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'environment'|translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let flowRow">
                        {{flowRow.cluster_name}} [sw={{flowRow.code_version}}]
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let flowRow; columns: displayedColumns;"
                         class="table-row" (click)="onActivate(flowRow, $event.metaKey)">
                </mat-row>
            </mat-table>
            <mat-paginator [pageSize]="10" [hidePageSize]="true"></mat-paginator>
        </div>
    </div>
</div>

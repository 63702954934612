<div *ngIf="enableLangSelection()" class="language-selector">
    <mat-select class="mat-typography"
                style="font-weight: 500"
                [(ngModel)]="selected"
                data-testid="lang-selector"
                name="languageSelector"
                panelWidth=""
                ngDefaultControl
                (selectionChange)="onSelectLanguage($event)">
        <mat-option *ngFor="let lang of translate.langs" [value]="lang"
                    data-testid="lang-options">{{lang|translate}}</mat-option>
    </mat-select>
</div>

import { Account, ContextInformation, Group } from '../services/api.service';
import { User, userDescription, userSearchableText } from '../services/user.service';

export const USER_CONTEXT_KEY = 'userId';
export const GROUP_CONTEXT_KEY = 'groupId';
export const ACCOUNT_CONTEXT_KEY = 'accountId';

export const PERSON_ICON = 'person';
export const GROUP_ICON = 'group';
export const ACCOUNT_ICON = 'business';

export const CONTEXT_KEYS = [USER_CONTEXT_KEY, GROUP_CONTEXT_KEY, ACCOUNT_CONTEXT_KEY];

export abstract class AutoCompleteItem {
    public selected: boolean = false;

    protected constructor(
        public id: string,
        public contextKey: string,
        private autoCompleteLabel: string | null,
        public search: string,
        public display: string,
        public iconKey: string,
    ) {
    }

    get labelKey(): string {
        return this.autoCompleteLabel;
    }

    get name(): string {
        return this.display;
    }

    get contextInfo(): ContextInformation {
        return {context_key: this.contextKey, context_id: this.id};
    }
}

export class AutoCompleteUser extends AutoCompleteItem {
    constructor(user: User, labelKey: string | null = null) {
        super(user.id, USER_CONTEXT_KEY, labelKey, userSearchableText(user), userDescription(user), PERSON_ICON);
    }
}

export class AutoCompleteGroup extends AutoCompleteItem {
    constructor(group: Group, labelKey: string | null = null) {
        super(group.id, GROUP_CONTEXT_KEY, labelKey, group.name, group.name, GROUP_ICON);
    }
}

export class AutoCompleteAccount extends AutoCompleteItem {
    constructor(account: Account, labelKey: string | null = null) {
        super(account.id, ACCOUNT_CONTEXT_KEY, labelKey, account.name, account.name, ACCOUNT_ICON);
    }
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import {
    AlphaImage,
    AlphaMap,
    DbsTarget,
    DbsTargets,
    Elements,
    Flows, GpAlpha,
    StnAlpha,
    Targets, VimAlpha
} from '../case.constants';
import { ActivityLogLevel, Case, CaseService, DeleteCaseResponse } from '../case.service';
import { DataElement } from '../data-element.class';
import { UploadService } from '../upload/upload.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmCancelDialogComponent } from '../../tools/confirm-dialog.component';
import { Permission, Permissions } from '../../security/permissions.class';
import { Subscriptions } from '../../tools/subscriptions.class';
import { PermissionsService } from '../../services/permissions.service';
import { Group, GroupService } from '../../services/group.service';
import { from, Observable, Subject } from 'rxjs';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import { FlowResponse, UploadReport } from '../../services/api.service';
import { ElectrodesUpdate } from '../electrode-model/electrode-model.component';
import { Features } from '../../security/feature.class';
import { UploadDicomRequest } from '../upload/upload-dicom.component';
import { DeleteCaseComponent, DeleteCaseDecision } from './delete-case/delete-case.component';
import { ElectrodeModel } from '../electrode-model/electrode-model.constants';
import { Visibilities } from '../../security/visibility.class';
import { saveAs } from 'file-saver';

export interface ActivityLog {
    case_id: string;
    created: string;
    id: string;
    level: number;
    message: string;
}

export enum DeleteCaseState {
    Idle, InProgress, Success, Failed
}

// A group constant to use for no assigned group. This group has a non-null id so that the value seen by mat-select
// is non-null (so that the placeholder text stays floating above the field). Having a fake id here means that when
// a case has a null group_id value, it must be translated, and likewise that this value must be translated back to
// null. The functions encodeGroupId() and decodeGroupId() below perform these functions. The name field is empty so
// that this group sorts to the front of the list; 'none' is provided in the HTML template for actual display.
const NO_GROUP: Group = {
    id: '--no-group-id--',
    name: '',
    created: '',
    updated: ''
};

function encodeGroupId(originalGroupId: string): string {
    return originalGroupId ? originalGroupId : NO_GROUP.id;
}

function decodeGroupId(encodedGroupId: string, noGroupValue: string = null): string {
    return encodedGroupId === NO_GROUP.id ? noGroupValue : encodedGroupId;
}

@Component({
    selector: 'app-case-overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.scss']
})

export class OverviewComponent implements OnDestroy, OnInit {

    static readonly userColumns = ['date', 'message'];
    static readonly adminColumns = ['date', 'level', 'message'];

    @Input() caseData: Case;
    @Input() caseUpdated: Subject<Case>;
    @Input() approvedTargets: Array<DbsTarget>;
    @Output() uploadInProgress = false;
    @Output() deleteCaseUpdates: EventEmitter<DeleteCaseState> = new EventEmitter<DeleteCaseState>();
    @Output() notifyUploadDicomRequest: Subject<UploadDicomRequest> = new Subject<UploadDicomRequest>();

    // reference the AlphaImage objects of interest for the HTML code.
    public readonly stnAlpha: AlphaImage = StnAlpha;
    public readonly gpAlpha: AlphaImage = GpAlpha;
    public readonly vimAlpha: AlphaImage = VimAlpha;

    name = '';
    description = '';
    groupId = NO_GROUP.id;

    elements = Elements;
    shareGroups: Group[] = [];

    caseId = '';
    canUploadPlanning: boolean;
    canUploadTargeting: boolean;
    canUploadPostOp: boolean;

    canPublishPrediction = false;
    canPublishPostOp = false;

    allowedUploadPlanning = false;
    allowedUploadTargeting = false;
    allowedUploadPostop = false;
    allowedPublishPrediction = false;
    allowedPublishPostOp = false;
    allowedUnpublish = false;
    allowedArchiveCase = false;
    allowedRestoreCase = false;
    allowedDeleteCase = false;
    allowedSubmitCase = false;
    allowedDownloadAlpha = false;
    allowAutoDetectElectrode = false;
    allowedDisableDataValidation = false;
    allowedHideDebug = false;

    deleteInProgress = false;
    cannotSaveChanges = true;

    downloadInProgress = false;

    activityLogDataSource = new MatTableDataSource<ActivityLog>();
    activityLogDisplayedColumns = OverviewComponent.userColumns;
    activityLogHideDebug = false;

    private subscriptions = new Subscriptions();

    private leftSelection: ElectrodeModel = null;
    private rightSelection: ElectrodeModel = null;
    public electrodesSelectionChanged = false;

    constructor(private permissionsService: PermissionsService, private caseService: CaseService,
                private router: Router, private uploadService: UploadService,
                private groupService: GroupService, private dialog: MatDialog) {
    }

    public ngOnInit() {
        this.subscriptions.add(this.caseUpdated, data => {
            this.caseData = data;
            this.readCaseData();
            this.refreshShareGroups();
        });

        this.subscriptions.add(this.uploadService.onIsEmptyChange(), isEmpty => this.uploadInProgress = !isEmpty);

        this.subscriptions.add(this.permissionsService.permissions(), () => {
            this.refreshPermissions();
            this.refreshShareGroups();
            this.refreshActivityLogColumns();
        });
    }

    public ngOnDestroy(): void {
        this.subscriptions.cancel();
    }

    public onInputChange() {
        this.cannotSaveChanges = false;
    }

    public saveCaseChanges() {
        const payload = {
            name: this.name,
            description: this.description,
            electrodes: this.electrodesSelectionChanged ?
                {left: this.leftSelection.payload, right: this.rightSelection.payload} : null
        };

        this.subscriptions.add(this.caseService.updateCase(this.caseId, payload),
            (data: Case) => {
                this.cannotSaveChanges = true;
                this.caseUpdated.next(data);
            },
            (error) => alert(error.message)
        );
    }

    public shareChanged() {
        if (decodeGroupId(this.groupId) !== this.caseData.group_id) {
            this.subscriptions.add(this.caseService.shareCase(this.caseId, {groupId: decodeGroupId(this.groupId)}),
                data => {
                    this.caseData = data;
                    this.readCaseData();
                    this.caseUpdated.next(data);
                },
                (error) => alert(error.message)
            );
        }
    }

    public electrodesChanged(update: ElectrodesUpdate) {
        this.electrodesSelectionChanged = update.userDriven;
        this.leftSelection = update.leftSelection;
        this.rightSelection = update.rightSelection;
    }

    public getActivityRowClass(activityRow: ActivityLog) {
        return {
            'activity-log': true,
            debug: activityRow.level === ActivityLogLevel.DEBUG,
            info: activityRow.level === ActivityLogLevel.INFO,
            warning: activityRow.level === ActivityLogLevel.WARNING,
            error: activityRow.level === ActivityLogLevel.ERROR
        };
    }

    public activityIcon(activityRow: ActivityLog): string {
        switch (activityRow.level) {
            case ActivityLogLevel.DEBUG:
                return 'info';  // lock, notes, flag, bolt, shield, details, build, visibility_off
            // case ActivityLogLevel.INFO: return 'info';
            // case ActivityLogLevel.WARNING: return 'warning';
            // case ActivityLogLevel.ERROR: return 'error';
            default:
                return '';
        }
    }

    /**
     * Can we submit the flow?
     * If we have flow in progress or upload in progress we cant.
     * if not in progress, check if we can submit any of the flows, if one of them can be submitted, return true
     */
    public enableSubmitCase(): boolean {
        // A case that is being deleted cannot be submitted
        if (this.deleteInProgress) {
            return false;
        }

        // if we have upload in progress or flow in progress submit is disabled.
        // A non published flow is not considered in-progress
        if (this.uploadInProgress) {
            return false;
        }
        // The caseService can answer if the prediction flow can be submitted based on data elements.
        // For the postop flow - we need a combined decision from case and electrode service.
        // So the overview component will combine
        return (this.caseService.canSubmitPrediction(this.caseData) || this.canSubmitPostop(this.caseData));
    }

    /**
     * The user should be able to submit the postop flow in the following cases:
     * Before postop flow completed:
     *      postop image uploaded
     *      We have a valid electrode selection (at least one side is not None)
     * After postop flow completed and published:
     *      We have a new postop image and valid electrodes selection or a new valid electrode selection
     * The payload of a model is the left and right values reported through ElectrodesUpdate (from the component)
     * @param caseData - dictionary of case elements and flows
     */
    private canSubmitPostop(caseData: Case): boolean {
        return this.caseService.canSubmitPostop(caseData, this.allowAutoDetectElectrode);
    }

    public submitCase() {
        this.caseService.submitCase(this.caseData).subscribe(newData => this.maybeUpdateCase(newData));
    }

    /***
     * Checks if the case has an AlphaElement of this type and the case targets include this alpha
     * @param alpha - one of the AvailableAlphas options
     * return - true if the alpha exist and should be displayed based on the case target, else false.
     */
    public showDownloadAlpha(alpha: AlphaImage): boolean {
        if (this.caseData === null || this.caseData.target === Targets.ALL) {
            return false;
        }
        const hasAlpha = this.caseService.hasElement(this.caseData, alpha.elementId);
        return hasAlpha && DbsTargets.get(this.caseData.target).elements.includes(alpha.elementId);
    }

    /***
     * Checks if the overview screen should use the single download alpha option
     * return - true if case data is available and target is ALL structures.
     */
    public useSingleDownloadAlpha(): boolean {
        return this.caseData !== null && this.caseData.target === Targets.ALL;
    }

    /**
     * Return true the case data has at least 1 approved segmentation with an Alpha image.
     */
    public hasAtLeastOneApprovedAlphaImage(): boolean {
        if (this.caseData === null) {
            return false;
        }
        return this.approvedTargets.some(t => this.caseService.hasElement(this.caseData, AlphaMap.get(t.key).elementId));
    }

    get alphaOptions(): Array<AlphaImage> {
        return this.approvedTargets.map(t => AlphaMap.get(t.key)).filter(alpha => this.canDownloadAlpha(alpha));
    }

    public canDownloadAlpha(alpha: AlphaImage): boolean {
        const hasAlpha = this.caseService.hasElement(this.caseData, alpha.elementId);
        return this.allowedDownloadAlpha && hasAlpha && this.approvedTargets.includes(alpha.target);
    }

    public publishPrediction() {
        if (this.allowedPublishPrediction && this.canPublishPrediction) {
            return this.publishFlows([
                this.caseService.latestTargetingFlow(this.caseData),
                this.caseService.latestPlanningFlow(this.caseData),
            ]);
        }
    }

    public publishPostOp() {
        if (this.allowedPublishPostOp && this.canPublishPostOp) {
            return this.publishFlows([
                this.caseService.latestFlow(this.caseData, [Flows.BG_POST_OP])
            ]);
        }
    }

    public unpublishCase() {
        this.caseService.unpublishCase(this.caseData.id).subscribe(newData => this.maybeUpdateCase(newData));
    }

    public downloadAlpha(alpha: AlphaImage, saver: (blob: Blob, filename: string) => any = saveAs) {
        if (this.canDownloadAlpha(alpha)) {
            const element = this.caseService.latestElement(this.caseData, alpha.elementId);
            if (DataElement.urlCount(element) > 0) {
                this.downloadInProgress = true;
                this.subscriptions.add(this.caseService.getElementZipFile(this.caseId, element.id),
                    (blob: Blob) => {
                        this.downloadInProgress = false;
                        saver(blob, `Planning_${this.alphaName(alpha)}_Visualization.zip`);
                    },
                    () => {
                        this.downloadInProgress = false;
                    }
                );
            }
        }
    }

    public showRestoreButton(): boolean {
        return this.allowedRestoreCase;
    }

    public disableRestoreButton(): boolean {
        return this.caseData && (this.caseData.archived === null);
    }

    public canUnpublish(): boolean {
        return this.allowedUnpublish && this.caseService.isCasePublished(this.caseData);
    }

    public restoreCase(): void {
        this.caseService.restoreCase(this.caseId).subscribe((updatedCase: Case) => {
            this.caseUpdated.next(updatedCase);
        });
    }

    public enableDeleteButton(): boolean {
        return this.allowedDeleteCase || this.canArchive();
    }

    private canArchive(): boolean {
        return this.allowedArchiveCase && !this.caseService.isCasePublished(this.caseData);
    }

    public deleteCase(): void {
        const dialogRef = this.dialog.open(DeleteCaseComponent, {
            width: '400px', data: {
                allowArchive: this.canArchive(),
                allowDelete: this.allowedDeleteCase
            }
        });
        dialogRef.afterClosed().subscribe((decision: DeleteCaseDecision) => {
            switch (decision) {
                case DeleteCaseDecision.Cancel:
                    return;
                case DeleteCaseDecision.Archive:
                    this.onDeleteRequest(this.caseService.archiveCase(this.caseId));
                    this.deleteCaseUpdates.emit(DeleteCaseState.InProgress);
                    break;
                case DeleteCaseDecision.Delete:
                    this.onDeleteRequest(this.caseService.deleteCase(this.caseId));
                    this.deleteCaseUpdates.emit(DeleteCaseState.InProgress);
            }
        });
    }

    private onDeleteRequest(request: Observable<DeleteCaseResponse>) {
        this.deleteInProgress = true;
        request.subscribe({
            next: () => {
                this.deleteInProgress = false;
                this.deleteCaseUpdates.emit(DeleteCaseState.Success);
                this.router.navigate(['/home']).then();
            },
            // this should not happen since the UI block archive when it is not possible
            // to archive. But just in case, we should not leave the flashing delete message forever
            error: () => {
                this.deleteInProgress = false;
                this.deleteCaseUpdates.emit(DeleteCaseState.Failed);
            }
        });
    }

    public maybeUpdateCase(data: Case) {
        // Only load the case if it is the same case currently loaded.
        if (data.id === this.caseId) {
            this.caseData = data;
            this.caseUpdated.next(data);
        }
    }

    public uploadCount(element: DataElement): number {
        const latestElement = this.caseService.latestElement(this.caseData, element);
        return DataElement.urlCount(latestElement);
    }

    public uploadReport(element: DataElement): UploadReport | null {
        const latestElement = this.caseService.latestElement(this.caseData, element);
        return latestElement?.upload_report || null;
    }

    public onActivate(row: ActivityLog) {
        const dialogData = {
            title: 'caseActivityLogDetails',
            contentText: row.message,
            cancelText: 'ok',
            actionText: ''
        };
        this.dialog.open(ConfirmCancelDialogComponent, {
            width: '400px', data: dialogData
        });
    }

    public hasGroup(): boolean {
        return this.groupId !== NO_GROUP.id;
    }

    public canViewGroup(): Permission {
        return Permissions.groupView(this.groupId);
    }

    public groupTarget(): string[] {
        return ['/settings/group', this.groupId];
    }

    private refreshPermissions() {
        this.permissionsService.batch(this.subscriptions, [
            [Permissions.caseUpload(this.caseId, Elements.T1_DICOM.name), allowed => {
                this.allowedUploadPlanning = allowed;
            }],
            [Permissions.caseUpload(this.caseId, Elements.T2_DICOM.name), allowed => {
                this.allowedUploadTargeting = allowed;
            }],
            [Permissions.caseUpload(this.caseId, Elements.POSTOP_DICOM.name), allowed => {
                this.allowedUploadPostop = allowed;
            }],
            [Permissions.flowPublish(this.caseId), allowed => {
                this.allowedPublishPrediction = allowed;
                this.allowedPublishPostOp = allowed;
            }],
            [Permissions.caseUnpublish(this.caseId), allowed => {
                this.allowedUnpublish = allowed;
            }],
            [Permissions.caseArchive(this.caseData.id), allowed => {
                this.allowedArchiveCase = allowed;
            }],
            [Permissions.caseRestore(this.caseId), allowed => {
                this.allowedRestoreCase = allowed;
            }],
            [Permissions.caseDelete(this.caseId), allowed => {
                this.allowedDeleteCase = allowed;
            }],
            [Permissions.caseSubmit(this.caseId), allowed => {
                this.allowedSubmitCase = allowed;
            }],
            [Permissions.featureAvailable(Features.DOWNLOAD_ALPHA), allowed => {
                this.allowedDownloadAlpha = allowed;
            }],
            [Permissions.featureAvailable(Features.ENABLE_AUTO_DETECT), allowed => {
                this.allowAutoDetectElectrode = allowed;
            }],
            [Permissions.featureAvailable(Features.DISABLE_DI), allowed => {
                this.allowedDisableDataValidation = allowed;
            }],
            [Permissions.userAdmin(), allowed => {
                this.allowedHideDebug = allowed;
            }]
        ]);
    }

    private refreshShareGroups() {
        if (!this.caseData) {
            return;
        }
        this.subscriptions.add(this.groupService.getGroups(), groups => {
            groups.push(NO_GROUP);

            const permissions = groups.map(group => Permissions.caseShareWithGroup(this.caseData.id, decodeGroupId(group.id)));
            this.permissionsService.prefetch(permissions);

            this.shareGroups = [];
            const accumulator: Group[] = [];
            this.subscriptions.add(
                from(groups).pipe(
                    map((group: Group) => {
                        return {
                            group,
                            permission: Permissions.caseShareWithGroup(this.caseData.id, decodeGroupId(group.id))
                        };
                    }),
                    mergeMap(groupPermission => {
                        return this.permissionsService.hasPermission(groupPermission.permission).pipe(
                            take(1),
                            filter(x => x || decodeGroupId(groupPermission.group.id) === this.caseData.group_id),
                            map(() => groupPermission.group)
                        );
                    })
                ),
                group => accumulator.push(group),
                undefined,
                () => {
                    accumulator.sort((a, b) => a.name.localeCompare(b.name));
                    this.shareGroups = accumulator;
                }
            );
        });
    }

    private refreshActivityLogColumns(): void {
        this.subscriptions.add(this.permissionsService.hasPermission(Permissions.userAdmin()), allowed => {
            this.activityLogDisplayedColumns = allowed ? OverviewComponent.adminColumns : OverviewComponent.userColumns;
        });
    }

    private readCaseData(): void {
        this.name = this.caseData.name;
        this.caseId = this.caseData.id;
        this.groupId = encodeGroupId(this.caseData.group_id);
        this.description = this.caseData.description;

        this.reloadActivityLog();

        this.canUploadPlanning = this.caseService.canUploadPlanning(this.caseData);
        this.canUploadTargeting = this.caseService.canUploadTargeting(this.caseData);
        this.canUploadPostOp = this.caseService.canUploadPostOp(this.caseData);
        this.canPublishPrediction = this.caseService.canPublishPrediction(this.caseData);
        this.canPublishPostOp = this.caseService.canPublishPostop(this.caseData);
    }

    public reloadActivityLog() {
        const activityRows = this.caseData.activity_log
            .slice()
            .filter(row => row.level > ActivityLogLevel.DEBUG || !this.activityLogHideDebug)
            .sort((a, b) => b.created.localeCompare(a.created));
        this.activityLogDataSource = new MatTableDataSource<ActivityLog>(activityRows);
    }

    private publishFlows(flows: Array<FlowResponse | null>) {
        // Filter out the nulls or any flows that were already published
        const flowsToPublish = flows.filter(flow => flow !== null && flow.visibility !== Visibilities.USER.code);

        // We only expect there to be 0, 1 or 2 items in the list. If there are two flows in the list that are both the same flow, drop
        // one of them.
        if (flowsToPublish.length == 2 && flowsToPublish[0].id === flowsToPublish[1].id) {
            flowsToPublish.pop();
        }

        // The publish button that led to this point should only be enabled if there was something to publish, but check anyway.
        if (flowsToPublish.length > 0) {
            this.caseService.publishCaseFlow(flowsToPublish[0].id).subscribe(newData => {
                this.maybeUpdateCase(newData)
                if (flowsToPublish.length > 1) {
                    this.caseService.publishCaseFlow(flowsToPublish[1].id).subscribe(newData => {
                        this.maybeUpdateCase(newData)
                    })
                }
            });
        }
    }

    private alphaName(alpha: AlphaImage): string {
        switch (alpha.target.key) {
            // We want "GPI" here, not "GP"
            case Targets.GP: return 'GPI';
            default: return alpha.target.key;
        }
    }
}

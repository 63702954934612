<div class="fill-parent">
    <form class="account-main" #accountForm="ngForm">
        <div class="account-header">
            <div class="account-left-container">
                <mat-form-field style="width: 250px">
                    <mat-label>{{'settingsAccountsAccountName'|translate}}</mat-label>
                    <input matInput
                           name="accountName"
                           [(ngModel)]="model.name"
                           [disabled]="!modifyAccountAllowed"
                           required
                           maxlength="256">
                </mat-form-field>
                <mat-form-field style="width: 450px">
                    <mat-label>{{'description'|translate}}</mat-label>
                    <textarea matInput type="text" name="accountDescription" [(ngModel)]="model.description"
                              [disabled]="!modifyAccountAllowed" maxlength="2000"
                              cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="3">
                    </textarea>
                </mat-form-field>
                <mat-form-field style="width: 150px">
                    <mat-label>{{'state'|translate}}</mat-label>
                    <mat-select name="accountState" [(ngModel)]="model.state" required panelWidth=null>
                        <mat-option *ngFor="let accountState of availableAccountStates; trackBy: trackStateByValue"
                                    [value]="accountState">
                            {{accountState}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <span>
                    <mat-form-field style="width: 450px">
                        <mat-select name="accountUser" [(ngModel)]="model.defaultUserId">
                            <mat-option *ngFor="let user of availableUsers; trackBy: trackUserById"
                                        [value]="user.id">
                                {{userDescription(user)}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <app-secure-link
                            *ngIf="disableSaveAccount()"
                            style="margin-left: 4px"
                            [permission]="canViewUser()"
                            [target]="userTarget()"
                            [hideWhenForbidden]="true">
                        <mat-icon>link</mat-icon>
                    </app-secure-link>
                </span>
                <mat-form-field style="width: 450px">
                    <mat-label>{{'settingsAccountsEditAccountAddressPlaceholder'|translate}}</mat-label>
                    <textarea matInput type="text" name="accountAddress" [(ngModel)]="model.address"
                              [disabled]="!modifyAccountAllowed" maxlength="512"
                              cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="3">
                    </textarea>
                </mat-form-field>
                <mat-form-field style="width: 250px">
                    <mat-label>{{'settingsAccountsEditAccountPhonePlaceholder'|translate}}</mat-label>
                    <input matInput name="accountPhone" [(ngModel)]="model.phone" [disabled]="!modifyAccountAllowed"
                           maxlength="256">
                </mat-form-field>
            </div>
            <div class="account-center-container">
                <div class="emails-inner">
                    <p class="section-header mat-typography" i18n="@@settingsAccountsEditAccountEmailAddressesTitle">
                        {{'settingsAccountsEditAccountEmailAddressesTitle'|translate}}
                    </p>
                    <div style="width: 100%">
                        <mat-table [dataSource]="emailDataSource" class="emails-table">
                            <ng-container matColumnDef="email" i18n="@@settingsAccountsEditAccountEmailsTableEmail">
                                <mat-header-cell *matHeaderCellDef>{{'email'|translate}}</mat-header-cell>
                                <mat-cell *matCellDef="let row">
                                    <mat-form-field style="width: 250px">
                                        <input matInput
                                               type="email"
                                               [name]="'emailEmail-' + row.rowId"
                                               [(ngModel)]="row.email"
                                               required>
                                    </mat-form-field>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="delete" i18n="@@settingsAccountsEditAccountEmailsTableDelete">
                                <mat-header-cell *matHeaderCellDef>{{'delete'|translate}}</mat-header-cell>
                                <mat-cell *matCellDef="let row">
                                    <button mat-icon-button
                                            matTooltip="remove {{row.email || 'email'}} from account"
                                            matTooltipPosition="after"
                                            (click)="removeEmail(row.rowId)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </mat-cell>
                            </ng-container>
                            <mat-header-row *matHeaderRowDef="displayedEmailColumns"></mat-header-row>
                            <mat-row *matRowDef="let email; columns: displayedEmailColumns;">
                            </mat-row>
                        </mat-table>
                        <mat-paginator #emailPaginator [pageSize]="2"></mat-paginator>
                    </div>
                    <button mat-mini-fab
                            class="add-email-btn"
                            i18n-matTooltip="@@settingsAccountsEditAccountAddEmailTooltip"
                            matTooltip="{{'settingsAccountsEditAccountAddEmailTooltip'|translate}}"
                            matTooltipPosition="before"
                            color="accent"
                            *ngIf="modifyAccountAllowed"
                            (click)="addEmail()">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>

            </div>
            <div class="account-right-container">
                <button mat-raised-button
                        *ngIf="listAccountCasesAllowed"
                        style="min-width: 200px; margin-bottom: 20px"
                        type="button"
                        i18n="@@settingsAccountsEditAccountViewCases"
                        (click)="viewCases($event.metaKey)">
                    {{'settingsAccountsViewCases'|translate}}
                </button>
                <button mat-raised-button
                        style="min-width: 200px; margin-bottom: 20px"
                        type="submit"
                        i18n="@@settingsAccountsEditAccountButtonSave"
                        [disabled]="!accountForm.form.valid || disableSaveAccount()"
                        (click)="updateAccountInfo()">
                    {{'save'|translate}}
                </button>
                <button *ngIf="deleteAccountAllowed"
                        mat-raised-button
                        style="min-width: 200px;"
                        type="submit"
                        color="warn"
                        i18n="@@settingsAccountsEditAccountButtonDelete"
                        data-testid="account-delete"
                        (click)="deleteAccount()">
                    {{'delete'|translate}}
                </button>
            </div>
        </div>
        <div class="options-section">
            <p class="section-header mat-typography" i18n="@@settingsAccountsEditAccountOptionsTitle">
                {{'settingsAccountsEditAccountOptionsTitle'|translate}}
            </p>
            <mat-checkbox name="accountAutoPublish"
                          [(ngModel)]="model.autopublish"
                          [disabled]="!modifyAccountAllowed">
                {{'settingsAccountsEditAccountAutoPublish' | translate}}
            </mat-checkbox>
        </div>
        <div class="endpoints-section">
            <p class="section-header mat-typography" i18n="@@settingsAccountsEditAccountTableTitle">
                {{'settingsAccountsEditAccountTableTitle'|translate}}
            </p>
            <div style="width: 100%">
                <mat-table [dataSource]="endpointDataSource" class="endpoints-table">
                    <ng-container matColumnDef="name" i18n="@@settingsAccountsEditAccountTableName">
                        <mat-header-cell *matHeaderCellDef>{{'name'|translate}}</mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <mat-form-field>
                                <input matInput
                                       [name]="'endpointName-' + row.rowId"
                                       [(ngModel)]="row.endpoint.name"
                                       required>
                            </mat-form-field>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="aet_source" i18n="@@settingsAccountsEditAccountTableAetSource">
                        <mat-header-cell *matHeaderCellDef>
                            {{'settingsAccountsEditAccountTableAetSource'|translate}}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <mat-form-field>
                                <input matInput
                                       [name]="'endpointAET-' + row.rowId"
                                       [(ngModel)]="row.endpoint.aet_source"
                                       required>
                            </mat-form-field>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="host" i18n="@@settingsAccountsEditAccountTableHost">
                        <mat-header-cell *matHeaderCellDef>
                            {{'settingsAccountsEditAccountTableHost'|translate}}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <mat-form-field>
                                <input matInput
                                       [name]="'endpointHost-' + row.rowId"
                                       [(ngModel)]="row.endpoint.host"
                                       required>
                            </mat-form-field>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="port" i18n="@@settingsAccountsEditAccountTablePort">
                        <mat-header-cell *matHeaderCellDef>
                            {{'settingsAccountsEditAccountTablePort'|translate}}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <mat-form-field>
                                <input matInput
                                       type="number"
                                       [name]="'endpointPort-' + row.rowId"
                                       [(ngModel)]="row.endpoint.port"
                                       required>
                            </mat-form-field>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="pacs_flavor" i18n="@@settingsAccountsEditAccountTablePacsFlavor">
                        <mat-header-cell *matHeaderCellDef>
                            {{'settingsAccountsEditAccountTablePacsFlavor'|translate}}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <mat-form-field>
                                <mat-select
                                        [name]="'endpointFlavor-' + row.rowId"
                                        [(ngModel)]="row.endpoint.pacs_flavor">
                                    <mat-option *ngFor="let flavor of availableFlavors" [value]="flavor">
                                        {{flavor}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="delete" i18n="@@settingsAccountsEditAccountTableDeleteDicomEndpoint">
                        <mat-header-cell *matHeaderCellDef>{{'delete'|translate}}</mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <button mat-icon-button
                                    i18n-matTooltip="@@settingsAccountsEditAccountTableDeleteDicomEndpointTooltip"
                                    matTooltip="{{'settingsAccountsEditAccountTableDeleteDicomEndpointTooltip'|translate}}"
                                    matTooltipPosition="after"
                                    (click)="removeEndpoint(row.rowId)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedEndpointColumns"></mat-header-row>
                    <mat-row *matRowDef="let endpoint; columns: displayedEndpointColumns;">
                    </mat-row>
                </mat-table>
                <mat-paginator #endpointPaginator [pageSize]="2"></mat-paginator>
            </div>
            <button mat-mini-fab
                    class="add-endpoint-btn"
                    i18n-matTooltip="@@settingsAccountsEditAccountTableAddEndpointTooltip"
                    matTooltip="{{'settingsAccountsEditAccountTableAddEndpointTooltip'|translate}}"
                    matTooltipPosition="before"
                    color="accent"
                    *ngIf="modifyAccountAllowed"
                    (click)="addEndpoint()">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </form>
</div>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { AccountService } from '../../services/account.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Account } from '../../services/api.service';
import { NewAccountComponent } from './new-account/new-account.component';
import { Router } from '@angular/router';
import { TableDirective } from '../../tools/table.directive';
import { PermissionsService } from '../../services/permissions.service';
import { Permissions } from '../../security/permissions.class';
import { Subscriptions } from '../../tools/subscriptions.class';

@Component({
    selector: 'app-accounts',
    templateUrl: './accounts.component.html',
    styleUrls: ['./accounts.component.scss']
})

export class AccountsComponent extends TableDirective<Account> implements OnInit, OnDestroy {

    createAccountsAllowed = false;

    private subscriptions = new Subscriptions();

    constructor(private router: Router, private accountService: AccountService,
                private permissionService: PermissionsService, private dialog: MatDialog) {
        super(['name', 'created', 'updated'], new MatTableDataSource<Account>());
    }

    ngOnInit() {
        this.readAccounts();
        this.subscriptions.add(this.permissionService.permissions(), () => {
            this.subscriptions.add(this.permissionService.hasPermission(Permissions.accountCreate()), allowed => {
                this.createAccountsAllowed = allowed;
            });
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.cancel();
    }

    onActivate(account: Account, metaKey: boolean) {
        this.navigateFromClick(this.router, metaKey, ['settings', 'account', account.id]);
    }

    openCreateAccountDialog() {
        const newAccountDialogOptions = {width: '600px'};
        const dialogRef = this.dialog.open(NewAccountComponent, newAccountDialogOptions);

        dialogRef.afterClosed().subscribe(data => {
            if (data != null) {
                this.readAccounts();
            }
        });
    }

    private readAccounts() {
        this.subscriptions.add(this.accountService.getAccounts(), accounts => {
            this.dataSource.data = [];
            const permissions = accounts.map(account => Permissions.accountView(account.id));
            this.permissionService.prefetch(permissions);

            const visibleAccounts = [];
            let count = 0;
            for (const account of accounts) {
                const permission = Permissions.accountView(account.id);
                this.subscriptions.add(this.permissionService.hasPermission(permission), allowed => {
                    if (allowed) {
                        visibleAccounts.push(account);
                    }
                    count += 1;
                    if (count === accounts.length) {
                        visibleAccounts.sort((a, b) => a.name.localeCompare(b.name));
                        this.dataSource.data = visibleAccounts;
                    }
                });
            }
        });
    }
}

import { Injectable } from '@angular/core';
import { AppConfigService } from '../app-config.service';

export class Quarter {
    private monthsInQ = new Array<number>();

    constructor(public index: number, public starts: Date, public ends: Date) {
        const startMonth = starts.getMonth();
        for (const i of [0, 1, 2]) {
            this.monthsInQ.push((startMonth + i) % 12);
        }
    }

    get months(): Array<number> {
        return this.monthsInQ;
    }

    public static mapQuarters(startMonths: Array<number>): Map<number, Quarter> {
        const quarters = new Map<number, Quarter>();
        startMonths.forEach((sm, index) => {
            quarters.set(index, new Quarter(index, new Date(0, sm, 1), new Date(0, (sm + 3) % 12, 0)));
        });
        return quarters;
    }

    spansYears(): boolean {
        return this.monthsInQ[2] < this.monthsInQ[0];
    }
}

@Injectable()
export class QuartersService {

    private readonly qMap: Map<number, Quarter>;

    constructor(private config: AppConfigService) {
        this.qMap = Quarter.mapQuarters(config.get(AppConfigService.QUARTERS));
    }

    public getQuarterByMonth(month: number): Quarter {
        const quarter = Array.from(this.qMap.values()).find(q => q.months.includes(month));
        if (quarter) {
            return quarter;
        }
        throw new Error(`Invalid month ${month}`);
    }

    private getQuarterByIndex(index: number): Quarter {
        return this.qMap.get(index);
    }

    get quarters(): Map<number, Quarter> {
        return this.qMap;
    }

    public currentQuarter(): [Date, Date] {
        const now = new Date();
        const year = now.getFullYear();
        const q = this.getQuarterByMonth(now.getMonth());
        const startDate = new Date(q.spansYears() ? year - 1 : year, q.starts.getMonth(), q.starts.getDate());
        return [startDate, now];
    }

    public prevQuarter(): [Date, Date] {
        const now = new Date();
        const currentQ = this.getQuarterByMonth(now.getMonth());
        const prevQ = currentQ.index === 0 ? this.getQuarterByIndex(3) : this.getQuarterByIndex(currentQ.index - 1);
        const startYear = prevQ.starts.getMonth() > now.getMonth() ? now.getFullYear() - 1 : now.getFullYear();
        const startDate = new Date(startYear, prevQ.starts.getMonth(), prevQ.starts.getDate());
        const endYear = prevQ.ends.getMonth() > now.getMonth() ? now.getFullYear() - 1 : now.getFullYear();
        const endDate = new Date(endYear, prevQ.ends.getMonth(), prevQ.ends.getDate(), 23, 59, 59);
        return [startDate, endDate];
    }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import { DEFAULT_INTERRUPTSOURCES, Idle, InterruptSource } from '@ng-idle/core';
import { Subscription } from 'rxjs';
import { UploadInterruptSource } from './case/upload/upload-interrupt-source';
import { UploadService } from './case/upload/upload.service';
import { AuthGuardService } from './services/authguard.service';
import { TosService } from './services/tos.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TosComponent } from './tos/tos.component';
import { TranslateService } from '@ngx-translate/core';
import { SavedLanguageKey } from './tools/languages.class';
import { AppConfigService } from './app-config.service';
import { LanguageService } from './services/language.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {

    showNavBar = false;
    isIdle: boolean;
    secondsRemaining: number;

    // How much time without activity can elapse before the user is considered
    // to be idle.
    private idleThreshold = 59 * 60; // 59 minutes

    // How much time, once idle, before the user will be logged out.
    private idleTimeout = 60; // 1 minute

    private subscriptions: Array<Subscription> = [];

    constructor(private auth: AuthService,
                private idle: Idle,
                private upload: UploadService,
                private authGuard: AuthGuardService,
                private tosService: TosService,
                private dialog: MatDialog,
                public translate: TranslateService,
                private languageService: LanguageService,
                appConfig: AppConfigService) {
        // authGuard is referenced only to force it to be created immediately so that its
        // subscriptions and associated behavior.
        this.setLanguages(appConfig.get(AppConfigService.LANGUAGES));
    }

    ngOnInit(): void {
        this.idle.setIdle(this.idleThreshold);
        this.idle.setTimeout(this.idleTimeout);
        this.idle.setInterrupts(this.interruptSources());

        this.showNavBar = this.auth.isAuthed();

        this.subscriptions.push(
            this.auth.afterLogin.subscribe({
                next: () => {
                    this.idle.watch();
                    this.showNavBar = true;
                }
            }),
            this.idle.onIdleStart.subscribe(() => {
                this.isIdle = true;
                this.secondsRemaining = this.idle.getTimeout();
            }),
            this.idle.onIdleEnd.subscribe(() => this.isIdle = false),
            this.idle.onTimeoutWarning.subscribe((secondsRemaining) => this.secondsRemaining = secondsRemaining),
            this.idle.onTimeout.subscribe(() => {
                this.auth.logout('Session expired');
            }),
            this.auth.afterLogout.subscribe({
                next: () => {
                    this.idle.stop();
                    this.isIdle = false;
                    this.showNavBar = false;
                }
            })
        );

        this.auth.start();
        this.tosService.start();
        this.subscriptions.push(this.tosService.showTos.subscribe(
            (launchTos: boolean) => this.showTos(launchTos)
        ));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.idle.stop();
        this.tosService.stop();
        this.auth.stop();
    }

    private setLanguages(languages: Array<string>) {
        this.translate.addLangs(languages);
        const latestLang = localStorage.getItem(SavedLanguageKey);
        if (latestLang && this.translate.langs.includes(latestLang)) {
            this.translate.use(latestLang);
        }
        else {
            localStorage.setItem(SavedLanguageKey, languages[0]);
            this.translate.use(languages[0]);
        }
    }

    private interruptSources(): Array<InterruptSource> {
        const result: Array<InterruptSource> = [];
        result.push(...DEFAULT_INTERRUPTSOURCES);
        result.push(new UploadInterruptSource(this.upload));
        return result;
    }

    private showTos(launchTos: boolean) {
        if (launchTos) {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.data = {content: this.tosService.getContent()};

            const dialogRef = this.dialog.open(TosComponent, dialogConfig);
            dialogRef.afterClosed().subscribe(
                (tosAccepted: boolean) => this.tosService.onTosAccepted(tosAccepted)
            );
        }
    }
}

<div class="fill-parent">
    <div class="home-row">
        <div class="home-header-section">
            <app-context-selector
                    class="home-header-autocomplete"
                    [widthPercentage]="100"
                    [forceDefault]="true"
                    [forUser]="auth.currentUserId"
                    [defaultLabel]="'homeUserSelectContext'"
                    [startLoading]="parentReady"
                    (contextChanged)="onContextChange($event)">
            </app-context-selector>
            <mat-form-field class="home-header-filter" appearance="outline" subscriptSizing="dynamic">
                <mat-label>{{ 'homeFilterPlaceholder'|translate }}</mat-label>
                <input matInput
                       [(ngModel)]="searchText"
                       (ngModelChange)="applyFilter()"
                       [ngModelOptions]="{standalone: true}">
            </mat-form-field>
            <div class="home-header-control">
                <mat-checkbox style="margin-top: 20px;"
                              *ngIf="canViewArchivedCases"
                              [checked]="includeArchived()"
                              (change)="showArchiveChanged($event)">
                    {{ 'showArchivedCheckbox'|translate }}
                </mat-checkbox>
            </div>
        </div>
    </div>
    <div class="home-main mat-elevation-z8">
        <mat-table [dataSource]="dataSource" matSort data-testid="home-cases-table">
            <ng-container matColumnDef="name" i18n="@@homeTableColumnName">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                                 class="home-column-200-start">{{ 'name'|translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let caseRow" class="home-column-200-start"
                          [matTooltip]="caseRow.description"
                          [matTooltipDisabled]="caseRow.description === null || caseRow.description.trim().length === 0"
                          [matTooltipShowDelay]="3000">
                    <span class="truncate-text" style="margin-right: 20px;"
                          [ngClass]="maybeArchivedClass(caseRow)">
                        {{ caseRow.name }}
                    </span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="target" i18n="@@homeTableColumnTarget">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                                 class="home-column-150-start">{{ 'homeColumnStructure'|translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let caseRow"
                          class="home-column-150-start">{{ caseRow.target|translate }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="upload" i18n="@@homeTableColumnUpload">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="home-column-150-center">
                    <div>
                        {{ 'upload'|translate }}<br>T1, T2, CT
                    </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let caseRow" class="home-column-150-center">
                    <div class="home-cases-table-upload mat-typography">
                        <mat-icon class="home-cases-table-upload-type"
                                  [ngStyle]="getStyleForElement(caseRow, elements.T1_DICOM)">done
                        </mat-icon>
                        <mat-icon class="home-cases-table-upload-type"
                                  [ngStyle]="getStyleForElement(caseRow, elements.T2_DICOM)">done
                        </mat-icon>
                        <mat-icon class="home-cases-table-upload-type"
                                  [ngStyle]="getStyleForElement(caseRow, elements.POSTOP_DICOM)">done
                        </mat-icon>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="pre-status" i18n="@@homeTableColumnPreOpStatus">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="home-column-150-start">
                    {{ 'homeColumnPreOpStatus'|translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let caseRow" class="home-column-150-start">
                    {{ describeFlowStatus(caseRow, flows.BG_PLANNING_PREDICTION)|translate }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="post-status" i18n="@@homeTableColumnPostOpStatus">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="home-column-150-start">
                    {{ 'homeColumnPostOpStatus'|translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let caseRow" class="home-column-150-start">
                    {{ describeFlowStatus(caseRow, flows.BG_POST_OP)|translate }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="leads" i18n="@@homeTableColumnLeads">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="home-column-200-start">
                    {{ 'homeColumnLeads'|translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let caseRow" class="home-column-200-start">
                    <div class="home-cases-table-leads-container mat-typography">
                        <div class="home-cases-table-leads-side">
                            <span><b>L&nbsp;-&nbsp;</b>{{ getLeadForSide(caseRow, LEFT)|translate }}</span>
                        </div>
                        <div class="home-cases-table-leads-side">
                            <span><b>R&nbsp;-&nbsp;</b>{{ getLeadForSide(caseRow, RIGHT)|translate }}</span>
                        </div>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="shared" i18n="@@homeTableColumnShared">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                                 class="home-column-100-start">{{ 'shared'|translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let caseRow" class="home-column-100-start">
                    <mat-icon *ngIf="caseRow.shared" style="color: darkgray;"
                              matTooltip="Visible to group members"
                              matTooltipPosition="after">
                        group
                    </mat-icon>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="created" i18n="@@homeTableColumnCreated">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'created'|translate }}</mat-header-cell>
                <mat-cell *matCellDef="let caseRow">{{ caseRow.created | localDate }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="updated" i18n="@@homeTableColumnUpdated">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'updated'|translate }}</mat-header-cell>
                <mat-cell *matCellDef="let caseRow">{{ caseRow.updated | localDate }}</mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let caseRow; columns: displayedColumns;"
                     class="table-row"
                     data-testid="home-cases-rows"
                     (click)="onActivate(caseRow, $event.metaKey)">
            </mat-row>
        </mat-table>
        <mat-paginator [pageSize]="10" [hidePageSize]="true" (page)="savePage()"></mat-paginator>
    </div>
    <button mat-fab
            class="new-case-button"
            data-testid="home-case-create"
            i18n-matTooltip="@@homeCreateNewCaseTooltip"
            matTooltip="{{'createNewCase'|translate}}"
            matTooltipPosition="before"
            color="accent"
            *ngIf="canCreateNewCase"
            (click)="openCreateCaseDialog()">
        <mat-icon>add</mat-icon>
    </button>
</div>

<mat-card>
    <mat-card-title i18n="@@settingsUsersCreateNewUserTitle"></mat-card-title>
    <mat-card-content class="create-user-content-container">
        <form [formGroup]="mainForm" novalidate>
            <mat-form-field style="width: 80%;">
                <mat-label>{{ 'settingsUsersCreateUserEmail'|translate }}</mat-label>
                <input matInput [formControl]="email" autofocus data-testid="create-user-email" type="email">
            </mat-form-field>
            <mat-form-field style="width: 80%;">
                <mat-label>{{ 'settingsUsersCreateUserInitialPassword'|translate }}</mat-label>
                <input matInput [formControl]="password" autocomplete="new-password" data-testid="create-user-pw"
                       type="password">
            </mat-form-field>
            <mat-form-field style="width: 80%;">
                <mat-label>{{ 'settingsUsersCreateUserFirstName'|translate }}</mat-label>
                <input matInput [formControl]="firstName" data-testid="create-user-first-name" type="text">
            </mat-form-field>
            <mat-form-field style="width: 80%;">
                <mat-label>{{ 'settingsUsersCreateUserLastName'|translate }}</mat-label>
                <input matInput [formControl]="lastName" data-testid="create-user-last-name" type="text">
            </mat-form-field>
            <mat-form-field style="width: 80%;">
                <mat-label>{{ 'settingsUsersCreateUserPhoneNumber'|translate }}</mat-label>
                <input matInput [formControl]="phoneNumber" type="tel">
            </mat-form-field>
            <mat-form-field style="width: 80%; margin-bottom: 40px;">
                <mat-label>{{ 'settingsUsersInviteAccount'|translate }}</mat-label>
                <input matInput [formControl]="account" type="text" data-testid="create-user-account"
                       [matAutocomplete]="auto">
                <mat-autocomplete
					#auto="matAutocomplete"
                    [displayWith]="displayAccount">
                    <mat-option *ngFor="let ca of filteredAccounts | async" [value]="ca">{{ ca.name }}</mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <br>
            <button (click)="saveUser()" [disabled]="submitDisabled()"
                    color="primary"
                    data-testid="create-user-create"
                    i18n="@@settingsUsersCreateUserButtonCreate"
                    mat-raised-button
                    style="margin-right: 40px;">
                {{ 'settingsUsersCreateUserButtonCreate'|translate }}
            </button>
            <button (click)="closeDialog()"
                    [disabled]="inProgress"
                    data-testid="create-user-cancel"
                    i18n="@@settingsUsersCreateUserButtonClose"
                    mat-raised-button>
                {{ 'close'|translate }}
            </button>
        </form>
        <div class="create_user_notification">
            <p *ngIf="success"
               i18n="@@settingsUsersCreateUserMessageSuccess"
               class="create-user-success-message mat-typography">
                {{ 'settingsUsersCreateUserMessageSuccess'|translate }}!
            </p>
            <p *ngIf="error"
               i18n="@@settingsUsersCreateUserMessageError"
               class="create-user-error-message mat-typography">
                {{ 'settingsUsersCreateUserMessageError'|translate }}
            </p>
        </div>
    </mat-card-content>
</mat-card>


<mat-card appearance="outlined" style="height: 100%; width: 100%;">
    <mat-card-title class="sis-card-title">
        <span class="mat-typography" style="font-weight: bold;">Postop Review</span>
    </mat-card-title>
    <mat-card-content class="postop-review-content">
        <form [formGroup]="flowOptions" class="postop-review-select-form">
            <mat-form-field class="postop-review-select-container">
                <mat-label>Exit Code</mat-label>
                <mat-select [formControl]="exitCode" (selectionChange)="onSelectionChange()" panelWidth="auto">
                    <mat-option *ngFor="let code of codes" [value]="code.value">
                        {{code.value}}&nbsp;-&nbsp;{{code.descKey|translate}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </form>
        <button mat-raised-button style="width: 60%;"
                [disabled]="!flowOptions.dirty" (click)="applyChanges()">
            Apply Changes
        </button>
    </mat-card-content>
</mat-card>

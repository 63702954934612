import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { compareUsersByDescription, User, UserService } from '../../services/user.service';
import { compareGroupsByName, Group, GroupService } from '../../services/group.service';
import { Account, AccountService, compareAccountsByName } from '../../services/account.service';
import { AuthenticationEvent, AuthService } from '../../services/auth.service';
import { Subscriptions } from '../subscriptions.class';
import { map, startWith } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import {
    AutoCompleteAccount,
    AutoCompleteGroup,
    AutoCompleteItem,
    AutoCompleteUser,
    CONTEXT_KEYS
} from '../context-item.class';
import { FormControl } from '@angular/forms';
import { ContextInformation } from '../../services/api.service';

@Component({
    selector: 'app-context-selector',
    templateUrl: './context-selector.component.html',
    styleUrl: './context-selector.component.scss'
})
export class ContextSelectorComponent implements OnInit, OnDestroy {
    // The Parent component can tell the context selector how much of the width to use
    @Input() widthPercentage: number;
    // The UI default label inside the drop-down menu
    @Input() defaultLabel: string;
    // the can instruct the selector which user shall be loaded
    @Input() forUser: string | null;
    // The parent may ask the selector to start empty or start with the default value
    @Input() forceDefault: boolean = false;
    // The parent can ask the selector to wait for it to initiate and load what is needs (like URL params in the home
    // screen). If ready is provided, the parent must call ready for the selector to start loading data.
    @Input() startLoading: Observable<ContextInformation | null>;
    // This is how the selector report to whoever is interested that the context has changed.
    @Output() contextChanged: EventEmitter<AutoCompleteItem> = new EventEmitter<AutoCompleteItem>();

    private lifetimeSubscriptions = new Subscriptions();
    private perContextSubscriptions = new Subscriptions();

    public contextList: Array<AutoCompleteItem> = new Array<AutoCompleteItem>();
    public contextControl: FormControl<AutoCompleteItem | null> = new FormControl();
    public filteredOptions: Observable<AutoCompleteItem[]>;

    private contextUser: User | null = null;

    constructor(private authService: AuthService, private userService: UserService,
                private groupService: GroupService, private accountService: AccountService) {
    }

    ngOnInit(): void {
        this.contextControl.disable();
        this.lifetimeSubscriptions.limit(this.authService.beforeLogout).subscribe((e: AuthenticationEvent) => {
            if (!e.authenticated) {
                // since we logged out, we need to clear all subscriptions, so we do not call all of them
                // as a  result of the invalidate event
                this.perContextSubscriptions.cancel();
                this.lifetimeSubscriptions.cancel();
            }
        });
        if (this.startLoading) {
            // if the parent ask us to wait before we start loading all the information, wait.
            this.lifetimeSubscriptions.add(this.startLoading, (startWith: ContextInformation | null) => {
                this.loadUsersGroupsAccounts(startWith);
            });
        }
        else {
            // else - load on init
            this.loadUsersGroupsAccounts(null);
        }
    }

    ngOnDestroy(): void {
        this.perContextSubscriptions.cancel();
        this.lifetimeSubscriptions.cancel();
    }

    get labelKey(): string {
        return this.contextControl?.value?.labelKey || this.defaultLabel;
    }

    get requestedStyle(): any {
        return {'width': `${this.widthPercentage}%`};
    }

    public displayFn(item: AutoCompleteItem): string {
        return item != null ? item.display : '';
    }

    public onContextChange() {
        this.contextChanged.emit(this.contextControl.value);
    }

    private loadUsersGroupsAccounts(startWith: ContextInformation | null): void {
        const requestsList = [
            this.userService.getUser(this.forUser),
            this.userService.getUsers(this.forUser),
            this.groupService.getGroups(this.forUser),
            this.accountService.getAccounts(this.forUser, true)
        ];

        this.lifetimeSubscriptions.limit(combineLatest(requestsList)).subscribe((data: Array<User | User[] | Group[] | Account[]>) => {
            this.contextUser = data[0] as User;
            this.setContextList(data[1] as User[], data[2] as Group[], data[3] as Account[]);
            this.setContextFilter();
            this.chooseContext(startWith ?? this.contextUser.home_context);
            this.onContextChange();
        });
    }

    private setContextList(users: User[], groups: Group[], accounts: Account[]) {
        const userContexts = users.sort(compareUsersByDescription).map(u => new AutoCompleteUser(u, 'homeUserContextHelpText'));
        const groupContexts = groups.sort(compareGroupsByName).map(g => new AutoCompleteGroup(g, 'homeGroupContextHelpText'));
        const accountContexts = accounts.sort(compareAccountsByName).map(a => new AutoCompleteAccount(a, 'homeAccountContextHelpText'));
        this.contextList = userContexts.concat(groupContexts, accountContexts);
        this.contextList.length > 0 ? this.contextControl.enable() : this.contextControl.disable();
    }

    get contexts(): Array<AutoCompleteItem> {
        return this.contextList;
    }

    private setContextFilter() {
        this.filteredOptions = this.contextControl.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string') ? value : (value != null) ? value.search : ''),
            map(value => value ? this._filter(value) : this.contexts.slice())
        );
    }

    private _filter(value: string): AutoCompleteItem[] {
        return this.contexts.filter(
            (option: AutoCompleteItem) => option.search.toLowerCase().includes(value.toLowerCase())
        );
    }

    /**
     * Update the context for the user
     * @param defaultContext - the context to be set. Can be null (no context)
     * @returns - true if the value changes, false if context was not updated
     * @private
     */
    private chooseContext(defaultContext: ContextInformation | null): boolean {
        const currentContext = this.contextControl.value;
        let context: AutoCompleteItem | null = null;
        if (defaultContext !== null && CONTEXT_KEYS.includes(defaultContext.context_key)) {
            context = this.contextList.find((c: AutoCompleteItem) => ((c.id === defaultContext.context_id) && (c.contextKey === defaultContext.context_key)));
            // if we found a context and the context is from the same type as the key -> userId: validUserId
            // we set this context
            if (context && (context !== currentContext)) {
                this.contextControl.setValue(context);
                return true;
            }
        }
        if (context === null && this.forceDefault) {
            // we could not find default that matches the list received from the server => fallback to current user
            context = this.contextList.find(c => c.id === this.forUser);
            this.contextControl.setValue(context);
            return true;
        }
        return false;
    }
}

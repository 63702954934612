<form class="search-bar">
    <mat-icon class="search-icon">search</mat-icon>
    <mat-form-field class="search-bar-autocomplete" appearance="fill" subscriptSizing="dynamic">
        <input type="text" matInput [formControl]="searchControl" [matAutocomplete]="auto">
        <mat-autocomplete
			#auto="matAutocomplete"
            panelWidth="auto"
            [displayWith]="displayFn">
            <mat-option *ngFor="let option of filteredOptions | async"
                        [ngClass]="option.cssClass"
                        [value]="option" class="search-bar-options"
                        (onSelectionChange)="option.onSelect(router)">
                <mat-icon style="margin-right: 8px;">{{option.iconKey}}</mat-icon>
                {{option.name}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</form>

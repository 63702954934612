import { Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, take, takeUntil, tap } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Subscriptions } from './subscriptions.class';
import { PermissionsService } from '../services/permissions.service';
import { AuthService } from '../services/auth.service';
import { suppressWith } from '../rxjs/operators/suppressWith';

export function stableTransitions<T>(observable: Observable<T>, permissionsService: PermissionsService): Observable<T> {
    // Eliminate consecutive repetitions of a single value. Do not propagate values while the permission service
    // has updates in progress.
    return observable.pipe(
        distinctUntilChanged(),
        suppressWith(permissionsService.anyPending)
    );
}

export class NavigationServiceEntry {
    constructor(public subject: Observable<boolean>, public child: string) {
    }
}

@Injectable()
export abstract class NavigationService  implements OnDestroy {

    protected subscriptions = new Subscriptions();
    protected failureRedirectPath = ['/'];

    protected constructor(protected permissionsService: PermissionsService,
                          protected authService: AuthService, protected router: Router) {
    }

    public ngOnDestroy() {
        this.subscriptions.cancel();
    }

    abstract canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean>;

    protected maybeRedirectChild(canActivateObservable: Observable<boolean>) {
        return canActivateObservable.pipe(
            take(1),
            tap(allowed => {
                if (!allowed) {
                    // noinspection JSIgnoredPromiseFromCall
                    this.router.navigate(this.failureRedirectPath);
                }
            })
        );
    }

    protected maybeRedirect(state: RouterStateSnapshot, table: Array<NavigationServiceEntry>): Observable<boolean> {
        // Create an observable to return to the router, which will tell it whether to allow navigation
        // to the bare dashboard page. This should only occur if we cannot redirect to any of the children.
        const result = new ReplaySubject<boolean>(1);
        // We need to loop over the items in the table, but each item in the table requires an asynchronous
        // request and also depends on the result of the prior (asynchronous) operation. So we create a
        // series of subjects, each one subscribing to the previous one. All subscriptions will be active
        // only until a value is emitted on `result`.
        const initialPrior = new ReplaySubject<boolean>(1);
        let prior = initialPrior;
        for (const item of table) {
            const thisItem = item;
            const nextPrior = new ReplaySubject<boolean>(1);
            this.subscriptions.add(prior.pipe(takeUntil(result)), (priorAllowed) => {
                this.subscriptions.add(thisItem.subject.pipe(takeUntil(result)), (allowed) => {
                    // If this item is allowed and no prior items were allowed, then redirect the user to
                    // the page for this item and finish.
                    if (!priorAllowed && allowed) {
                        // noinspection JSIgnoredPromiseFromCall
                        this.router.navigate([state.url, thisItem.child]);
                        result.next(false);
                        result.complete();
                    }
                    // Propagate the cumulative "allowed" state
                    nextPrior.next(priorAllowed || allowed);
                    nextPrior.complete();
                });
                prior = nextPrior;
            });
        }

        // Trigger the cascade by inject a false value
        initialPrior.next(false);

        // If none of the child pages were accessible, then allow navigation to the no-settings page.
        this.subscriptions.add(prior.pipe(takeUntil(result)), priorAllowed => {
            if (!priorAllowed) {
                // noinspection JSIgnoredPromiseFromCall
                this.router.navigate(this.failureRedirectPath);
                result.next(false);
                result.complete();
            }
        });

        return result;
    }

}

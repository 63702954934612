<h2 mat-dialog-title i18n="@@settingsTransferCasesTitle">{{'settingsTransferCasesTitle'|translate}}</h2>
<form>
    <mat-dialog-content>
        <div class="transfer-cases-title-row">
            <mat-form-field style="width: 50%;">
                <mat-label>{{'settingsTransferCasesSelectUserPlaceholder'|translate}}</mat-label>
                <mat-select [(ngModel)]="selectedNewUserId" required
                            [ngModelOptions]="{standalone: true}">
                    <mat-option *ngFor="let user of selectableTargetUsers" [value]="user.id">
                        {{userDescription(user)}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-checkbox *ngIf="canViewArchivedCases"
                          [checked]="includeArchived()"
                          (change)="showArchiveChanged($event)">
                {{'showArchivedCheckbox'|translate}}
            </mat-checkbox>
        </div>
        <div>
            <mat-table class="case-table" [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="selected">
                    <mat-header-cell *matHeaderCellDef>
                        <mat-checkbox
                                [checked]="availableCaseCount > 0 && selectedCaseCount === availableCaseCount"
                                [indeterminate]="selectedCaseCount > 0 && selectedCaseCount < availableCaseCount"
                                (change)="selectAll($event.checked)">
                        </mat-checkbox>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let caseRow">
                        <mat-checkbox [checked]="caseRow.selected" (change)="toggle(caseRow)"></mat-checkbox>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="name" i18n="@@transferCasesTableName">
                    <mat-header-cell *matHeaderCellDef
                                     mat-sort-header>{{'name'|translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let caseRow"
                              [ngClass]="maybeArchivedClass(caseRow)">{{caseRow.name}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="status" i18n="@@transferCasesTableStatus">
                    <mat-header-cell *matHeaderCellDef
                                     mat-sort-header>{{'status'|translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let caseRow"> {{describeCaseStatus(caseRow.status)|translate}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="shared" i18n="@@transferCasesTableShared">
                    <mat-header-cell *matHeaderCellDef
                                     mat-sort-header>{{'shared'|translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let caseRow">
                        <mat-icon *ngIf="caseRow.shared"
                                  matTooltip="{{'settingsTransferCasesVisibleToGroup'|translate}}"
                                  matTooltipPosition="after">
                            group
                        </mat-icon>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="created" i18n="@@transferCasesTableCreated">
                    <mat-header-cell *matHeaderCellDef
                                     mat-sort-header>{{'created'|translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let caseRow"> {{caseRow.created | localDate}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="updated" i18n="@@transferCasesTableUpdated">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'updated'|translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let caseRow"> {{caseRow.updated | localDate}}</mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let caseRow; columns: displayedColumns;" class="table-row">
                </mat-row>
            </mat-table>
            <mat-paginator [pageSize]="4"></mat-paginator>
            <p class="case-count mat-typography" i18n="@@settingsTransferCasesSelectedCases">
                {{selectedCaseCount}} {{'settingsTransferCasesSelectedCases'|translate}}</p>
        </div>
    </mat-dialog-content>
    <div class="actions-and-progress">
        <mat-dialog-actions>
            <button mat-raised-button
                    i18n="@@transferCasesTableButtonClose"
                    [mat-dialog-close]=null>
                {{'close'|translate}}
            </button>
            <button mat-raised-button
                    i18n="@@settingsTransferCasesTableButtonTransfer"
                    (click)="onTransfer()"
                    [disabled]="!enableTransfer()">
                {{'settingsTransferCasesTableButtonTransfer'|translate}}
            </button>
        </mat-dialog-actions>
        <div *ngIf="transferVisible" class="transfer-progress">
            <mat-progress-bar style="width: 100%"
                              mode="determinate"
                              [color]="transferColor"
                              [value]="transferProgress">
            </mat-progress-bar>
            <p class="mat-typography" i18n="@@transferCasesReport">
                {{transfersDone}} {{'settingsTransferCasesReportTransferred'|translate}}
                , {{transfersFailed}} {{'settingsTransferCasesReportFailures'|translate}}
            </p>
        </div>
    </div>
</form>

<h2 mat-dialog-title i18n="@@settingsAccountsNewAccountTitle">{{'settingsAccountsNewAccountTitle'|translate}}</h2>
<form>
    <mat-dialog-content>
        <mat-form-field style="margin: auto; width: 100%;">
            <mat-label>{{'settingsAccountsAccountName'|translate}}</mat-label>
            <input matInput data-testid="create-account-name" name="accountName" [(ngModel)]="newAccount.name"
                   required>
        </mat-form-field>
        <mat-form-field style="margin: auto; width: 100%;">
            <mat-label>{{'state'|translate}}</mat-label>
            <mat-select name="accountState" [(ngModel)]="newAccount.state" required>
                <mat-option *ngFor="let accountState of accountStates; trackBy: trackStateByValue"
                            [value]="accountState">
                    {{accountState}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-raised-button
                i18n="@@settingsAccountsNewAccountButtonCancel"
                data-testid="create-account-cancel"
                [mat-dialog-close]=null>
            {{'cancel'|translate}}
        </button>
        <button mat-raised-button
                i18n="@@settingsAccountsNewAccountButtonSave"
                data-testid="create-account-submit"
                (click)="onSubmit()"
                [disabled]="!enableSubmission()">
            {{'save'|translate}}
        </button>
    </mat-dialog-actions>
</form>

<mat-sidenav-container class="fill-parent">
    <mat-sidenav
            class="sis-sidenav"
            mode="side"
            opened="true"
            disableClose>
        <div class="case-sidenav-container">
            <mat-list>
                <div class="case-side-nav-title">
                    <div class="case-side-nav-title-row">
                        <span class="mat-typography case-side-nav-name ellipsized">{{ caseName }}</span>
                    </div>
                    <div class="case-side-nav-title-row" *ngIf="caseTarget">
                        <span class="mat-typography case-side-nav-target ellipsized" style="margin-right: 20px;">
                            {{ targetTitle }}:
                        </span>
                        <span *ngIf="!canSwitchTargets()"
                              class="mat-typography case-side-nav-target ellipsized" style="margin-right: 20px;">
                            {{ dbsTarget|translate }}
                        </span>
                        <mat-select panelWidth=""
                                    class="case-side-nav-target-select"
                                    *ngIf="canSwitchTargets()"
                                    data-testid="case-sidenav-target-select"
                                    [(ngModel)]="caseTarget"
                                    (selectionChange)="onDbsTargetChange()"
                                    [disabled]="loadingDicom || loadingNifti"
                                    [ngModelOptions]="{standalone: true}">
                            <mat-option *ngFor="let target of selectableTargets"
                                        data-testid="case-sidenav-target-options" [value]="target">
                                <mat-icon *ngIf="hasSuccessPublishedPlanning()" [ngClass]="getTargetIconCls(target)">
                                    {{ getTargetIcon(target) }}
                                </mat-icon>
                                {{ target.titleKey|translate }}
                            </mat-option>
                        </mat-select>
                    </div>
                </div>
            </mat-list>
            <mat-nav-list>
                <mat-list-item
                        i18n="@@caseSideMenuOverview"
                        routerLink="/case/{{caseId}}/overview"
                        data-testid="case-sidenav-overview"
                        [activated]="selectedView === OVERVIEW"
                        [disabled]="selectedView === NOT_FOUND"
                        class="sis-side-nav-item">
                    {{ 'caseSideMenuOverview'|translate }}
                </mat-list-item>
                <mat-list-item
                        i18n="@@caseSideMenuDicom"
                        (click)="onClick(DICOM)"
                        data-testid="case-sidenav-dicom"
                        [activated]="selectedView === DICOM"
                        [disabled]="!hasDicomData || (selectedView === NOT_FOUND)"
                        class="sis-side-nav-item">
                    {{ 'caseSideMenuDicom'|translate }}
                </mat-list-item>
                <mat-list-item
                        (click)="onClick(LEAD_LOCATION)"
                        data-testid="case-sidenav-leads-view"
                        [activated]="selectedView === LEAD_LOCATION"
                        [disabled]="!hasDbsData || (selectedView === NOT_FOUND)"
                        class="sis-side-nav-item">
                    {{ leadViewTitle | translate }}
                </mat-list-item>
                <mat-list-item
                        i18n="@@caseSideMenuTools"
                        *ngIf="enableAdminView()"
                        (click)="onClick(ADMIN)"
                        data-testid="case-sidenav-admin"
                        [activated]="selectedView === ADMIN"
                        [disabled]="!enableAdminView() || (selectedView === NOT_FOUND)"
                        class="sis-side-nav-item">
                    {{ 'admin' | translate }}
                </mat-list-item>
            </mat-nav-list>
            <div class="case-side-nav-space">
            </div>
            <mat-nav-list *ngIf="userDescription || accountName" class="case-side-nav-owner">
                <mat-list-item
                        *ngIf="userDescription"
                        routerLink="/settings/user/{{userId}}">
                    <div class="sis-side-nav-item">
                        <mat-icon style="margin: 0 5px 3px 0;">person</mat-icon>
                        <span class="no-wrap">{{ userDescription }}</span>
                    </div>
                </mat-list-item>
                <mat-list-item
                        *ngIf="accountName"
                        routerLink="/settings/account/{{accountId}}"
                        class="sis-side-nav-item">
                    <div class="sis-side-nav-item">
                        <mat-icon style="margin: 0 5px 3px 0;">business</mat-icon>
                        <span class="no-wrap">{{ accountName }}</span>
                    </div>
                </mat-list-item>
            </mat-nav-list>
        </div>
    </mat-sidenav>
    <mat-sidenav-content #sidenavContent class="sis-sidenav-content" [ngClass]="contentClass()">
        <app-not-found *ngIf="selectedView === NOT_FOUND"></app-not-found>
        <app-case-overview
                *ngIf="caseData"
                [caseData]="caseData"
                [caseUpdated]="caseUpdated"
                [approvedTargets]="approvedTargets"
                (deleteCaseUpdates)="deleteCaseStatus($event)"
                [ngClass]="viewClass(OVERVIEW)"
                [hidden]="!(selectedView === OVERVIEW)">
        </app-case-overview>
        <app-case-dicom
                [caseData]="caseData"
                [caseUpdated]="caseUpdated"
                [approvedTargets]="approvedTargets"
                [onActivate]="onDicomActivate"
                (loadingDicom)="dicomLoadStatus($event)"
                [ngClass]="viewClass(DICOM)"
                [hidden]="!(selectedView === DICOM)"
                *ngIf="hasDicomData">
        </app-case-dicom>
        <app-case-lead-location
                [caseData]="caseData"
                [caseUpdated]="caseUpdated"
                [approvedTargets]="approvedTargets"
                (loadingPlanningToPostop)="niftiLoadStatus($event)"
                [ngClass]="viewClass(LEAD_LOCATION)"
                [hidden]="!(selectedView === LEAD_LOCATION)"
                [containerWidth]="contentAreaWidth"
                [containerHeight]="contentAreaHeight"
                *ngIf="hasDbsData">
        </app-case-lead-location>
        <app-case-admin style="height: 100%;"
                        *ngIf="enableAdminView()"
                        [caseUpdated]="caseUpdated"
                        [allowedUploadPlanningPostopTransform]="allowedUploadPlanningPostopTransform"
                        [allowedLogCaseEvent]="allowedLogCaseEvent"
                        [allowedReRunFlow]="allowedReRunFlow"
                        [allowedChoosePlanningToPostopRegistrationMethod]="allowedChoosePlanningToPostopRegistrationMethod"
                        [allowedAuditQuery]="allowedAuditQuery"
                        [allowedCaseReview]="allowedCaseReview"
                        [ngClass]="viewClass(ADMIN)"
                        [hidden]="!(selectedView === ADMIN)">
        </app-case-admin>
    </mat-sidenav-content>
</mat-sidenav-container>

<form>
    <mat-form-field appearance="outline" subscriptSizing="dynamic" [ngStyle]="requestedStyle">
        <mat-label>{{ labelKey|translate }}</mat-label>
        <input type="text" matInput
               [formControl]="contextControl"
               [matAutocomplete]="auto">
        <mat-autocomplete
			#auto="matAutocomplete"
            [displayWith]="displayFn"
            (optionSelected)="onContextChange()">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                <mat-icon>{{ option.iconKey }}</mat-icon>
                {{ option.display }}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</form>


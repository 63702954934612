<mat-list-item
        class="sis-side-nav-item"
        *ngIf="!hide"
        [activated]="isActive(item)"
        [ngClass]="{'active': isActive(item), 'expanded': expanded}"
        [ngStyle]="{'padding-left': (indentLevel * 12) + 'px'}"
        (click)="onItemSelected(item, $event.metaKey)" mat-list-item>
    <span>{{item.titleKey|translate}}</span>
    <span class="twistie-separator"></span>
    <span *ngIf="item.children && item.children.length">
        <mat-icon [@indicatorRotate]="expanded ? 'expanded': 'collapsed'" class="menu-twistie">
            expand_more
        </mat-icon>
    </span>
</mat-list-item>
<div *ngIf="expanded">
    <app-menu-item
            *ngFor="let child of item.children"
            [indentLevel]="indentLevel + 1"
            [item]="child"
            class="menu-child">
    </app-menu-item>
</div>

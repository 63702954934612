<mat-card appearance="outlined" class="choose-registration-method-box">
    <mat-card-title class="sis-card-title">
        <span class="mat-typography" style="font-weight: bold;">Choose {{kind}} Registration Method</span>
    </mat-card-title>
    <mat-card-content class="choose-registration-method-content">
        <mat-form-field style="width: 90%;">
            <mat-select [(ngModel)]="selectedMethod" (selectionChange)="save()">
                <mat-option *ngFor="let method of methodChoices()" [value]="method.code">
                    {{method.description}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </mat-card-content>
</mat-card>

import { Injectable } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import {
    ActivityLogLevel,
    ApiService,
    Archived,
    CaseResponse,
    DataElementResponse,
    DeleteCaseResponse,
    ElectrodesDetectionReport,
    ElectrodeSelectionResponse,
    FlowResponse,
    HeadPose,
    NewCase,
    PRED_ERROR,
    SegValidationItem
} from '../services/api.service';
import { FlowType } from './flow-type.class';
import { AllFlowTypes, DbsTarget, DbsTargets, Elements, Flows, SWITCHABLE_TARGETS, Targets } from './case.constants';
import { DataElement } from './data-element.class';
import { Visibilities, Visibility } from '../security/visibility.class';
import { RegistrationMethod } from './registration-method.class';
import { ElectrodeModelService } from './electrode-model/electrode-model.service';
import { urlExpand } from '../tools/url-compression';
import { map } from 'rxjs/operators';

export {
    CaseResponse as Case,
    FlowResponse as Flow,
    DeleteCaseResponse,
    ActivityLogLevel,
    ElectrodesDetectionReport,
};

export enum CaseStatus {
    NEW = 0,
    SUBMITTED = 1,
    IN_PROGRESS = 2,
    RESULTS_AVAILABLE = 3,
    DONE_WITH_ERRORS = 4,
    DONE_ERROR = 5,
    DONE = 6
}

export enum FlowStatus {
    NA = 0, // flow is not available yet - before submission
    SUBMITTED = 1,
    IN_PROGRESS = 2,
    DONE_ERROR = 3,
    DONE = 4
}

export enum SubmitPostopRequirements {
    MISSING_POSTOP_IMAGE,
    MISSING_ELECTRODE_SELECTION,
    INVALID_ELECTRODE_SELECTION,
    NO_NEW_DATA,
    NO_ALPHA
}

@Injectable()
export class CaseService {
    constructor(private api: ApiService) {
    }

    public getCaseById(caseId: string): Observable<CaseResponse> {
        return this.api.getCaseById(caseId);
    }

    public createNewCase(newCase: NewCase): Observable<CaseResponse> {
        return this.api.createNewCase(newCase);
    }

    public updateCase(caseId: string, payload: any): Observable<CaseResponse> {
        return this.api.updateCase(caseId, payload);
    }

    public getCasesForUser(userId: string, options?: {
        groupId?: string,
        archived?: string
    }): Observable<CaseResponse[]> {
        return this.api.getCasesForUser(userId, options);
    }

    public countCasesForUser(userId: string, archived: Archived): Observable<number> {
        return this.api.countCasesForUser(userId, archived);
    }

    public getCasesForGroup(groupId: string, archived: Archived): Observable<CaseResponse[]> {
        return this.api.getCasesForGroup(groupId, archived);
    }

    public getCasesForAccount(accountId: string, archived: Archived): Observable<CaseResponse[]> {
        return this.api.getCasesForAccount(accountId, archived);
    }

    public submitCase(caseData: CaseResponse): Observable<CaseResponse> {
        return this.api.submitCase(caseData.id);
    }

    public forceSubmitCase(caseId: string, flow: number): Observable<CaseResponse> {
        return this.api.forceSubmitCase(caseId, flow);
    }

    public publishCaseFlow(flowId: string): Observable<any> {
        return this.api.publishCaseFlow(flowId);
    }

    public unpublishCase(caseId: string): Observable<CaseResponse> {
        return this.api.unpublishCase(caseId);
    }

    public updateFlow(flowId: string, payload: any): Observable<CaseResponse> {
        return this.api.update_flow(flowId, payload);
    }

    public getCaseTextResource(caseId: string, uri: string, priority: number = 0): Observable<any> {
        return this.api.getCaseTextResource(caseId, uri, priority);
    }

    public getCaseJsonResource(caseId: string, uri: string, priority: number = 0): Observable<any> {
        return this.api.getCaseJsonResource(caseId, uri, priority);
    }

    public getCaseResource(caseId: string, uri: string, priority: number = 0): Observable<Blob> {
        return this.api.getCaseResource(caseId, uri, priority);
    }

    public getCaseResourceAsArrayBuffer(caseId: string, uri: string, priority: number = 0): Observable<ArrayBuffer> {
        return this.api.getCaseResourceAsArrayBuffer(caseId, uri, priority);
    }

    public getElementZipFile(caseId: string, elementId: string): Observable<Blob> {
        return this.api.getElementZipFile(caseId, elementId);
    }

    public getReviewMrb(caseId: string): Observable<Blob> {
        return this.api.getCaseReviewMrb(caseId);
    }

    public archiveCase(caseId: string): Observable<DeleteCaseResponse> {
        return this.api.archiveCase(caseId);
    }

    public restoreCase(caseId: string): Observable<CaseResponse> {
        return this.api.restoreCase(caseId);
    }

    public deleteCase(caseId: string): Observable<DeleteCaseResponse> {
        return this.api.deleteCase(caseId);
    }

    public shareCase(caseId: string, shareOptions: { groupId?: string | null }): Observable<CaseResponse> {
        return this.api.shareCase(caseId, shareOptions);
    }

    public transferCase(caseId: string, newUserId: string): Observable<CaseResponse> {
        return this.api.transferCase(caseId, newUserId);
    }

    public logCaseEvent(caseId: string, level: ActivityLogLevel, message: string) {
        return this.api.logCaseEvent(caseId, level.valueOf(), message);
    }

    public calculateCaseStatus(caseData: CaseResponse): CaseStatus {
        // find recent flows per code
        const recentFlows = this.getCaseLatestFlows(caseData.flows);
        let countResultsAvailable = 0;
        let countInProgress = 0;
        let countErrors = 0;

        recentFlows.forEach((flow: FlowResponse) => {
            if (flow.visibility === Visibilities.USER.code) {
                if (flow.start_time) {
                    if (flow.end_time) {
                        switch (flow.exit_code) {
                            case 0:
                                countResultsAvailable++;
                                break;
                            default:
                                countErrors++;
                                break;
                        }
                    }
                    else {
                        countInProgress++;
                    }
                }
            }
        });
        return this.statusFromCounters(recentFlows.size, countResultsAvailable, countErrors, countInProgress);
    }

    public calculateFlowStatus(caseData: any, flow: FlowType): FlowStatus {
        // find the recent relevant flow - we ignore targeting flow since there are no results available to user even
        // if the flow have been completed.
        const recentFlow = (flow === Flows.BG_POST_OP) ?
            this.latestFlow(caseData, [Flows.BG_POST_OP]) :
            this.latestFlow(caseData, [
                Flows.BG_TARGETING_PREDICTION, Flows.BG_PLANNING_PREDICTION, Flows.BG_PREDICTION
            ]);
        // if we do not yet have the requested flow, the flow was not submitted
        if (recentFlow === null) {
            return FlowStatus.NA;
        }
        if (recentFlow.code === Flows.BG_TARGETING_PREDICTION.flowCode) {
            return this.calculateTargetingStatus(recentFlow);
        }
        // if submitted we check if started
        if (recentFlow.submitted) {
            // if we started, we check if we have an end_time
            if (recentFlow.start_time) {
                // if we have end_time we decide based on the results and visibility
                if (recentFlow.end_time) {
                    switch (recentFlow.exit_code) {
                        // if result is success - we return the DONE or keep IN_PROGRESS if was not published
                        case 0:
                            return recentFlow.visibility === Visibilities.USER.code ? FlowStatus.DONE : FlowStatus.IN_PROGRESS;
                        // if result is error - we return the DONE_ERROR or keep IN_PROGRESS if was not published
                        default:
                            return recentFlow.visibility === Visibilities.USER.code ? FlowStatus.DONE_ERROR : FlowStatus.IN_PROGRESS;
                    }
                }
                // if we do not have an end_time we are IN_PROGRESS
                else {
                    return FlowStatus.IN_PROGRESS;
                }
            }
            else {
                return FlowStatus.SUBMITTED;
            }
        }
        // not submitted -> return NEW
        else {
            return FlowStatus.NA;
        }
    }

    /**
     * Targeting flow status is different logic since we do not publish targeting results.
     * @param flow - the targeting FlowResponse Object
     * @private
     */
    private calculateTargetingStatus(flow: FlowResponse): FlowStatus {
        if (flow.submitted) {
            // if we started, we check if we have an end_time
            if (flow.start_time) {
                // if targeting completed, return result based on visibility
                if (flow.end_time) {
                    switch (flow.exit_code) {
                        case 0:
                            // if we started the flow we are in progress until planning is done
                            return FlowStatus.IN_PROGRESS;
                        default:
                            // if we failed and the flow result is visible to user, return DONE_ERROR
                            return (flow.visibility === Visibilities.USER.code) ? FlowStatus.DONE_ERROR : FlowStatus.IN_PROGRESS;
                    }
                }
                else {
                    return FlowStatus.IN_PROGRESS;
                }
            }
            else {
                return FlowStatus.SUBMITTED;
            }
        }
        // not submitted -> return NEW
        else {
            return FlowStatus.NA;
        }
    }

    describeCaseStatus(status: CaseStatus): string {
        switch (status) {
            case CaseStatus.NEW:
                return 'caseStatusNew';
            case CaseStatus.SUBMITTED:
                return 'caseStatusSubmitted';
            case CaseStatus.IN_PROGRESS:
                return 'caseStatusInProgress';
            case CaseStatus.RESULTS_AVAILABLE:
                return 'caseStatusResultsAvailable';
            case CaseStatus.DONE_WITH_ERRORS:
                return 'caseStatusDoneWithErrors';
            case CaseStatus.DONE_ERROR:
                return 'caseStatusDoneError';
            case CaseStatus.DONE:
                return 'caseStatusDone';
            default:
                return '';
        }
    }

    describeFlowStatus(status: FlowStatus): string {
        switch (status) {
            case FlowStatus.NA:
                return 'flowStatusNA';
            case FlowStatus.SUBMITTED:
                return 'flowStatusSubmitted';
            case FlowStatus.IN_PROGRESS:
                return 'flowStatusInProgress';
            case FlowStatus.DONE_ERROR:
                return 'flowStatusDoneError';
            case FlowStatus.DONE:
                return 'flowStatusDone';
            default:
                return '';
        }
    }

    /*
     element_name = 'electrode_visualization_stl_left' or 'electrode_visualization_stl_right'
     */
    public getStlUrls(caseObject: { elements: DataElementResponse[] }, elementName: string): Observable<Array<string>> {
        if (caseObject) {
            const elements = caseObject.elements;
            for (const el of elements) {
                if (el.name === elementName) {
                    const elementURLs = (typeof el.url === 'number') ? this.api.getElementURLs(el.case_id, el.id) : of(el.url);
                    return elementURLs.pipe(
                        map(urls => this.getStlUrlsSubset(urlExpand(urls)))
                    );
                }
            }
        }
        return of([]);
    }

    public getElementUrls(caseObject: { elements?: DataElementResponse[] }, element: DataElement): Array<string> {
        // Note that this.apiService.getElementUrls() is *not* used here. Only the elements already embedded
        // in the case object are used.
        if (caseObject) {
            const latestElement = this.latestElement(caseObject, element);
            return DataElement.urls(latestElement);
        }
        return [];
    }

    public getElectrodeDetectionReport(caseObject: any): Observable<ElectrodesDetectionReport> {
        if (caseObject) {
            const latestElement = this.latestElement(caseObject, Elements.ELECTRODE_DETECTION_REPORT);
            if (latestElement) {
                if (latestElement.value) {
                    return of(latestElement.value as ElectrodesDetectionReport);
                }
                else if (DataElement.urlCount(latestElement)) {
                    return this.getCaseJsonResource(caseObject.id, DataElement.urls(latestElement)[0]);
                }
            }
        }
        return EMPTY;
    }

    public getHeadModel(caseId: string): Observable<Blob> {
        if (caseId) {
            return this.api.getHeadModel(caseId);
        }
        return EMPTY;
    }

    public getPostopHeadPose(caseObject: any): Observable<HeadPose> {
        if (caseObject) {
            const latestElement = this.latestElement(caseObject, Elements.POSTOP_HEAD_POSE);
            if (latestElement) {
                if (latestElement.value) {
                    return of(latestElement.value as HeadPose);
                }
                else if (DataElement.urlCount(latestElement) > 0) {
                    return this.getCaseJsonResource(caseObject.id, DataElement.urls(latestElement)[0]);
                }
            }
        }
        return of(null);
    }

    public getDicomPatientIds(data: CaseResponse): Array<string> {
        const dcmPatientIds: Array<string> = [];
        [Elements.T1_DICOM, Elements.T2_DICOM, Elements.POSTOP_DICOM].forEach(dcmElement => {
            const latest = this.latestElement(data, dcmElement);
            // Do not count null and empty patient Ids as they do not provide any value
            if (latest !== null && latest.patient_id !== null && latest.patient_id.length > 0) {
                dcmPatientIds.push(latest.patient_id);
            }
        });
        return dcmPatientIds;
    }

    public latestElement(caseData: {
                             elements?: DataElementResponse[]
                         },
                         targetDataElement: DataElement): DataElementResponse | null {
        let latest = null;
        if (!caseData || !caseData.elements) {
            return null;
        }
        for (const element of caseData.elements) {
            if (element.name === targetDataElement.name && (!latest || element.updated > latest.updated)) {
                latest = element;
            }
        }
        return latest;
    }

    public hasElement(caseData: { elements?: DataElementResponse[] }, targetDataElement: DataElement): boolean {
        if (!caseData || !caseData.elements) {
            return false;
        }
        for (const element of caseData.elements) {
            if (element.name === targetDataElement.name) {
                return true;
            }
        }
        return false;
    }

    public latestTargetingFlow(caseData: CaseResponse | null): FlowResponse | null {
        return this.latestFlow(caseData, [Flows.BG_PREDICTION, Flows.BG_TARGETING_PREDICTION]);
    }

    public latestPlanningFlow(caseData: CaseResponse | null): FlowResponse | null {
        return this.latestFlow(caseData, [Flows.BG_PREDICTION, Flows.BG_PLANNING_PREDICTION]);
    }

    public latestFlow(caseData: CaseResponse, targetFlows: Array<FlowType>): FlowResponse | null {
        let latest = null;
        if (!caseData || !caseData.flows) {
            return null;
        }
        for (const flow of caseData.flows) {
            if (targetFlows.some(f => f.flowCode === flow.code) && (!latest || flow.submitted > latest.submitted)) {
                latest = flow;
            }
        }
        return latest;
    }

    private completedPrediction(caseData: CaseResponse): boolean {
        return this.completedTargeting(caseData) && this.completedPlanning(caseData);
    }

    /**
     * The UI will consider a completed targeting flow if:
     * A targeting flow exists and completed (end_time)
     * @param caseData
     * @private
     */
    private completedTargeting(caseData: CaseResponse | null): boolean {
        const latestTargeting = this.latestTargetingFlow(caseData);
        return !!latestTargeting && latestTargeting.end_time !== null;
    }

    /**
     * The UI will consider a valid targeting result to be available if:
     * A prediction flow have been completed successfully (exit_code, end_time)
     * The prediction validation approved the relevant target
     * @param caseData
     * @returns true if there is a valid and approved targeting flow else - false.
     * @private
     */
    public hasValidTargeting(caseData: CaseResponse | null): boolean {
        if (caseData === null) {
            return false;
        }
        const latestTargeting = this.latestTargetingFlow(caseData);
        const targets = caseData.target === Targets.ALL ? [Targets.STN, Targets.GP, Targets.VIM] : [caseData.target];
        return latestTargeting !== null && latestTargeting.end_time !== null && latestTargeting.exit_code === 0 && targets.some(t => latestTargeting.approved_targets.includes(t));
    }

    /**
     * The UI will consider a completed planning flow if:
     * A planning flow exists and was completed (end_time)
     * @param caseData
     * @private
     */
    private completedPlanning(caseData: CaseResponse | null): boolean {
        const latestPlanning = this.latestPlanningFlow(caseData);
        return !!latestPlanning && latestPlanning.end_time !== null;
    }

    /**
     * The UI will consider a valid planning result to be available if:
     * There is a valid targeting result and a planning flow have been
     * completed successfully (exit_code, end_time)
     * @param caseData
     * @private
     */
    public hasValidPlanning(caseData: CaseResponse | null): boolean {
        if (!this.hasValidTargeting(caseData)) {
            return false;
        }
        const latestPlanning = this.latestPlanningFlow(caseData);
        return !!latestPlanning && latestPlanning.exit_code === 0 && latestPlanning.end_time !== null;
    }

    /**
     * Check if a case has a successfully published planning flow.
     * A valid planing flow include a valid targeting flow, with at least 1 approved target + a successfully
     * completed and published planning flow.
     * Note: this method is different from hasValidPlaning since it does not expect the targeting flow to have
     * the case target approved, just to have an approved target
     * @param caseData
     * @return true if a there is a successfully published planning flow. False if not.
     */
    public hasSuccessPublishedPlanning(caseData: CaseResponse): boolean {
        const latestTargeting = this.latestTargetingFlow(caseData);
        const validTargeting = !!latestTargeting && latestTargeting.end_time !== null && latestTargeting.exit_code === 0 && latestTargeting.approved_targets.length > 0;

        const latestPlanning = this.latestPlanningFlow(caseData);
        const validPlanning = !!latestPlanning && latestPlanning.end_time !== null && latestPlanning.exit_code === 0 && latestPlanning.visibility === Visibilities.USER.code;

        return validTargeting && validPlanning;
    }

    public hasPostOp(caseData: CaseResponse): boolean {
        const latestFlow = this.latestFlow(caseData, [Flows.BG_POST_OP]);
        return !!latestFlow && latestFlow.exit_code === 0 && latestFlow.end_time !== null;
    }

    public hasVisibleFlow(caseData: CaseResponse, flows: Array<FlowType>, visibleToUser: boolean = true): boolean {
        const lastFlow = this.latestFlow(caseData, flows);
        if (!!lastFlow && lastFlow.exit_code !== null) {
            const visibility = new Visibility(lastFlow.visibility);
            return visibleToUser ? visibility.user() : visibility.admin();
        }
        else {
            return false;
        }
    }

    public hasFlowInProgress(caseData: CaseResponse): boolean {
        return AllFlowTypes.some(f => this.inProgress(caseData, f));
    }

    /**
     * Check if a flow is currently running for this case. Return.
     * @param caseData
     * @param targetFlow
     * @return true if the flow is running.
     * @private
     */
    private inProgress(caseData: CaseResponse, targetFlow: FlowType): boolean {
        const lastFlow = this.latestFlow(caseData, [targetFlow]);
        return !!lastFlow && lastFlow.submitted && !lastFlow.end_time;
    }

    public hasElectrodeVisualization(caseData: { elements?: DataElementResponse[] }) {
        return this.hasElement(caseData, Elements.ELECTRODE_VISUALIZATION_STL_LEFT) ||
            this.hasElement(caseData, Elements.ELECTRODE_VISUALIZATION_STL_RIGHT);
    }

    /**
     * @param caseData - the latest case data and status
     * @return List of approved Targets (always a subset or the full SWITCHABLE_TARGETS list).
     * Can be an empty list  if no targets were approved or if the flow did not complete/run yet.
     */
    public getApprovedTargets(caseData: CaseResponse): Array<DbsTarget> {
        const latestTargetingFlow = this.latestTargetingFlow(caseData);
        // if the flow did not run yet or is currently running, we do not have approved targets -> return empty list
        if (latestTargetingFlow === null || (latestTargetingFlow.submitted && !latestTargetingFlow.end_time)) {
            return [];
        }
        // if the flow completed, return the approved targets
        return latestTargetingFlow.approved_targets.map(t => DbsTargets.get(t));
    }

    public allowTargetSwitch(caseData: CaseResponse): boolean {
        // get the latest targeting flow
        const latestTargetingFlow = this.latestTargetingFlow(caseData);
        // if no flow was submitted and targeting flow does not exist, it is ok to change structure of interest
        if (latestTargetingFlow === null) {
            return true;
        }
        // if we have a flow, but it is currently running, do not allow to switch structure of interest
        if (latestTargetingFlow.submitted && !latestTargetingFlow.end_time) {
            return false;
        }
        // if we have approved targets and more than 1 target was approved, we allow switch between approved targets
        if (latestTargetingFlow.approved_targets.length > 0) {
            const approved: DbsTarget[] = SWITCHABLE_TARGETS.filter((item: DbsTarget) => latestTargetingFlow.approved_targets.includes(item.key));
            if (approved.length > 1) {
                return true;
            }
        }
        // if we do not have approved segmentation for all targets we use the SV report
        // to check targets that were approved.
        // If we do not have a report, we do not allow the switch target feature
        if (!this.hasElement(caseData, Elements.SEGMENTATION_VALIDATION)) {
            return false;
        }
        // if we have the report, this report was created from version 5.4.0 and above
        // get the report (from caseData) and check if there are errors for any of the targets (STN/GP).
        // if there were no errors -> it is safe to switch targets
        const segValidationElement = this.latestElement(caseData, Elements.SEGMENTATION_VALIDATION);
        const validationItems = segValidationElement.value as Array<SegValidationItem>;
        // there should be no errors for any of the structures to approve switching
        // in the future we may want to work with each structure to allow switching between different targets
        return validationItems.filter(x => x.level === PRED_ERROR && !x.verdict).length === 0;
    }

    /***
     * The user should be able to upload new targeting image if there is no flow that is currently running
     * and a targeting flow was not already completed.
     * @param caseData
     */
    public canUploadTargeting(caseData: CaseResponse): boolean {
        return !(this.hasFlowInProgress(caseData) || this.hasValidTargeting(caseData));
    }

    /**
     * An authorized administrator should be able to publish prediction if:
     * The latest prediction/planning flow completed and was not yet published.
     * @param caseData
     */
    public canPublishPrediction(caseData: CaseResponse): boolean {
        return this.completedPrediction(caseData) && !this.hasVisibleFlow(caseData, [Flows.BG_PREDICTION, Flows.BG_PLANNING_PREDICTION], true);
    }

    public canPublishPostop(caseData: CaseResponse): boolean {
        return this.hasVisibleFlow(caseData, [Flows.BG_POST_OP], false);
    }

    /**
     * The user should be able to upload new planning image only if there is no flow currently running.
     * @param caseData
     */
    public canUploadPlanning(caseData: CaseResponse) {
        return !this.hasFlowInProgress(caseData);
    }

    /**
     * The user should be able to upload new post-op CT if there is no flow currently running.
     * @param caseData
     */
    public canUploadPostOp(caseData: CaseResponse) {
        return !this.hasFlowInProgress(caseData);
    }

    public canSubmitPrediction(caseData: CaseResponse): boolean {
        return this.canSubmitTargeting(caseData) || this.canSubmitPlanning(caseData);
    }

    /**
     * We should be able to submit targeting if we have the T2 and we did not conclude the brain segmentation
     * @param caseData
     * @private
     */
    private canSubmitTargeting(caseData: CaseResponse): boolean {
        if (this.hasFlowInProgress(caseData)) {
            return false;
        }
        return this.hasElement(caseData, Elements.T2_DICOM) && !this.hasValidTargeting(caseData);
    }

    /**
     * When can we submit alpha generation, either combined targeting+planning or planning-only?
     * We must have valid targeting and planning images.
     * The planning image should be newer than the latest planning flow.
     * @param caseData - the case data dictionary
     */
    private canSubmitPlanning(caseData: CaseResponse): boolean {
        if (!this.hasElement(caseData, Elements.T2_DICOM) || this.hasFlowInProgress(caseData)) {
            return false;
        }

        const latestPlanningInput = this.latestElement(caseData, Elements.T1_DICOM);
        // if we do not have T1 we can't submit planning
        if (latestPlanningInput === null) {
            return false;
        }
        // if we have T1 it needs to be newer than the last planning flow
        const latestPlanningFlow = this.latestPlanningFlow(caseData);
        // if the planning flow did not run yet -> we should allow submission
        if (latestPlanningFlow === null) {
            return true;
        }
        // if we have a flow, the T1 should be newer to allow submission
        return latestPlanningFlow.submitted && (latestPlanningInput.updated > latestPlanningFlow.submitted);
    }

    public canSubmitPostop(caseData: CaseResponse, autoDetectEnabled: boolean): boolean {
        return this.itemsMissingForPostopSubmission(caseData, autoDetectEnabled).length === 0;
    }

    public itemsMissingForPostopSubmission(caseData: CaseResponse, autoDetectEnabled: boolean): Array<SubmitPostopRequirements> {
        const missingElements = new Array<SubmitPostopRequirements>();
        if (!this.hasElement(caseData, Elements.POSTOP_DICOM)) {
            missingElements.push(SubmitPostopRequirements.MISSING_POSTOP_IMAGE);
        }
        const electrodeSelection = this.latestElement(caseData, Elements.USER_ELECTRODE_MODELS_SELECTION);
        if (electrodeSelection === null) {
            if (!autoDetectEnabled) {
                missingElements.push(SubmitPostopRequirements.MISSING_ELECTRODE_SELECTION);
            }
        }
        else {
            // we have electrode selection, validate the electrode selection
            const eSelection = electrodeSelection.value as ElectrodeSelectionResponse;
            const left = eSelection.electrodes.left;
            const right = eSelection.electrodes.right;
            if (!this.electrodesSelectionValidForSubmission(autoDetectEnabled, left, right)) {
                missingElements.push(SubmitPostopRequirements.INVALID_ELECTRODE_SELECTION);
            }
        }
        // We need a planning result (which implies having a targeting result) before we can submit post-op. Either we have that
        // result already, or it is submittable now.
        if (!this.hasValidPlanning(caseData) && !this.canSubmitPlanning(caseData)) {
            missingElements.push(SubmitPostopRequirements.NO_ALPHA);
        }
        if (missingElements.length > 0) {
            return missingElements;
        }

        if (this.hasPostOp(caseData) && !this.hasNewPostopImage(caseData) && !this.hasNewElectrodeSelection(caseData) && !this.hasNewPlanningPostopTransformation(caseData)) {
            return [SubmitPostopRequirements.NO_NEW_DATA];
        }
        return [];
    }

    /**
     * If the user do not have autodetect enabled, he can't select autodetect or no selection
     * so even if in the past he submitted this case with another postop image a new submission will require a new
     * selection if the feature is now off
     *
     * @param autoDetect - does the user have the autodetect feature at the time of the call
     * @param left - left selected model payload - null, [] or ['model']
     * @param right - right selected model payload - null, [] or ['model']
     * @return true if the selection is valid and match the enabled feature
     */
    private electrodesSelectionValidForSubmission(autoDetect: boolean, left: null | Array<string>, right: null | Array<string>): boolean {
        if (!autoDetect) {
            if (left === ElectrodeModelService.AUTO_DETECT.payload || right === ElectrodeModelService.AUTO_DETECT.payload) {
                return false;
            }
        }
        return left !== ElectrodeModelService.NO_SELECTION.payload || right !== ElectrodeModelService.NO_SELECTION.payload;
    }

    public hasNewPlanningPostopTransformation(caseData: CaseResponse): boolean {
        const latestTransform = this.latestElement(caseData, Elements.PLANNING_TO_POSTOP_TRX);
        const latestFlow = this.latestFlow(caseData, [Flows.BG_POST_OP]);
        if (!latestTransform || !latestFlow || !latestFlow.end_time) {
            return false;
        }
        return (latestTransform.updated > latestFlow.end_time);
    }

    /**
     * Check if there is a new postop image for the postop flow
     * @param caseData - dictionary with case information
     * @return true if the element is newer than the flow or the flow was not created yet
     */
    public hasNewPostopImage(caseData: CaseResponse): boolean {
        return this.hasNewFlowElement(caseData, Flows.BG_POST_OP, Elements.POSTOP_DICOM);
    }

    /**
     * this method only checks data elements availability and dates.
     * It does not check the electrode selection content (None, AutoDetect, A Model)
     * @param caseData - dictionary with case information
     * @return True if we have an electrode selection newer than the postop flow or if the postop flow did not run
     */
    public hasNewElectrodeSelection(caseData: CaseResponse): boolean {
        return this.hasNewFlowElement(caseData, Flows.BG_POST_OP, Elements.USER_ELECTRODE_MODELS_SELECTION);
    }

    private hasNewFlowElement(caseData: CaseResponse, flow: FlowType, element: DataElement): boolean {
        const latestElement = this.latestElement(caseData, element);
        // if we do not have an electrode selected - return false
        if (!latestElement) {
            return false;
        }
        const latestFlow = this.latestFlow(caseData, [flow]);
        // if we have an electrode selected but no postop flow - we have a new selection - return true
        if (!latestFlow) {
            return true;
        }
        // if element and flow exist, the element is new only if the element was added after the flow was done
        return (latestFlow.end_time && latestElement.updated > latestFlow.end_time);
    }

    public listAvailablePlanningToPostopRegistrationMethods(): Observable<RegistrationMethod[]> {
        return this.api.listAvailablePlanningToPostopRegistrationMethods();
    }

    private getCaseLatestFlows(flowsList: FlowResponse[]): Map<number, FlowResponse> {
        const recentFlows = new Map<number, FlowResponse>();
        flowsList.forEach(flow => {
            const flowCode = flow.code;
            if (!recentFlows.has(flowCode) || flow.created > recentFlows.get(flowCode).created) {
                recentFlows.set(flowCode, flow);
            }
        });
        return recentFlows;
    }

    private statusFromCounters(numberOfFlows: number, countResultsAvailable: number, countErrors: number, countInProgress: number): CaseStatus {
        // All flows completed successfully
        if (countResultsAvailable > 0 && (countResultsAvailable === numberOfFlows)) {
            return CaseStatus.DONE;
        }
        // We have errors in all or some of the flows
        if (countErrors > 0) {
            if (countResultsAvailable > 0) {
                return CaseStatus.DONE_WITH_ERRORS;
            }
            else {
                return CaseStatus.DONE_ERROR;
            }
        }
        // Part of the flows completed successfully
        if (countResultsAvailable > 0) {
            return CaseStatus.RESULTS_AVAILABLE;
        }
        // we have cases in progress
        if (countInProgress > 0) {
            return CaseStatus.IN_PROGRESS;
        }
        // we have flows but they did not start
        if (numberOfFlows > 0) {
            return CaseStatus.SUBMITTED;
        }
        // no flows were submitted
        return CaseStatus.NEW;
    }

    /*
     Given a list of URLS referencing STL files, return a list containing all pieces of the
     electrode, and at most one STN, preferring the smooth version.
     param urls:    a list of URL's to STL files
     :return:        a list of URL's to STL files
     */
    private getStlUrlsSubset(urls: string[]): Array<string> {
        const result: Array<string> = [];
        let bestStnUrl: string = null;
        let bestRnUrl: string = null;
        urls.forEach((url) => {
            const b = url.split('/').pop();
            if (b.match(/^STN_.*_smooth.stl$/)) {
                bestStnUrl = url;
            }
            else if (b.match(/^STN_.*.stl$/)) {
                bestStnUrl = bestStnUrl || url;
            }
            else if (b.match(/^RN_.*_smooth.stl$/)) {
                bestRnUrl = url;
            }
            else if (b.match(/_RN_.*.stl$/)) {
                bestRnUrl = bestRnUrl || url;
            }
            else {
                result.push(url);
            }
        });
        if (bestStnUrl) {
            result.push(bestStnUrl);
        }
        if (bestRnUrl) {
            result.push(bestRnUrl);
        }
        return result;
    }

    public getFlowLogs(caseData: CaseResponse, flows: Array<FlowResponse>): Map<string, DataElementResponse> {
        const elements: Array<DataElementResponse> = caseData.elements;
        const response: Map<string, DataElementResponse> = new Map<string, DataElementResponse>();
        flows.forEach(f => {
            const logElement = elements.find(e => e.flow_id === f.id && e.name === Elements.FLOW_LOG.name);
            if (logElement) {
                response.set(f.id, logElement);
            }
        });
        return response;
    }

    public isCasePublished(caseData: CaseResponse): boolean {
        const published = caseData.flows.filter(flow => flow.end_time && flow.visibility === Visibilities.USER.code);
        return published.length > 0;
    }
}

<div class="dnd-container" [ngStyle]="mainStyle" (dragover)="dragOver()" (dragleave)="dragLeave()">
    <input type="file" id="fileDropRef" data-testid="scan-dicom-dad-input"
           (change)="onFolderSelected($event)" [disabled]="blockUploads()"
           multiple webkitdirectory/>
    <img ngSrc="../../../../assets/img/ic-upload-file.svg" width="32" height="32" alt="">
    <span class="mat-typography" style="display: flex; align-items: center; margin-top: 10px;">
        {{'caseOverviewDicomScanMain'|translate}}
    </span>
    <span class="mat-typography" style="display: flex; align-items: center;margin-bottom: 10px;">
        {{'caseOverviewDicomScanOr'|translate}}
    </span>
    <label class="mat-typography" for="fileDropRef" [ngClass]="labelClass()">
        {{'caseOverviewDicomScanSelectFolder'|translate}}
    </label>
    <div class="pb-dicom-process">
        <mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>
    </div>
</div>

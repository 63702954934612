<div class="fill-parent">
    <div style="height: 50px; width: 100%;"></div>
    <div class="accounts-main">
        <div class="mat-elevation-z8">
            <mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="name" i18n="@@settingsAccountsTableAccountName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'settingsAccountsAccountName'|translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let accountRow"> {{accountRow.name}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="created" i18n="@@settingsAccountsTableCreated">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'created'|translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let accountRow"> {{accountRow.created | localDate}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="updated" i18n="@@settingsAccountsTableUpdated">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'updated'|translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let accountRow"> {{accountRow.updated | localDate}}</mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row (click)="onActivate(accountRow, $event.metaKey)"
                         *matRowDef="let accountRow; columns: displayedColumns;"
                         class="table-row">
                </mat-row>
            </mat-table>
            <mat-paginator [pageSize]="10" [hidePageSize]="true"></mat-paginator>
        </div>
        <button (click)="openCreateAccountDialog()"
                *ngIf="createAccountsAllowed"
                class="create-account-btn"
                data-testid="settings-accounts-create"
                color="accent"
                mat-fab
                i18n-matTooltip="@@settingsAccountsCreateNewAccountTooltip"
                matTooltip="{{'settingsAccountsCreateNewAccountTooltip'|translate}}"
                matTooltipPosition="before">
            <mat-icon>add</mat-icon>
        </button>
    </div>
</div>

<div class="electrode-model-main">
    <p class="mat-typography electrode-model-header" i18n="@@caseElectrodeModelTitle">
        <span>{{'caseElectrodeModelTitle'|translate}}</span>
        <span *ngIf="promptElectrodeSelection()" style="margin-left: 5px;">
            (<span class="select-electrode-reminder">{{electrodeSelectionPrompt|translate}}</span>)
        </span>

    </p>
    <div class="electrode-model-side">
        <p class="mat-typography electrode-model-side-text" i18n="@@caseElectrodeModelLeftSide">
            {{'caseElectrodeModelLeftSide'|translate}}:
        </p>
        <mat-select class="electrode-model-selector" data-testid="left-electrode-model" panelWidth="auto"
                    [(ngModel)]="selectedLeft" (selectionChange)="onLeftSelection()">
            <mat-option
                *ngFor="let leftElectrode of electrodes" [value]="leftElectrode" data-testid="left-electrode-options">
                {{leftElectrode.modelDescKey|translate}}
            </mat-option>
        </mat-select>
    </div>
    <div class="electrode-model-side">
        <p class="mat-typography electrode-model-side-text" i18n="@@caseElectrodeModelRightSide">
            {{'caseElectrodeModelRightSide'|translate}}:
        </p>
        <mat-select class="electrode-model-selector" data-testid="right-electrode-model" panelWidth="auto"
                    [(ngModel)]="selectedRight" (selectionChange)="onRightSelection()">
            <mat-option *ngFor="let rightElectrode of electrodes" [value]="rightElectrode" data-testid="right-electrode-options">
                {{rightElectrode.modelDescKey|translate}}
            </mat-option>
        </mat-select>
    </div>
</div>

import { Directive, OnDestroy } from '@angular/core';
import { ReportsService } from './reports.service';
import { Subscriptions } from '../tools/subscriptions.class';
import { QuartersService } from '../services/quarters.service';

@Directive()
export abstract class ReportDirective implements OnDestroy {

    protected subscriptions = new Subscriptions();

    inProgress = false;
    startDate: Date = null;
    endDate: Date = null;

    protected constructor(protected rs: ReportsService, protected qs: QuartersService) {
    }

    ngOnDestroy(): void {
        this.subscriptions.cancel();
    }

    public abstract submitQuery(): void;

    disableSubmit(): boolean {
        return !((this.startDate && this.endDate && this.startDate < this.endDate) && !this.inProgress);
    }

    protected anyDateSelected(): boolean {
        return this.startDate != null || this.endDate != null;
    }

    protected reset(): void {
        this.startDate = null;
        this.endDate = null;
    }

    public updateStart(value: Date) {
        this.startDate = value;
    }

    public updateEnd(value: Date) {
        this.endDate = value;
    }

    public setCurrentMonth() {
        const now = new Date();
        this.startDate = new Date(now.getFullYear(), now.getMonth(), 1);
        this.endDate = now;
    }

    public setPrevMonth(): void {
        const now = new Date();
        this.startDate = new Date(now.getFullYear() - (now.getMonth() > 0 ? 0 : 1), (now.getMonth() - 1 + 12) % 12, 1);
        this.endDate = new Date(now.getFullYear(), now.getMonth(), 0, 23, 59, 59);
    }

    public setCurrentQuarter() {
        [this.startDate, this.endDate] = this.qs.currentQuarter();
    }

    public setPrevQuarter(): void {
        [this.startDate, this.endDate] = this.qs.prevQuarter();
    }

    public setCurrentYear() {
        const now = new Date();
        this.startDate = new Date(now.getFullYear(), 0, 1);
        this.endDate = now;
    }

    public setPrevYear(): void {
        const year = new Date().getFullYear() - 1;
        this.startDate = new Date(year, 0, 1);
        this.endDate = new Date(year, 11, 31, 23, 59, 59);
    }

    public setAll(): void {
        const now = new Date();
        this.startDate = new Date(2015, 0, 1);
        this.endDate = now;
    }
}

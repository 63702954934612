import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { PermissionsService } from '../services/permissions.service';
import { AuthService } from '../services/auth.service';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { NavigationService, NavigationServiceEntry, stableTransitions } from '../tools/navigation.service';
import { Permissions } from '../security/permissions.class';

@Injectable()
export class DashboardNavigationService extends NavigationService {

    private permissionListFlows: Subject<boolean> | null = null;

    constructor(permissionsService: PermissionsService, authService: AuthService, router: Router) {
        super(permissionsService, authService, router);
    }

    canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
        if (state.url.endsWith('/flows')) {
            return this.maybeRedirectChild(this.canActivateListFlows());
        }
        else if (state.url.endsWith('/dashboard')) {
            const table = [
                new NavigationServiceEntry(this.canActivateListFlows(), 'flows'),
            ];
            return this.maybeRedirect(state, table);
        }
        else {
            // This should only be used as a guard on paths that it knows how to handle.
            return false;
        }
    }

    public canActivateDashboard(): Observable<boolean> {
        return stableTransitions(this._requireCanActivateDashboard(), this.permissionsService);
    }

    public canActivateListFlows(): Observable<boolean> {
        return stableTransitions(this._requireCanListFlows(), this.permissionsService);
    }

    private _requireCanActivateDashboard(): Subject<boolean> {
        return this._requireCanListFlows();
    }

    private _requireCanListFlows(): Subject<boolean> {
        if (!this.permissionListFlows) {
            this.permissionListFlows = new ReplaySubject<boolean>(1);
            this.subscriptions.add(this.permissionsService.permissions(), () => {
                this.subscriptions.add(this.permissionsService.hasPermission(Permissions.listFlows()), (allowed) => {
                    this.permissionListFlows.next(allowed);
                });
            });
        }
        return this.permissionListFlows;
    }
}

import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService, DicomEndpoint } from '../../../services/account.service';
import { Account, AccountState } from '../../../services/api.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { PermissionsService } from '../../../services/permissions.service';
import { Subscriptions } from '../../../tools/subscriptions.class';
import { Permission, Permissions } from '../../../security/permissions.class';
import { ConfirmCancelDialogComponent } from '../../../tools/confirm-dialog.component';
import { compareUsersByDescription, User, userDescription, UserService } from '../../../services/user.service';
import { Values } from '../../../tools/values.class';

export class EmailRow {
    rowId: number;
    email: string;
}

export class EndpointRow {
    rowId: number;
    endpoint: DicomEndpoint;
}

export class AccountModel {
    id: string;
    created: string;
    updated: string;
    name: string;
    description: string;
    state: AccountState;
    defaultUserId: string;
    address: string;
    phone: string;
    autopublish: boolean;
    emailRows: EmailRow[];
    endpointRows: EndpointRow[];
    nextEmailRowId: number;
    nextEndpointRowId: number;
}

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('emailPaginator', {static: true}) emailPaginator: MatPaginator;
    @ViewChild('endpointPaginator', {static: true}) endpointPaginator: MatPaginator;

    model: AccountModel = {
        id: null,
        created: '',
        updated: '',
        name: '',
        description: '',
        state: AccountState.ACTIVE,
        defaultUserId: null,
        address: '',
        phone: '',
        autopublish: false,
        emailRows: [],
        endpointRows: [],
        nextEmailRowId: 0,
        nextEndpointRowId: 0
    };

    accountAsLoaded = null;

    // English only - account states can be only english at this stage
    availableAccountStates: AccountState[] = [AccountState.ACTIVE, AccountState.SUSPENDED];
    availableUsers: User[] = [];
    // noinspection SpellCheckingInspection
    availableFlavors: string[] = [
        'Generic',
        'StoreScp',
        'ClearCanvas',
        'MedInria',
        'Dcm4Chee',
        'SyngoVia',
        'AgfaImpax',
        'EFilm2',
        'Vitrea'];

    listAccountCasesAllowed = false;
    modifyAccountAllowed = false;
    deleteAccountAllowed = false;

    displayedEmailColumns = ['email', 'delete'];
    displayedEndpointColumns = ['name', 'aet_source', 'host', 'port', 'pacs_flavor', 'delete'];

    emailDataSource = new MatTableDataSource<EmailRow>();
    endpointDataSource = new MatTableDataSource<EndpointRow>();

    private lifetimeSubscriptions = new Subscriptions();
    private subscriptions = new Subscriptions();

    constructor(private activatedRoute: ActivatedRoute, private router: Router, private accountService: AccountService,
                private permissionService: PermissionsService, private userService: UserService,
                private dialog: MatDialog) {
    }

    ngOnInit() {
        // The account component needs the accountId to load account permissions. We will subscribe for the
        // lifetime of the component to the activateRoute params change to get the accountId when it changes.
        // Once the accountId changes, we load account permissions and data.
        this.lifetimeSubscriptions.add(this.activatedRoute.params, () => {
            this.setupNewAccount();
        });
    }

    private setupNewAccount() {
        this.subscriptions.cancel();
        this.model.id = this.activatedRoute.snapshot.params.accountId;
        this.subscriptions.add(this.userService.getUsers(), users => {
            this.availableUsers = users.sort(compareUsersByDescription);
        });

        this.subscriptions.add(this.permissionService.permissions(), () => {
            this.permissionService.prefetch([
                Permissions.listAccountCases(this.model.id),
                Permissions.accountModify(this.model.id),
                Permissions.accountDelete(this.model.id)
            ]);

            this.subscriptions.add(this.permissionService.hasPermission(Permissions.listAccountCases(this.model.id)),
                allowed => {
                    this.listAccountCasesAllowed = allowed;
                });

            this.subscriptions.add(this.permissionService.hasPermission(Permissions.accountModify(this.model.id)),
                allowed => {
                    this.modifyAccountAllowed = allowed;
                });

            this.subscriptions.add(this.permissionService.hasPermission(Permissions.accountDelete(this.model.id)),
                allowed => {
                    this.deleteAccountAllowed = allowed;
                });
        });
        this.loadAccountData();
    }

    ngAfterViewInit() {
        this.emailDataSource.paginator = this.emailPaginator;
        this.endpointDataSource.paginator = this.endpointPaginator;
    }

    ngOnDestroy() {
        this.subscriptions.cancel();
        this.lifetimeSubscriptions.cancel();
    }

    disableSaveAccount() {
        return Values.equal(this.accountFromModel(this.model), this.accountAsLoaded);
    }

    updateAccountInfo() {
        const payload = {
            name: this.model.name,
            description: this.model.description,
            state: this.model.state,
            default_user_id: this.model.defaultUserId,
            address: this.model.address,
            phone: this.model.phone,
            emails: this.model.emailRows.map(row => row.email),
            autopublish: this.model.autopublish,
            dicom_endpoints: this.model.endpointRows.map(row => row.endpoint)
        };
        this.subscriptions.add(this.accountService.updateAccount(this.model.id, payload), response => {
            this.readAccountData(response);
        }, error => {
            alert(error.message);
        });
    }

    deleteAccount() {
        this.confirm('settingsAccountsDeleteAccount', 'settingsAccountsDeleteAccountContent',
            'delete', 'cancel', () => {
                this.subscriptions.add(this.accountService.deleteAccount(this.model.id), () => {
                    // noinspection JSIgnoredPromiseFromCall
                    this.router.navigate(['/settings/accounts']);
                });
            });
    }

    addEmail() {
        this.model.emailRows.push({
            rowId: this.model.nextEmailRowId,
            email: ''
        });
        this.model.nextEmailRowId += 1;
        this.emailDataSource.data = this.model.emailRows;
        setTimeout(() => this.emailDataSource.paginator.lastPage(), 1);
    }

    removeEmail(rowId: number) {
        this.prepareForLastPageDisappearing(this.emailDataSource.paginator);

        this.model.emailRows = this.model.emailRows.filter(row => row.rowId !== rowId);
        this.emailDataSource.data = this.model.emailRows;
    }

    addEndpoint() {
        // noinspection SpellCheckingInspection
        const newEndpoint: DicomEndpoint = {
            name: '',
            aet_source: '',
            host: '',
            port: 2762, // DICOM using TLS over TCP/UDP; 104=without TLS; 11112= without TLS unprivileged; 2761=using ISCL over TCP/UDP
            pacs_flavor: this.availableFlavors[0]
        };
        this.model.endpointRows.push({
            rowId: this.model.nextEndpointRowId,
            endpoint: newEndpoint
        });
        this.model.nextEndpointRowId += 1;
        this.endpointDataSource.data = this.model.endpointRows;
        setTimeout(() => this.endpointDataSource.paginator.lastPage(), 1);
    }

    removeEndpoint(rowId: number) {
        this.prepareForLastPageDisappearing(this.endpointDataSource.paginator);

        this.model.endpointRows = this.model.endpointRows.filter(row => row.rowId !== rowId);
        this.endpointDataSource.data = this.model.endpointRows;
    }

    trackUserById(_index: number, user: User): string {
        return user.id;
    }

    trackStateByValue(_index: number, state: AccountState): string {
        return AccountState[state];
    }

    userDescription(user: User): string {
        return userDescription(user);
    }

    canViewUser(): Permission | null {
        return this.model.defaultUserId ? Permissions.userView(this.model.defaultUserId) : null;
    }

    userTarget(): string[] | null {
        return this.model.defaultUserId ? ['/settings/user', this.model.defaultUserId] : null;
    }

    private loadAccountData() {
        this.subscriptions.add(this.accountService.getAccount(this.model.id),
            (account: Account) => {
                if (account == null) {
                    this.router.navigate(['settings/not-found']).then();
                }
                else {
                    this.readAccountData(account);
                }
            },
            () => {
                this.router.navigate(['settings/not-found']).then();
            }
        );
    }

    private readAccountData(account: Account) {
        this.model = this.modelFromAccount(account);
        this.emailDataSource.data = this.model.emailRows;
        this.endpointDataSource.data = this.model.endpointRows;

        // Make a deep copy of the account data for later comparison
        this.accountAsLoaded = Values.deepCopy(account);
    }

    private confirm(title: string, contentText: string, actionText: string, cancelText: string, callbackFunction: () => void, cancelFunction?: () => void): void {
        const confirmationTemplate = {
            title,
            contentText,
            cancelText,
            actionText
        };
        const dialogRef = this.dialog.open(ConfirmCancelDialogComponent, {
            width: '400px', data: confirmationTemplate
        });

        dialogRef.afterClosed().subscribe(confirmed => {
            if (confirmed) {
                callbackFunction();
            }
            else if (cancelFunction !== undefined) {
                cancelFunction();
            }
        });
    }

    private prepareForLastPageDisappearing(paginator: MatPaginator) {
        // If we're on the last page and removing one item would decrease the number of pages, move to the prior page.
        if (!paginator.hasNextPage() && paginator.hasPreviousPage()) {
            const oldPageCount = Math.ceil(paginator.length / paginator.pageSize);
            const newPageCount = Math.ceil((paginator.length - 1) / paginator.pageSize);
            if (newPageCount < oldPageCount) {
                paginator.previousPage();
            }
        }
    }

    private accountFromModel(model: AccountModel): Account {
        return {
            id: model.id,
            created: model.created,
            updated: model.updated,
            name: model.name,
            description: model.description,
            state: model.state,
            default_user_id: model.defaultUserId,
            emails: model.emailRows.map(row => row.email),
            address: model.address,
            phone: model.phone,
            autopublish: model.autopublish,
            dicom_endpoints: model.endpointRows.map(row => row.endpoint)
        };
    }

    private modelFromAccount(account: Account): AccountModel {
        let nextEndpointRowId = 0;
        let nextEmailRowId = 0;
        return {
            id: account.id,
            created: account.created,
            updated: account.updated,
            name: account.name,
            description: account.description,
            state: account.state,
            defaultUserId: account.default_user_id,
            address: account.address,
            phone: account.phone,
            autopublish: account.autopublish,
            emailRows: account.emails.map(email => {
                return {rowId: nextEmailRowId++, email};
            }),
            endpointRows: account.dicom_endpoints.map(ep => {
                return {rowId: nextEndpointRowId++, endpoint: ep};
            }),
            nextEmailRowId: account.emails.length,
            nextEndpointRowId: account.dicom_endpoints.length
        };
    }

    public viewCases(metaKey: boolean): void {
        // the key in queryParams (accountId) need to match the ACCOUNT_CONTEXT_KEY in context-item.class.ts
        const extras = {queryParams: {accountId: this.model.id}};
        const url = this.router.serializeUrl(this.router.createUrlTree(['/home'], extras));
        metaKey ? window.open(url, '_blank') : this.router.navigate(['/home'], extras);
    }
}

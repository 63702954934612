export const environment = {
    production: false,              // product level settings i.e. the way the application behaves logically
    ngProdMode: false,              // specify the Angular app mode of operation
    rootUri: 'https://ca.us.surgicalis.com',
    version: '7.1.0-alpha.0',
    releaseDate: '2024-03-26',
    enableLanguages: true,
    enableVideos: false,
    geography: 'us'
};

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { Case, CaseService, CaseStatus, ElectrodesDetectionReport } from '../case/case.service';
import { AuthenticationEvent, AuthService } from '../services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NewCaseComponent } from '../new-case/new-case.component';
import { TableDirective } from '../tools/table.directive';
import { Permission, Permissions } from '../security/permissions.class';
import { PermissionsService } from '../services/permissions.service';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Subscriptions } from '../tools/subscriptions.class';
import {
    ACCOUNT_CONTEXT_KEY,
    AutoCompleteItem,
    GROUP_CONTEXT_KEY,
    USER_CONTEXT_KEY
} from '../tools/context-item.class';
import { Archived, CaseResponse, ContextInformation, DataElementResponse } from '../services/api.service';
import { DbsTargets, Elements, Flows, LEFT, RIGHT } from '../case/case.constants';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { LS_KEY_ARCHIVED } from '../app-constants';
import { DataElement } from '../case/data-element.class';
import { ElectrodeModelService } from '../case/electrode-model/electrode-model.service';
import { FlowType } from '../case/flow-type.class';

export interface CaseRow {
    name: string;
    description: string;
    target: string;
    status: CaseStatus;
    elements: Array<DataElementResponse>;
    shared: boolean;
    created: string;
    updated: string;
    archived: string | null;
}

interface HomeContextStrategy {
    getCases(caseService: CaseService, contextId: string, archived: Archived): Observable<CaseResponse[]>;

    caseCreatePermission(contextId: string): Permission;
}

class HomeUserContext implements HomeContextStrategy {
    getCases(caseService: CaseService, id: string, archived: Archived): Observable<CaseResponse[]> {
        return caseService.getCasesForUser(id, {archived: Archived[archived]});
    }

    caseCreatePermission(id: string): Permission {
        return Permissions.caseCreateForUser(id);
    }
}

class HomeGroupContext implements HomeContextStrategy {

    getCases(caseService: CaseService, id: string, archived: Archived): Observable<CaseResponse[]> {
        return caseService.getCasesForGroup(id, archived);
    }

    caseCreatePermission(id: string): Permission {
        return Permissions.caseCreateForGroup(id);
    }
}

class HomeAccountContext implements HomeContextStrategy {

    getCases(caseService: CaseService, id: string, archived: Archived): Observable<CaseResponse[]> {
        return caseService.getCasesForAccount(id, archived);
    }

    caseCreatePermission(currentUserId: string): Permission {
        return Permissions.caseCreateForUser(currentUserId);
    }
}

const STYLE_OK = {color: 'forestgreen'};
const STYLE_WARN = {color: 'red'};
const STYLE_UNAVAILABLE = {color: 'lightgray'};

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent extends TableDirective<CaseRow> implements OnInit, OnDestroy {

    @Input() contextChangeObserver: EventEmitter<AutoCompleteItem>;
    @Output() parentReady: ReplaySubject<ContextInformation | null> = new ReplaySubject<ContextInformation>(1);

    private contextStrategies: Map<string, HomeContextStrategy> = new Map<string, HomeContextStrategy>([
        [USER_CONTEXT_KEY, new HomeUserContext()],
        [GROUP_CONTEXT_KEY, new HomeGroupContext()],
        [ACCOUNT_CONTEXT_KEY, new HomeAccountContext()]
    ]);

    private static PAGE_INDEX = 'pageIndex';
    private static SEARCH_TEXT = 'searchText';
    private static CURRENT_CONTEXT = 'homeSelectedUser';

    public canCreateNewCase = true;
    public searchText = '';

    public canViewArchivedCases = false;
    private viewArchived: Archived = Archived.exclude;

    private currentContext: AutoCompleteItem | null = null;

    private lifetimeSubscriptions = new Subscriptions();
    private perContextSubscriptions = new Subscriptions();

    public elements = Elements;
    public flows = Flows;
    protected readonly LEFT = LEFT;
    protected readonly RIGHT = RIGHT;

    constructor(public auth: AuthService,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private caseService: CaseService,
                private permissionsService: PermissionsService,
                private electrodeModelService: ElectrodeModelService,
                private dialog: MatDialog) {
        super(['name', 'target', 'upload', 'pre-status', 'post-status', 'leads', 'shared', 'created', 'updated'], new MatTableDataSource<CaseRow>());
    }

    ngOnInit() {
        this.lifetimeSubscriptions.limit(this.auth.beforeLogout).subscribe((e: AuthenticationEvent) => {
            if (!e.authenticated) {
                // since we logged out, we need to clear all subscriptions, so we do not call all of them
                // as a  result of the invalidate event
                this.perContextSubscriptions.cancel();
                this.lifetimeSubscriptions.cancel();
            }
        });

        const listArchivedCases = this.lifetimeSubscriptions.limit(this.permissionsService.permissions().pipe(
            switchMap(() => this.permissionsService.hasPermission(Permissions.listArchivedCases()))
        ));

        // Check the main permission and once we have it start loading the page info
        this.lifetimeSubscriptions.limit(combineLatest([
            listArchivedCases, this.activatedRoute.queryParams
        ])).subscribe((value) => {
                this.canViewArchivedCases = value[0] as boolean;
                const savedArchive = localStorage.getItem(LS_KEY_ARCHIVED);
                this.viewArchived = this.canViewArchivedCases && savedArchive !== null ? Archived[savedArchive] : Archived.exclude;
                this.forceContextIfNeeded(value[1] as Params);
            }
        );
    }

    ngOnDestroy() {
        this.perContextSubscriptions.cancel();
        this.lifetimeSubscriptions.cancel();
    }

    private forceContextIfNeeded(params: Params) {
        let startWith: ContextInformation | null = null;
        // Priority One - if we have params from the URL, we use them to set the context
        if (Object.entries(params).length > 0) {
            const [k, v] = Object.entries(params)[0];
            startWith = {context_key: k, context_id: v};
        }
        else {
            // Priority Two - if we have a saved context, we use the saved context
            const savedContext = localStorage.getItem(HomeComponent.CURRENT_CONTEXT);
            if (savedContext !== null) {
                startWith = JSON.parse(savedContext);
            }
        }
        // Either use the params or saved context or use null (no preference, use default)
        this.parentReady.next(startWith);
        this.parentReady.complete();
    }

    onContextChange(selectedContext: AutoCompleteItem) {
        if (selectedContext !== null) {
            const contextSwitched = this.currentContext === null || this.currentContext !== selectedContext;
            if (contextSwitched) {
                // Store the new selection in local storage
                localStorage.setItem(HomeComponent.CURRENT_CONTEXT, JSON.stringify(selectedContext.contextInfo));

                // Stop any subscriptions that were based on the prior selection
                this.perContextSubscriptions.cancel();

                this.loadCasesForContext(selectedContext);

                // Determine whether the current user is allowed to create cases in the current newContext
                const casesContext = this.contextStrategies.get(selectedContext.contextKey);
                this.perContextSubscriptions.limit(this.permissionsService.permissions().pipe(
                    switchMap(() => this.permissionsService.hasPermission(casesContext.caseCreatePermission(selectedContext.id))),
                )).subscribe(allowed => this.canCreateNewCase = allowed);

                if (contextSwitched) {
                    this.clearSearch();
                    this.currentContext = selectedContext;
                }
                this.applyFilter();
                if (!contextSwitched) {
                    this.restorePage();
                }
            }
        }
    }

    private loadCasesForContext(context: AutoCompleteItem) {
        const casesContext: HomeContextStrategy = this.contextStrategies.get(context.contextKey);
        this.dataSource.data = [];
        // Load the cases for the selected item
        this.perContextSubscriptions.limit(casesContext.getCases(this.caseService, context.id, this.viewArchived)).subscribe(
            (data: Array<any>) => {
                this.dataSource.data = data.map(item => {
                    item.shared = !!item.group_id;
                    item.status = this.caseService.calculateCaseStatus(item);
                    item.target = DbsTargets.get(item.target).titleKey;
                    return item;
                });
                setTimeout(() => {
                }, 200);
            }
        );
    }

    public openCreateCaseDialog() {
        const newCaseDialogOptions = {
            width: '600px',
            data: {
                userId: this.auth.currentUserId,
                groupId: this.currentContext.contextKey === GROUP_CONTEXT_KEY ? this.currentContext.id : null
            },
        };
        const dialogRef = this.dialog.open(NewCaseComponent, newCaseDialogOptions);
        this.lifetimeSubscriptions.limit(dialogRef.afterClosed()).subscribe((data: Case) => {
            if (data != null) {
                this.router.navigate(['/case', data.id]).then();
            }
        });
    }

    public describeFlowStatus(caseData: CaseRow, forFlow: FlowType): string {
        return this.caseService.describeFlowStatus(this.caseService.calculateFlowStatus(caseData, forFlow));
    }

    public onActivate(row: Case, metaKey: boolean) {
        this.navigateFromClick(this.router, metaKey, ['/case', row.id]);
    }

    public applyFilter() {
        this.dataSource.filter = this.searchText.trim().toLowerCase();
        localStorage.setItem(HomeComponent.SEARCH_TEXT, this.searchText);
    }

    private restorePage() {
        let pageIndex: string | number | null = localStorage.getItem(HomeComponent.PAGE_INDEX);
        if (pageIndex !== null) {
            pageIndex = Number(pageIndex);
            // We can only set the page after *something* else happens. But it's not clear
            // what that is or if we can trap the event. So, just use a timeout.
            setTimeout(() => {
                this.dataSource.paginator.pageIndex = pageIndex as number;
            }, 50);
        }
    }

    private clearSearch() {
        this.searchText = '';
        localStorage.setItem(HomeComponent.SEARCH_TEXT, this.searchText);
        localStorage.removeItem(HomeComponent.PAGE_INDEX);
    }

    public savePage() {
        localStorage.setItem(HomeComponent.PAGE_INDEX, this.dataSource.paginator.pageIndex.toString());
    }

    public includeArchived(): boolean {
        return this.viewArchived === Archived.include;
    }

    public showArchiveChanged(event: MatCheckboxChange): void {
        this.viewArchived = event.checked ? Archived.include : Archived.exclude;
        localStorage.setItem(LS_KEY_ARCHIVED, Archived[this.viewArchived]);
        this.loadCasesForContext(this.currentContext);
    }

    public maybeArchivedClass(item: CaseRow): string {
        return item.archived == null ? '' : 'archived';
    }

    public getStyleForElement(item: CaseRow, de: DataElement): any {
        const latest = this.caseService.latestElement(item, de);
        if (latest === null) {
            return STYLE_UNAVAILABLE;
        }
        return latest.upload_report?.errors?.length > 0 ? STYLE_WARN : STYLE_OK;
    }

    public getLeadForSide(item: CaseRow, side: string): string {
        const latest = this.caseService.latestElement(item, Elements.USER_ELECTRODE_MODELS_SELECTION);
        if (latest === null || latest.value === null) {
            return '';
        }
        const models = (latest.value as ElectrodesDetectionReport).electrodes[side] ?? [];
        return models.length > 0 ? this.electrodeModelService.findModel(models[0]).modelShortDescKey : '';
    }
}

<mat-card>
    <mat-card-title *ngIf="logData" class="view-log-header">
        <div class="view-log-header-text">
            <span *ngIf="flow">{{flow.description}}</span>
            <span class="view-log-flow-info">
            <span style="margin: 0 5px 0 0;"><b>Start:</b></span><span>{{data.flowData.start_time| localDate}}</span>
            <span style="margin: 0 5px 0 15px;"><b>End:</b></span><span>{{data.flowData.end_time| localDate}}</span>
            <span style="margin: 0 5px 0 15px;"><b>Run Time:</b></span><span>{{flowTime|minuteSeconds}} min</span>
            <span style="margin: 0 5px 0 15px;"><b>Exit Code:</b></span>{{data.flowData.exit_code}}
        </span>
        </div>
        <button mat-raised-button (click)="onClose()">Close</button>
    </mat-card-title>
    <mat-card-content *ngIf="logData">
        <div class="view-log-text">{{logData}}</div>
    </mat-card-content>
</mat-card>

<div class="fill-parent reports-container">
    <div class="snapshot-report-header">
        <div class="reports-selection-container">
            <div class="reports-selection">
                <div style="width: 100%;">
                    <mat-radio-group class="usage-context-buttons" [(ngModel)]="selectedContext" ngDefaultControl
                                     (ngModelChange)="updateContextList()">
                        <mat-radio-button *ngFor="let uc of usageContextList" [value]="uc">
                            {{uc.label | translate}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
                <mat-form-field style="width: 100%;" appearance="fill">
                    <mat-label>{{'reportSnapshotSelectContext'|translate}}</mat-label>
                    <input matInput type="text" [formControl]="contextControl" [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" panelWidth="400px">
                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option"
                                    (click)="optionClicked($event, option)">
                            <mat-icon class="snapshot-report-auto-complete-item-icon">{{option.iconKey}}</mat-icon>
                            <div class="snapshot-report-auto-complete-item-div">
                                <div class="snapshot-report-auto-complete-item-desc-text">
                                    {{option.display}}
                                </div>
                                <mat-checkbox [checked]="option.selected" (change)="toggleSelection(option)"
                                              (click)="$event.stopPropagation()">
                                </mat-checkbox>
                            </div>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-form-field appearance="fill" style="">
                    <mat-label>{{'reportSelectDatesLabel'|translate}}</mat-label>
                    <mat-date-range-input [rangePicker]="picker" [disabled]="inProgress">
                        <input matStartDate placeholder="{{'startDate'|translate}}" [(ngModel)]="startDate">
                        <input matEndDate placeholder="{{'endDate'|translate}}" [(ngModel)]="endDate">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
                <button mat-raised-button style="align-self: center;" class="report-range-btn"
                        (click)="submitQuery()" [disabled]="disableSubmit()">
                    {{'submit'|translate}}
                </button>
            </div>
            <div class="reports-range">
                <button mat-raised-button class="report-range-btn"
                        (click)="setCurrentMonth()" [disabled]="inProgress">
                    {{'reportsRangeCurrentMonth'|translate}}
                </button>
                <button mat-raised-button class="report-range-btn" (click)="setPrevMonth()"
                        [disabled]="inProgress">
                    {{'reportsRangePrevMonth'|translate}}
                </button>
            </div>
            <div class="reports-range">
                <button mat-raised-button class="report-range-btn" (click)="setCurrentQuarter()"
                        [disabled]="inProgress">
                    {{'reportsRangeCurrentQuarter'|translate}}
                </button>
                <button mat-raised-button class="report-range-btn" (click)="setPrevQuarter()"
                        [disabled]="inProgress">
                    {{'reportsRangePrevQuarter'|translate}}
                </button>
            </div>
            <div class="reports-range">
                <button mat-raised-button class="report-range-btn" (click)="setCurrentYear()"
                        [disabled]="inProgress">
                    {{'reportsRangeCurrentYear'|translate}}
                </button>
                <button mat-raised-button class="report-range-btn" (click)="setPrevYear()"
                        [disabled]="inProgress">
                    {{'reportsRangePrevYear'|translate}}
                </button>
                <button mat-raised-button class="report-range-btn" (click)="setAll()" [disabled]="inProgress">
                    {{'reportsRangeAllTimes'|translate}}
                </button>
            </div>
        </div>

        <div class="snapshot-report-container">
            <div class="snapshot-report-row">
                <div class="snapshot-report-column" *ngIf="caseData.length > 0">
                    <div class="snapshot-report-title-container">
                        <p class="mat-typography snapshot-item-title">{{'reportCasesAndFlows'|translate}}</p>
                    </div>
                    <mat-table [dataSource]="caseData">
                        <ng-container matColumnDef="param" i18n="@@reportSnapshotParam">
                            <mat-cell style="justify-content: flex-start;" *matCellDef="let statRow"
                                      [ngClass]="rowClass(statRow)">
                                {{statRow.titleKey|translate}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="value" i18n="@@reportSnapshotValue">
                            <mat-cell class="snapshot-number-column" *matCellDef="let statRow"
                                      [ngClass]="rowClass(statRow)">
                                {{formatItem(statRow)}}
                            </mat-cell>
                        </ng-container>
                        <mat-row *matRowDef="let statRow; columns: displayedColumns;" class="table-row">
                        </mat-row>
                    </mat-table>
                </div>
                <div class="snapshot-report-column" *ngIf="caseLeadData.length > 0 || leadsData.length > 0">
                    <div class="snapshot-report-leads-leads">
                        <div *ngIf="caseLeadData.length > 0" style="margin-bottom: 40px;">
                            <div class="snapshot-report-title-container">
                                <p class="mat-typography snapshot-item-title">{{'reportSnapshotLeadSummary'|translate}}</p>
                            </div>
                            <mat-table [dataSource]="caseLeadData">
                                <ng-container matColumnDef="param" i18n="@@reportSnapshotParam">
                                    <mat-cell style="justify-content: flex-start;" *matCellDef="let statRow">
                                        {{statRow.titleKey|translate}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="value" i18n="@@reportSnapshotValue">
                                    <mat-cell class="snapshot-number-column" *matCellDef="let statRow">
                                        {{formatItem(statRow)}}
                                    </mat-cell>
                                </ng-container>
                                <mat-row *matRowDef="let statRow; columns: displayedColumns;" class="table-row">
                                </mat-row>
                            </mat-table>
                        </div>
                        <div *ngIf="leadsData.length > 0">
                            <div class="snapshot-report-title-container">
                                <p class="mat-typography snapshot-item-title">{{'reportLeads'|translate}}</p>
                            </div>
                            <mat-table [dataSource]="leadsData">
                                <ng-container matColumnDef="param" i18n="@@reportSnapshotParam">
                                    <mat-cell style="justify-content: flex-start;" *matCellDef="let leadRow">
                                        {{getElectrodeModel(leadRow.model)|translate}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="value" i18n="@@reportSnapshotValue">
                                    <mat-cell class="snapshot-number-column" *matCellDef="let leadRow">
                                        {{formatLeadCount(leadRow.count)}}
                                    </mat-cell>
                                </ng-container>
                                <mat-row *matRowDef="let leadRow; columns: displayedColumns;" class="table-row">
                                </mat-row>
                            </mat-table>
                        </div>
                    </div>
                </div>
                <div class="snapshot-report-column"
                     *ngIf="usersData.length > 0 || accountsData.length > 0 || groupsData.length > 0">
                    <div class="snapshot-report-users-accounts-groups">
                        <div *ngIf="usersData.length > 0" style="margin-bottom: 40px;">
                            <div class="snapshot-report-title-container">
                                <p class="mat-typography snapshot-item-title">{{'reportUsers'|translate}}</p>
                            </div>
                            <mat-table [dataSource]="usersData">
                                <ng-container matColumnDef="param" i18n="@@reportSnapshotParam">
                                    <mat-cell style="justify-content: flex-start;" *matCellDef="let statRow">
                                        {{statRow.titleKey|translate}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="value" i18n="@@reportSnapshotValue">
                                    <mat-cell class="snapshot-number-column" *matCellDef="let statRow">
                                        {{formatItem(statRow)}}
                                    </mat-cell>
                                </ng-container>
                                <mat-row *matRowDef="let statRow; columns: displayedColumns;" class="table-row">
                                </mat-row>
                            </mat-table>
                        </div>
                        <div *ngIf="accountsData.length > 0" style="margin-bottom: 40px;">
                            <div class="snapshot-report-title-container">
                                <p class="mat-typography snapshot-item-title">{{'reportAccounts'|translate}}</p>
                            </div>
                            <mat-table [dataSource]="accountsData">
                                <ng-container matColumnDef="param" i18n="@@reportSnapshotParam">
                                    <mat-cell style="justify-content: flex-start;" *matCellDef="let statRow">
                                        {{statRow.titleKey|translate}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="value" i18n="@@reportSnapshotValue">
                                    <mat-cell class="snapshot-number-column" *matCellDef="let statRow">
                                        {{formatItem(statRow)}}
                                    </mat-cell>
                                </ng-container>
                                <mat-row *matRowDef="let statRow; columns: displayedColumns;" class="table-row">
                                </mat-row>
                            </mat-table>
                        </div>
                        <div *ngIf="groupsData.length > 0">
                            <div class="snapshot-report-title-container">
                                <p class="mat-typography snapshot-item-title">{{'reportGroups'|translate}}</p>
                            </div>
                            <mat-table [dataSource]="groupsData">
                                <ng-container matColumnDef="param" i18n="@@reportSnapshotParam">
                                    <mat-cell style="justify-content: flex-start;" *matCellDef="let statRow">
                                        {{statRow.titleKey|translate}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="value" i18n="@@reportSnapshotValue">
                                    <mat-cell class="snapshot-number-column" *matCellDef="let statRow">
                                        {{formatItem(statRow)}}
                                    </mat-cell>
                                </ng-container>
                                <mat-row *matRowDef="let statRow; columns: displayedColumns;" class="table-row">
                                </mat-row>
                            </mat-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="snapshot-report-footer">
        <button mat-raised-button class="report-range-btn" (click)="submitExport()"
                [disabled]="disableSubmit()">
            {{'reportSnapshotExportCsv'|translate}}
        </button>
        <button mat-raised-button style="margin-right: 20px;" class="report-range-btn" (click)="clear()"
                [disabled]="disableClear()">
            {{'clear'|translate}}
        </button>
    </div>
</div>

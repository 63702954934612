<mat-card appearance="outlined" class="run-flow-box">
    <mat-card-title class="run-flow-header">
        <div>
            <span class="mat-typography run-flow-title">Force Submit Flow</span>
        </div>
        <button mat-raised-button
                [disabled]="selectedFlow < 1"
                (click)="submitFlowReRun()">
            Submit
        </button>
    </mat-card-title>
    <mat-card-content class="run-flow-content">
        <mat-form-field style="width: 90%;">
            <mat-select [(ngModel)]="selectedFlow">
                <mat-option *ngFor="let flow of availableFlows" [value]="flow.flowCode">
                    {{flow.description}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </mat-card-content>
</mat-card>

import { Component, HostListener, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterStateSnapshot } from '@angular/router';

import { AllTarget, DbsTarget, DbsTargets, Elements, SWITCHABLE_TARGETS, Targets } from './case.constants';
import { Case, CaseService } from './case.service';
import { filter, switchMap } from 'rxjs/operators';
import { PermissionsService } from '../services/permissions.service';
import { Subscriptions } from '../tools/subscriptions.class';
import { Permission, Permissions } from '../security/permissions.class';
import { ReplaySubject } from 'rxjs';
import { MatSidenavContent } from '@angular/material/sidenav';
import { TranslateService } from '@ngx-translate/core';
import { userLastAndFirstName, UserService } from '../services/user.service';
import { CaseResponse } from '../services/api.service';
import { AccountService } from '../services/account.service';
import { Features } from '../security/feature.class';
import { DeleteCaseState } from './overview/overview.component';
import { ConfirmationTemplate, ConfirmCancelDialogComponent } from '../tools/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-case',
    templateUrl: './case.component.html',
    styleUrls: ['./case.component.scss']
})
export class CaseComponent implements OnInit, OnDestroy {
    @ViewChild('sidenavContent', {static: true}) contentArea: MatSidenavContent;

    @Output() contentAreaHeight: number;
    @Output() contentAreaWidth: number;

    readonly OVERVIEW = 'overview';
    readonly DICOM = 'dicom-viewer';
    readonly LEAD_LOCATION = 'dbs-lead-location';
    readonly NOT_FOUND = 'not-found';
    readonly ADMIN = 'tools';

    readonly TITLE_3D_VIEW = 'caseSideMenu3DView';

    private subscriptions = new Subscriptions();
    private perCaseSubscriptions = new Subscriptions();

    caseUpdated = new ReplaySubject<Case>(1);
    onDicomActivate = new ReplaySubject<boolean>(1);

    caseData: Case | null = null;
    caseName = '';
    caseTarget: DbsTarget | null = null;
    targetTitle = '';
    selectableTargets: Array<DbsTarget> = [];
    approvedTargets: Array<DbsTarget> = [];

    caseId = '';
    selectedView = this.OVERVIEW;
    selectedViewVisible = true;
    hasDbsData: boolean;
    hasDicomData: boolean;
    leadViewTitle: string = this.TITLE_3D_VIEW;

    private deleteCaseState: DeleteCaseState = DeleteCaseState.Idle;
    private checkSubmitEnabled: boolean = true;
    private destUrl: string | null = null;

    userDescription = '';
    accountName = '';

    allowedUploadPlanningPostopTransform = false;
    allowedLogCaseEvent = false;
    allowedReRunFlow = false;
    allowedChoosePlanningToPostopRegistrationMethod = false;
    allowedAuditQuery = false;
    allTargetsEnabled = false;
    allowedCaseReview = false;
    allowAutoDetectElectrode = false;

    loadingDicom = false;
    loadingNifti = false;

    constructor(private activatedRoute: ActivatedRoute, private router: Router, private caseService: CaseService,
                private userService: UserService, private permissionsService: PermissionsService,
                private accountService: AccountService, private translate: TranslateService, private dialog: MatDialog) {
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.setContentSize();
    }

    ngOnInit() {
        this.setContentSize();
        // The case component needs the caseId to load account permissions and data.
        // We will subscribe for the lifetime of the component to the activateRoute params change to get the
        // caseId when it changes. Once the caseId changes, we load case permissions and data.
        this.subscriptions.add(this.activatedRoute.params, () => {
            this.setupNewCase();
        });

        this.subscriptions.limit(this.router.events).pipe(
            filter(event => event instanceof NavigationEnd)).subscribe(
            (event: NavigationEnd) => this.onRouterEvent(event.url)
        );

        this.onRouterEvent(this.router.routerState.snapshot.url);
    }

    private setupNewCase(): void {
        this.perCaseSubscriptions.cancel();
        this.caseId = this.activatedRoute.snapshot.params.caseId;
        this.perCaseSubscriptions.add(this.permissionsService.permissions(), () => {
            this.permissionsService.batch(this.subscriptions, [
                    [Permissions.caseUpload(this.caseId, Elements.PLANNING_TO_POSTOP_TRX.name), allowed => {
                        this.allowedUploadPlanningPostopTransform = allowed;
                    }],
                    [Permissions.caseLogEvent(this.caseId), allowed => {
                        this.allowedLogCaseEvent = allowed;
                    }],
                    [Permissions.caseReRunFlow(this.caseId), allowed => {
                        this.allowedReRunFlow = allowed;
                    }],
                    [Permissions.caseReview(this.caseId), allowed => {
                        this.allowedCaseReview = allowed;
                    }],
                    [Permissions.caseModify(this.caseId, ['planning_to_postop_registration_method']), allowed => {
                        this.allowedChoosePlanningToPostopRegistrationMethod = allowed;
                    }],
                    [Permissions.auditQuery(), allowed => {
                        this.allowedAuditQuery = allowed;
                    }],
                    [Permissions.featureAvailable(Features.ENABLE_ALL_TARGETS), allowed => {
                        this.allTargetsEnabled = allowed;
                        this.setTargets();
                    }],
                    [Permissions.featureAvailable(Features.ENABLE_AUTO_DETECT), allowed => {
                        this.allowAutoDetectElectrode = allowed;
                    }],

                ]
            );
        });

        this.perCaseSubscriptions.limit(this.caseService.getCaseById(this.caseId)).subscribe(
            {
                next: (responseData: Case) => {
                    this.setCaseUpdated();
                    this.caseUpdated.next(responseData);
                    if (this.selectedView === this.NOT_FOUND) {
                        this.router.navigate(['/case', this.caseId, this.OVERVIEW]).then();
                    }
                    this.subscriptions.limit(this.translate.onLangChange).subscribe(
                        () => this.setTargetDescription()
                    );
                },
                error: error => {
                    if (error.status === 404) {
                        this.router.navigate(['/case', this.caseId, this.NOT_FOUND]).then();
                    }
                },
                complete: () => {
                }
            }
        );
    }

    private setCaseUpdated(): void {
        this.subscriptions.add(this.caseUpdated, (data: Case) => {
            this.updateCaseData(data);
            this.updateAccountName();
        });
    }

    private updateCaseData(data: CaseResponse) {
        this.perCaseSubscriptions.cancel();
        this.caseData = data;
        this.hasDbsData = this.caseService.hasElectrodeVisualization(data);
        this.hasDicomData = this.caseService.hasElement(data, Elements.STN_VISUALIZATION_HEATMAP_T1_DICOM);
        this.caseName = this.caseData.name;
        setTimeout(() => {
            this.caseTarget = DbsTargets.get(data.target);
            this.setTargetDescription();
        }, 0);
        this.updateUserDescription();
        this.setTargets();
    }

    dicomLoadStatus(inProgress: boolean) {
        // See explanation in niftiLoadStatus() below. Currently, the DICOM loading is lazy, so the exact same sequence should not occur,
        // but for consistency and as a defensive strategy, defer assigning to this.loadingDicom.
        setTimeout(() => {
            this.loadingDicom = inProgress;
        }, 0);
    }

    niftiLoadStatus(inProgress: boolean) {
        // This callback can fire in response to an in-progress change detection, where a case update causes the lead location component
        // to be created in an already existing case component. The lead location component emits true before it starts loading the
        // NIfTI, triggering this callback. If we immediately assigned the value to this.loadingNifti, we can get a warning from Angular
        // about a value changing after it has been checked. Using a timeout to defer the assignment prevents this warning.
        setTimeout(() => {
            this.loadingNifti = inProgress;
        }, 0);
    }

    ngOnDestroy() {
        this.perCaseSubscriptions.cancel();
        this.subscriptions.cancel();
        this.caseData = null;
    }

    get dbsTarget(): string {
        return this.caseTarget === null ? '' : this.caseTarget.titleKey;
    }

    enableAdminView(): boolean {
        return this.allowedReRunFlow || this.allowedLogCaseEvent || this.allowedUploadPlanningPostopTransform
            || this.allowedChoosePlanningToPostopRegistrationMethod || this.allowedAuditQuery;
    }

    get submitEnabled(): boolean {
        return this.caseService.canSubmitPrediction(this.caseData) || this.caseService.canSubmitPostop(this.caseData, this.allowAutoDetectElectrode);
    }

    public canLeave(nextState: RouterStateSnapshot): boolean {
        if (!this.caseDeleted && this.submitEnabled && this.checkSubmitEnabled) {
            setTimeout(() => {
                this.destUrl = nextState.url;
                this.confirmLeaveWithoutSubmit();
            }, 100);
            return false;
        }
        return true;
    }

    private confirmLeaveWithoutSubmit() {
        const dialogData: ConfirmationTemplate = {
            title: 'caseConfirmLeaveWithoutSubmitTitle',
            contentText: 'caseConfirmLeaveWithoutSubmitContent',
            cancelText: 'caseConfirmLeaveWithoutSubmitStay',
            actionText: 'caseConfirmLeaveWithoutSubmitLeave'
        };
        const dialogRef = this.dialog.open(ConfirmCancelDialogComponent, {
            width: '400px', data: dialogData
        });

        dialogRef.afterClosed().subscribe(confirmed => {
            if (confirmed && this.destUrl !== null) {
                // leave the case page
                this.checkSubmitEnabled = false;
                this.router.navigate([this.destUrl]).then();
            }
        });
    }

    private onRouterEvent(url: string): void {
        for (const view of [this.OVERVIEW, this.DICOM, this.LEAD_LOCATION, this.ADMIN, this.NOT_FOUND]) {
            if (url.endsWith(view)) {
                this.show(view, false);
                return;
            }
        }
    }

    public show(whichView: string, initiallyVisible: boolean) {
        this.selectedView = whichView;
        this.selectedViewVisible = initiallyVisible;

        this.onDicomActivate.next(whichView === this.DICOM);

        if (!initiallyVisible) {
            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
                this.selectedViewVisible = true;
            }, 1);
        }
    }

    get caseDeleted(): boolean {
        return (this.deleteCaseState === DeleteCaseState.InProgress || this.deleteCaseState === DeleteCaseState.Success);
    }

    public deleteCaseStatus(state: DeleteCaseState): any {
        this.deleteCaseState = state;
    }

    public viewClass(whichView: string): any {
        return {
            hidden: whichView !== this.selectedView,
            invisible: whichView === this.selectedView && !this.selectedViewVisible,
        };
    }

    private setTargets() {
        // selectableTargets is based on the permission.
        // User WILL be able to select target that does not have approved segmentation.
        // The Overview-DownloadAlpha/DicomViewer/3D-Model should handle segmentations that were not approved.
        this.selectableTargets.splice(0, this.selectableTargets.length);
        this.selectableTargets.push(...SWITCHABLE_TARGETS);
        if (this.allTargetsEnabled) {
            this.selectableTargets.push(AllTarget);
        }
        this.approvedTargets = this.caseService.getApprovedTargets(this.caseData);
    }

    onDbsTargetChange() {
        this.caseService.updateCase(this.caseId, {target: this.caseTarget.key}).subscribe(
            (data: Case) => {
                this.updateCaseData(data);
                this.caseUpdated.next(data);
            });
        this.setTargetDescription();
    }

    private setTargetDescription() {
        if (this.caseTarget !== null) {
            const messageKey = (this.caseTarget.key !== Targets.STN) ? 'caseSideMenuDbsTargets' : 'caseSideMenuDbsTarget';
            this.translate.get([messageKey, this.caseTarget.titleKey]).subscribe(translated => {
                this.targetTitle = `${translated[messageKey]}`;
            });
        }
    }

    isUserAdmin(): Permission | null {
        return this.userId ? Permissions.userAdmin() : null;
    }

    get userId(): string | null | undefined {
        return this.caseData?.user_id;
    }

    get accountId(): string | null | undefined {
        return this.caseData?.account_id;
    }

    private updateUserDescription() {
        this.userDescription = '';
        if (this.userId) {
            this.perCaseSubscriptions.limit(this.permissionsService.permissions().pipe(
                switchMap(() => {
                    return this.permissionsService.hasPermission(this.isUserAdmin());
                }),
            )).subscribe(allowed => {
                if (allowed) {
                    this.perCaseSubscriptions.limit(this.userService.getUser(this.userId)).subscribe(user => {
                        this.userDescription = userLastAndFirstName(user) || user.email;
                    });
                }
                else {
                    this.userDescription = '';
                }
            });
        }
    }

    private updateAccountName() {
        this.accountName = '';
        if (this.accountId) {
            this.perCaseSubscriptions.limit(this.permissionsService.permissions().pipe(
                switchMap(() => {
                    return this.permissionsService.hasPermission(this.isUserAdmin());
                }),
            )).subscribe(allowed => {
                if (allowed) {
                    this.perCaseSubscriptions.limit(this.accountService.getAccount(this.accountId)).subscribe(account => {
                        this.accountName = account?.name ?? '';
                    });
                }
                else {
                    this.accountName = '';
                }
            });
        }
    }

    private setContentSize() {
        this.contentAreaWidth = this.contentArea.getElementRef().nativeElement.offsetWidth;
        this.contentAreaHeight = this.contentArea.getElementRef().nativeElement.offsetHeight;
    }

    public canSwitchTargets(): boolean {
        // to allow target switching we need to have the segmentation validation element and a
        // valid prediction for both the STN and the GP targets
        return this.caseService.allowTargetSwitch(this.caseData);
    }

    public contentClass(): string {
        if (this.selectedView == this.DICOM) {
            return 'disable-scroll';
        }
        return '';
    }

    public onClick(dest: string): void {
        let route: Array<string>;
        let disabled = false;
        switch (dest) {
            case this.DICOM:
                disabled = !this.hasDicomData;
                route = ['/case', this.caseId, 'dicom-viewer'];
                break;
            case this.LEAD_LOCATION:
                disabled = !this.hasDbsData;
                route = ['/case', this.caseId, 'dbs-lead-location'];
                break;
            case this.ADMIN:
                disabled = !this.enableAdminView();
                route = ['/case', this.caseId, 'tools'];
                break;
        }
        if (disabled || this.selectedView === this.NOT_FOUND) {
            return;
        }
        this.router.navigate(route).then();
    }

    public getTargetIcon(target: DbsTarget): string {
        // if we do not have a result or the target is ALL, we do not show an icon
        if (target === AllTarget || !this.caseService.hasSuccessPublishedPlanning(this.caseData)) {
            return '';
        }
        // if we have a valid planning result and return approved/not-approved icon
        return this.approvedTargets.includes(target) ? 'verified' : 'unpublished';
    }

    public getTargetIconCls(target: DbsTarget): string {
        // if we do not have a result or the target is ALL, we do not show an icon, cls is empty
        if (target === AllTarget || !this.caseService.hasSuccessPublishedPlanning(this.caseData)) {
            return '';
        }
        // if we have a valid planning result and return approved/not-approved cls
        return this.approvedTargets.includes(target) ? 'case-side-nav-target-icon-approved' : '';
    }

    public hasSuccessPublishedPlanning(): boolean {
        return this.caseService.hasSuccessPublishedPlanning(this.caseData);
    }
}

import { Component, Inject, LOCALE_ID } from '@angular/core';
import { environment } from '../../../environments/environment';
import { formatDate } from '@angular/common';

interface EnvArgs {
    version: string;
    geography: string;
}

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss']
})

export class AboutComponent {

    private readonly RELEASE_DATE = new Date(environment.releaseDate);

    // Turn x.y.z-alpha.* into 0x0y0z
    public readonly UDI = '(01)0860001580907' + environment.version.split('-')[0].split('.').map(v => v.padStart(2, '0')).join('');

    constructor(@Inject(LOCALE_ID) public locale: string) {
    }

    get enableEu(): boolean {
        return environment.geography === 'eu';
    }

    get releaseDate(): string {
        return formatDate(this.RELEASE_DATE, 'YYYY-MM', this.locale);
    }

    protected readonly env: EnvArgs = environment;
}

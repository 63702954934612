import { Injectable } from '@angular/core';
import { PermissionsService } from '../services/permissions.service';
import { Observable, ReplaySubject } from 'rxjs';
import { Permissions } from '../security/permissions.class';
import { UserService } from '../services/user.service';
import { AuthenticationState, AuthService } from '../services/auth.service';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { NavigationService, NavigationServiceEntry, stableTransitions } from '../tools/navigation.service';
import { ACCOUNT_ADMIN, GroupPermission, UserResponse } from '../services/api.service';
import { atLeastOneOf, replay } from '../rxjs/observables';

@Injectable()
export class SettingsNavigationService extends NavigationService {

    private mCanActivateSettings: Observable<boolean> | null = null;
    private mPermissionUserAdmin: Observable<boolean> | null = null;
    private mAccountAdmin: Observable<boolean> | null = null;
    private mPermissionViewAccounts: Observable<boolean> | null = null;
    private mActiveGroupAdmin: Observable<boolean> | null = null;
    private mCanActivateSettingsUsers: Observable<boolean> | null = null;
    private mCanActivateSettingsGroups: Observable<boolean> | null = null;

    constructor(permissionsService: PermissionsService, authService: AuthService, router: Router,
                private userService: UserService) {
        super(permissionsService, authService, router);
    }

    canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
        if (state.url.endsWith('/users')) {
            return this.maybeRedirectChild(this.canActivateSettingsUsers());
        }
        else if (state.url.endsWith('/groups')) {
            return this.maybeRedirectChild(this.canActivateSettingsGroups());
        }
        else if (state.url.endsWith('/accounts')) {
            return this.maybeRedirectChild(this.canActivateSettingsAccounts());
        }
        else if (state.url.endsWith('/settings')) {
            const table = [
                new NavigationServiceEntry(this.canActivateSettingsUsers(), 'users'),
                new NavigationServiceEntry(this.canActivateSettingsGroups(), 'groups'),
                new NavigationServiceEntry(this.canActivateSettingsAccounts(), 'accounts'),
            ];
            return this.maybeRedirect(state, table);
        }
        else {
            // This should only be used as a guard on paths that it knows how to handle.
            return false;
        }
    }

    public canActivateSettings(): Observable<boolean> {
        return stableTransitions(this._requireCanActivateSettings(), this.permissionsService);
    }

    public canActivateSettingsUsers(): Observable<boolean> {
        return stableTransitions(this._requireCanActivateSettingsUsers(), this.permissionsService);
    }

    public canActivateSettingsGroups(): Observable<boolean> {
        return stableTransitions(this._requireCanActivateSettingsGroups(), this.permissionsService);
    }

    public canActivateSettingsAccounts(): Observable<boolean> {
        return stableTransitions(this._requireCanActivateSettingsAccounts(), this.permissionsService);
    }

    private _requirePermissionUserAdmin(): Observable<boolean> {
        if (!this.mPermissionUserAdmin) {
            const subject = new ReplaySubject<boolean>(1);
            this.subscriptions.add(this.permissionsService.permissions(), () => {
                this.subscriptions.add(this.permissionsService.hasPermission(Permissions.userAdmin()), (allowed) => {
                    subject.next(allowed);
                });
            });
            this.mPermissionUserAdmin = subject;
        }
        return this.mPermissionUserAdmin;
    }

    private _requireAccountAdmin(): Observable<boolean> {
        // Rather than looking for a security permission here, we're looking for a permission stored in the current user (if there is one).
        // This is a bit of a shortcut since actually using Settings|Users involves several permissions, some of which are referenced
        // indirectly. For example, listing users for an account is handled by GET /api/v2/user without a separate permission. Inviting
        // new users requires Permissions.account_invite()
        if (!this.mAccountAdmin) {
            const subject = new ReplaySubject<boolean>(1);
            this.subscriptions.add(this.authService.onAuthState, (event) => {
                if (!event.authenticated) {
                    subject.next(false);
                }
                else {
                    this.subscriptions.add(this.userService.getUser(event.userId), (user) => {
                        // To be considered an account admin, a user has to be assigned to an account and have the ACCOUNT_ADMIN user
                        // permission.
                        const active = user.status === 'ACTIVE' && !user.disabled;
                        const hasAccount = !!user.account_id;
                        const isAccountAdmin = (user.permissions ?? []).includes(ACCOUNT_ADMIN);
                        subject.next(active && hasAccount && isAccountAdmin);
                    });
                }
            });
            this.mAccountAdmin = subject;
        }
        return this.mAccountAdmin;
    }

    private _requireActiveGroupAdmin(): Observable<boolean> {
        if (!this.mActiveGroupAdmin) {
            const subject = new ReplaySubject<boolean>(1);
            // Subscribe to permission changes rather than authentication state, as the permission service will get
            // notified about changes that might affect the current user's permissions even when the authentication
            // state does not change.
            this.subscriptions.add(this.permissionsService.permissions(), () => {
                switch (this.authService.getAuthenticationState()) {
                    case AuthenticationState.Valid: {
                        this.subscriptions.add(this.userService.getUser(this.authService.currentUserId), (user: UserResponse) => {
                            let isActiveGroupAdmin = false;

                            for (const membership of user.memberships || []) {
                                if (membership.status === 'ACTIVE' && membership.permissions.includes(GroupPermission.GroupAdmin)) {
                                    isActiveGroupAdmin = true;
                                    break;
                                }
                            }
                            subject.next(isActiveGroupAdmin);
                        });
                        break;
                    }
                    case AuthenticationState.Invalid:
                        subject.next(false);
                        break;

                    case AuthenticationState.Pending:
                        // Do nothing
                        break;
                }
            });

            this.mActiveGroupAdmin = subject;
        }
        return this.mActiveGroupAdmin;
    }

    private _requirePermissionViewAccounts(): Observable<boolean> {
        if (!this.mPermissionViewAccounts) {
            const subject = new ReplaySubject<boolean>(1);
            this.subscriptions.add(this.permissionsService.permissions(), () => {
                this.subscriptions.add(this.permissionsService.hasPermission(Permissions.accountViewAll()), (allowed) => {
                    subject.next(allowed);
                });
            });
            this.mPermissionViewAccounts = subject;
        }
        return this.mPermissionViewAccounts;
    }

    private _requireCanActivateSettingsUsers(): Observable<boolean> {
        if (!this.mCanActivateSettingsUsers) {
            this.mCanActivateSettingsUsers = this.replayAtLeastOneOf([
                this._requirePermissionUserAdmin(),
                this._requireAccountAdmin(),
            ]);
        }

        return this.mCanActivateSettingsUsers;
    }

    private _requireCanActivateSettingsGroups(): Observable<boolean> {
        if (!this.mCanActivateSettingsGroups) {
            this.mCanActivateSettingsGroups = this.replayAtLeastOneOf([
                this._requirePermissionUserAdmin(),
                this._requireActiveGroupAdmin(),
            ]);
        }
        return this.mCanActivateSettingsGroups;
    }

    private _requireCanActivateSettingsAccounts(): Observable<boolean> {
        // Nothing else to do since we just alias mCanActivateSettingsAccounts to mPermissionViewAccounts
        return this._requirePermissionViewAccounts();
    }

    private _requireCanActivateSettings(): Observable<boolean> {
        if (!this.mCanActivateSettings) {
            this.mCanActivateSettings = this.replayAtLeastOneOf([
                this._requireCanActivateSettingsUsers(),
                this._requireCanActivateSettingsGroups(),
                this._requireCanActivateSettingsAccounts(),
            ]);
        }

        return this.mCanActivateSettings;
    }

    private replayAtLeastOneOf(components: Array<Observable<boolean>>): Observable<boolean> {
        return replay(this.subscriptions.limit(atLeastOneOf(components)), 1);
    }
}

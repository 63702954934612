<div class="stl-options-main-container" [@showStlOptions]="stlEnabled">
    <mat-card appearance="outlined" class="stl-options-card">
        <mat-card-content class="stl-options-card-content">
            <div class="stl-options-row" *ngIf="enableCoordinatesChange">
                <mat-select class="stl-options-coordinates-selector" [(ngModel)]="coordinateSystem"
                            (selectionChange)="onChangeImageCoordinates()">
                    <mat-option *ngFor="let cs of coordinateSystems" [value]="cs">
                        {{cs.titleKey|translate}}
                    </mat-option>
                </mat-select>
            </div>
            <div class="stl-options-row stl-options-img-opacity-row" *ngIf="hasSlices">
                <span i18n="@@caseLeadLocationStlOptionsImageOpacity" style="margin-right: 5px;">
                    {{'caseLeadLocationStlOptionsImageOpacity'|translate}} ({{imageOpacity|number: '1.1'}}):
                </span>
                <div class="image-opacity-container">
                    <mat-slider class="image-opacity-slider" min="0" [max]="1" step="0.1" [disabled]="disableSlider"
                                color="accent">
                        <input matSliderThumb #ngSliderThumb="matSliderThumb"
                               (input)="onImageOpacityChange(ngSliderThumb.value)" [(value)]="imageOpacity"/>
                    </mat-slider>
                </div>
            </div>
            <div class="stl-options-row-slices" *ngIf="hasSlices">
                <div class="stl-options-row-slice-pos mat-typography">
                    <span i18n="@@caseLeadLocationStlOptionsCurrentSlice">{{'caseLeadLocationStlOptionsCurrentSlice'|translate}}
                        : <b>{{getSlicesIndex()}}</b></span>
                </div>
                <div class="stl-options-row-slice-slider">
                    <span class="stl-options-slider-text mat-typography" style="margin-right: 5px;">0</span>
                    <mat-slider class="stl-options-slider"
                                min="0"
                                [max]="numOfSlices"
                                step="1"
                                color="accent"
                                [disabled]="disableSlider">
                        <input matSliderThumb
                               (input)="onSlicesSliderChange(ngSliderThumb.value)"
							   #ngSliderThumb="matSliderThumb"
                               [(value)]="sliceIndex"/>
                    </mat-slider>
                    <span class="stl-options-slider-text mat-typography"
                          style="margin-left: 5px;">{{numOfSlices}}</span>
                </div>
            </div>
            <div class="stl-options-container">
                <div class="stl-options-column">
                    <span class="stl-options-side-text mat-typography">{{'stlOptionsElementLeft'|translate}}</span>
                    <mat-checkbox *ngFor="let element of leftElements"
                                  (change)="onDisplayElementChange(element.id(), $event.checked)"
                                  [checked]="element.checked"
                                  [disabled]="disabled(element)"
                                  class="stl-options-item mat-typography">
                        {{element.titleKey | translate}}
                    </mat-checkbox>
                </div>
                <div class="stl-options-column">
                    <span class="stl-options-side-text mat-typography">{{'stlOptionsElementRight'|translate}}</span>
                    <mat-checkbox *ngFor="let element of rightElements"
                                  (change)="onDisplayElementChange(element.id(), $event.checked)"
                                  [checked]="element.checked"
                                  [disabled]="disabled(element)"
                                  class="stl-options-item mat-typography">
                        {{element.titleKey | translate}}
                    </mat-checkbox>
                </div>
                <div class="stl-options-column">
                    <div class="stl-options-side-text">
                        <mat-checkbox
                                [checked]="allSelected"
                                [indeterminate]="someSelected"
                                (change)="onBulkElementDisplaysChange($event.checked)">{{'stlOptionsElementSelectAll'|translate}}
                        </mat-checkbox>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>


import { Injectable } from '@angular/core';
import {
    ApiService,
    BooleanResponse,
    DeleteUserResponse,
    MessageResponse,
    TokenResponse,
    UserResponse
} from './api.service';
import { Observable } from 'rxjs';

export {
    BooleanResponse, MessageResponse, TokenResponse, UserResponse as User, DeleteUserResponse,
    DescribableUser, compareUsersByDescription, userDescription, userSearchableText, userLastAndFirstName
};

@Injectable()
export class UserService {

    constructor(private api: ApiService) {
    }

    public getUsers(forUser: string | null = null): Observable<Array<UserResponse>> {
        return this.api.getUsers(forUser);
    }

    public createNewUser(newUser: any): Observable<any> {
        return this.api.createNewUser(newUser);
    }

    public getUser(userId: string): Observable<UserResponse> {
        return this.api.getUser(userId);
    }

    public updateUser(userId: string, updates: any): Observable<any> {
        return this.api.updateUser(userId, updates);
    }

    public deleteUser(userId: string): Observable<DeleteUserResponse> {
        return this.api.deleteUser(userId);
    }

    public changePasswordWithToken(userId: string, newPassword: string, oldPassword?: string): Observable<BooleanResponse> {
        return this.api.changePassword(userId, newPassword, oldPassword);
    }

    public changePasswordNotAuthenticated(exToken: string, userId: string, newPassword: string, oldPassword?: string): Observable<BooleanResponse> {
        return this.api.changePasswordNotAuthenticated(exToken, userId, newPassword, oldPassword);
    }

    public resetPassword(email: string): Observable<BooleanResponse> {
        return this.api.resetPassword(email);
    }
}

interface DescribableUser {
    email: string;
    first_name?: string;
    last_name?: string;
}

function userDescription(user: DescribableUser): string {
    const lastAndFirstName = userLastAndFirstName(user);
    return lastAndFirstName ? `${lastAndFirstName} (${user.email})` : user.email;
}

function userSearchableText(user: DescribableUser): string {
    // Return non-null, non-empty strings separated by spaces
    return [user.last_name, user.first_name, user.email].filter(x => !!x).join(' ');
}

function userLastAndFirstName(user: DescribableUser): string {
    return [user.last_name, user.first_name].filter(x => !!x).join(', ');
}

function compareUsersByDescription(a: DescribableUser, b: DescribableUser): number {
    return userDescription(a).localeCompare(userDescription(b), undefined, {sensitivity: 'base'});
}

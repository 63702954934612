import { EventEmitter, Injectable } from '@angular/core';
import { interval, Observable, ReplaySubject, Subject } from 'rxjs';
import { ApiError, ApiService, ContextInformation, TokenResponse, UserResponse } from './api.service';
import { Subscriptions } from '../tools/subscriptions.class';
import { map, tap } from 'rxjs/operators';
import { SavedLanguageKey } from '../tools/languages.class';
import { Features } from '../security/feature.class';
import { NotificationsService } from '../main-nav/notifications.service';
import { PasswordPolicy, STRONG, WEAK } from '../tools/password-policy.class';

export { TokenResponse };

export class AuthenticationFailure implements Error {
    public name = 'AuthenticationFailure';
    public message = 'Authentication failed';
}

export class AuthenticationEvent {
    constructor(public authenticated: boolean, public userId: string, public username: string) {
    }
}

export enum AuthenticationState {
    Invalid, Pending, Valid, Expired
}

export class LoginResponse {
    userId: string;
    email: string;
    accessToken: string;
    expire_in: number | null;
    expired: boolean;
}

@Injectable()
export class AuthService {
    private static REFRESH_TOKEN_INTERVAL_MS = 1000 * 60 * 10;
    // 7 days before password expiration, suggest change password on login
    public static SUGGEST_PW_CHANGE = 7 * 24 * 60 * 60;

    private static readonly CURRENT_TOKEN: string = 'auth_token';
    private static readonly CURRENT_USER_NAME: string = 'auth_user'; // email/username
    private static readonly CURRENT_USER_ID: string = 'auth_user_id';
    private static readonly CURRENT_ACCOUNT_ID: string = 'auth_account_id';
    private static readonly CURRENT_HOME_CONTEXT: string = 'home_context';
    public readonly afterLogin = new EventEmitter<AuthenticationEvent>();
    public readonly beforeLogout = new EventEmitter<AuthenticationEvent>();
    public readonly afterLogout = new EventEmitter<AuthenticationEvent>();
    public readonly onAuthState = new ReplaySubject<AuthenticationEvent>(1);

    private authenticationState: AuthenticationState;
    private subscriptions = new Subscriptions();
    private _logoutReason: string;
    private _acceptedTos = -1;
    private _expireToken: string = null;
    private passwordPolicy = WEAK;

    constructor(private api: ApiService) {
        this.authenticationState = this.token ? AuthenticationState.Pending : AuthenticationState.Invalid;
    }

    public start(): void {
        this.api.token = this.token;

        // Copy login and logout events to a replay subject so that subscribers can always access the most recent,
        // state-defining event.
        this.subscriptions.add(this.afterLogin, event => this.onAuthState.next(event));
        this.subscriptions.add(this.afterLogout, event => this.onAuthState.next(event));

        // If there is a previously existing token, attempt to refresh it immediately.
        // and refresh the user data. This is done only once at start
        const token = this.token;
        this.refreshToken(() => {
            this.api.getUser(this.currentUserId).subscribe((user: UserResponse) => {
                this.finishLogin(user, token);
            });
        });

        // Attempt to refresh the token periodically
        this.subscriptions.add(interval(AuthService.REFRESH_TOKEN_INTERVAL_MS), () => this.refreshToken());
    }

    public stop(): void {
        this.subscriptions.cancel();
        this.authenticationState = AuthenticationState.Pending;
    }

    public login(email: string, password: string): Observable<LoginResponse> {
        const result = new Subject<LoginResponse>();

        this.subscriptions.add(this.api.auth({username: email, password}),
            (response: TokenResponse) => {
                // if the password expired we save the email, userId and token that we got from the server
                // and notify the subscriber that the response arrived
                if (response.expired) {
                    this.authenticationState = AuthenticationState.Expired;
                    // we save the expired token that we got from the server in memory, this is not a regular token
                    this._expireToken = response.access_token;
                    this.passwordPolicy = response.strong_password ? STRONG : WEAK;
                    this.setCurrentUser(email, response.user_id);
                    this.api.token = response.access_token;
                    result.next({
                        userId: response.user_id,
                        email,
                        accessToken: null,
                        expire_in: response.expire_in,
                        expired: true
                    });
                }
                else {
                    this._expireToken = null;
                    this.setToken(response.access_token);
                    this.subscriptions.add(this.api.getUser(response.user_id),
                        (user: UserResponse) => {
                            this.finishLogin(user, response.access_token);
                            result.next({
                                userId: response.user_id,
                                email: user.email,
                                expire_in: response.expire_in,
                                accessToken: response.access_token,
                                expired: false
                            });
                            result.complete();
                        },
                        error => {
                            result.error(error);
                        });
                }
            },
            error => {
                if (error instanceof ApiError && error.status === 401) {
                    result.error(new AuthenticationFailure());
                }
                else {
                    result.error(error);
                }
            }
        );

        return result;
    }

    public tokenLogin(userId: string, token: string): Observable<LoginResponse> {
        // We need to set the token before completing the login so that we can retrieve the user information.
        this.setToken(token);
        return this.api.getUser(userId).pipe(
            tap((user: UserResponse) => {
                this.finishLogin(user, token);
            }),
            map((user: UserResponse) => {
                return {
                    userId,
                    email: user.email,
                    accessToken: token,
                    expire_in: 0,
                    expired: false
                };
            })
        );
    }

    public authCodeLogin(authCode: string): Observable<LoginResponse> {
        this.setToken(null);

        const result = new Subject<LoginResponse>();

        this.api.redeemAuthCode(authCode).subscribe({
            next: (tokenResponse: TokenResponse) => {
                this.setToken(tokenResponse.access_token);
                this.api.getUser(tokenResponse.user_id).subscribe({
                    next: (user: UserResponse) => {
                        this.finishLogin(user, tokenResponse.access_token);
                        result.next({
                            userId: tokenResponse.user_id,
                            email: user.email,
                            accessToken: tokenResponse.access_token,
                            expire_in: tokenResponse.expire_in,
                            expired: tokenResponse.expired
                        });
                    },
                    error: (e) => {
                        result.error(e);
                    }
                });
            },
            error: e => {
                result.error(e);
            }
        });

        return result;
    }

    public logout(reason = '') {
        if (this.authenticationState !== AuthenticationState.Invalid) {
            const event = new AuthenticationEvent(false, this.currentUserId, this.currentUsername);
            this.beforeLogout.next(event);
            this._expireToken = null;
            this.passwordPolicy = WEAK;
            this.setTosVersion(null);
            this.setToken(null);
            this.setHomeContext(null);
            this.setCurrentUser(null, null);
            this.setAccountId(null);
            this.setLogoutReason(reason);
            this.clearLocalStorage([SavedLanguageKey, NotificationsService.shownAlerts]);
            this.afterLogout.next(event);
        }
    }

    public isAuthed(): boolean {
        return this.authenticationState === AuthenticationState.Valid;
    }

    public getAuthenticationState(): AuthenticationState {
        return this.authenticationState;
    }

    public currentPasswordPolicy(): PasswordPolicy {
        return this.passwordPolicy;
    }

    get expireToken(): string | null {
        return this._expireToken;
    }

    get token() {
        return localStorage.getItem(AuthService.CURRENT_TOKEN);
    }

    get currentUsername(): string | null {
        return localStorage.getItem(AuthService.CURRENT_USER_NAME);
    }

    get currentUserId(): string | null {
        return localStorage.getItem(AuthService.CURRENT_USER_ID);
    }

    get logoutReason(): string {
        return this._logoutReason;
    }

    get acceptedTos(): number {
        return this._acceptedTos;
    }

    private finishLogin(user: UserResponse, token: string) {
        this.passwordPolicy = user.enabled_features.includes(Features.STRONG_PASSWORD.name) ? STRONG : WEAK;
        this.setTosVersion(user.accepted_tos);
        this.setToken(token);
        this.setCurrentUser(user.email, user.id);
        this.setHomeContext(user.home_context);
        this.setAccountId(user.account_id);
        this.setLogoutReason(null);
        this.afterLogin.next(new AuthenticationEvent(true, user.id, user.email));
    }

    private refreshToken(successCallback = null) {
        const currentToken = this.token;
        if (currentToken) {
            this.subscriptions.add(this.api.refreshToken(), (tokenResponse: TokenResponse) => {
                    this.requireAccessToken(tokenResponse);
                    // If the token has changed since the refresh started, do not overwrite
                    // it with the token just obtained.
                    if (currentToken === this.token) {
                        this.setToken(tokenResponse.access_token);
                    }
                    if (successCallback) {
                        successCallback();
                    }
                }, (error) => {
                    if (error.status === 401) {
                        // If the token refresh returns 401, it means the token is expired, or
                        // possibly that the user account has been disabled, etc. In any case,
                        // the user will not be able to do anything without re-authenticating.
                        this.logout('Session expired');
                    }
                    else {
                        console.log('Error trying to refresh token:');
                        console.log(error);
                    }
                }
            );
        }
    }

    private setToken(newToken: string) {
        if (newToken) {
            localStorage.setItem(AuthService.CURRENT_TOKEN, newToken);
            this.api.token = newToken;
            this.authenticationState = AuthenticationState.Valid;
        }
        else {
            localStorage.removeItem(AuthService.CURRENT_TOKEN);
            this.authenticationState = AuthenticationState.Invalid;
        }
    }

    private setCurrentUser(newUsername: string, newUserId: string) {
        if (newUsername) {
            localStorage.setItem(AuthService.CURRENT_USER_NAME, newUsername);
        }
        else {
            localStorage.removeItem(AuthService.CURRENT_USER_NAME);
        }
        if (newUserId) {
            localStorage.setItem(AuthService.CURRENT_USER_ID, newUserId);
        }
        else {
            localStorage.removeItem(AuthService.CURRENT_USER_ID);
        }
    }

    private setHomeContext(context: ContextInformation | null) {
        localStorage.setItem(AuthService.CURRENT_HOME_CONTEXT, context !== null ? JSON.stringify(context) : null);
    }

    private setAccountId(accountId: string | null) {
        localStorage.setItem(AuthService.CURRENT_ACCOUNT_ID, accountId);
    }

    private setLogoutReason(newReason: string) {
        this._logoutReason = newReason;
    }

    private setTosVersion(acceptedTos: number) {
        this._acceptedTos = acceptedTos;
    }

    private clearLocalStorage(exclude: Array<string>) {
        const saved = {};
        // if the key does not exist we get null back, and then we save it, we do not want to do that
        exclude.forEach(key => {
            const value = localStorage.getItem(key);
            if (value !== null) {
                saved[key] = localStorage.getItem(key);
            }
        });
        localStorage.clear();
        Object.keys(saved).forEach(key => localStorage.setItem(key, saved[key]));
    }

    private requireAccessToken(data: TokenResponse) {
        if (!data.access_token) {
            throw new Error('API error: successful authentication should provide access token');
        }
    }
}

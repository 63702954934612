import { Feature } from './feature.class';

// Match the permissions.py code on the BE
const ACCOUNT = 'ACCOUNT';
const AUDIT = 'AUDIT';
const CASE = 'CASE';
const CONFIG = 'CONFIG';
const FEATURE = 'FEATURE';
const FLOW = 'FLOW';
const GROUP = 'GROUP';
const INVITATION = 'INVITATION';
const REPORT = 'REPORT';
const USER = 'USER';

const ACCEPT = 'ACCEPT';
const ADMIN = 'ADMIN';
const AUTH_CODE = 'AUTH_CODE';
const CANDIDATES = 'CANDIDATES';
const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
const CREATE = 'CREATE';
const ARCHIVE = 'ARCHIVE';
const RESTORE = 'RESTORE';
const DELETE = 'DELETE';
const DOWNLOAD_ELEMENT = 'DOWNLOAD_ELEMENT';
const FORCE_PASSWORD = 'FORCE_PASSWORD';
const FORCE_SUBMIT = 'FORCE_SUBMIT';
const INVITE = 'INVITE';
const LIST = 'LIST';
const LIST_ARCHIVED = 'LIST_ARCHIVED';
const LOG_EVENT = 'LOG_EVENT';
const MODIFY = 'MODIFY';
const PUBLISH = 'PUBLISH';
const QUERY = 'QUERY';
const REGISTRATION_METHODS = 'REGISTRATION_METHODS';
const REVIEW = 'REVIEW';
const UNPUBLISH = 'UNPUBLISH';
const SHARE = 'SHARE';
const SUBMIT = 'SUBMIT';
const SUBMIT_TRANSFORM = 'SUBMIT_TRANSFORM';
const TRANSFER = 'TRANSFER';
const TRIGGER = 'TRIGGER';
const UPLOAD = 'UPLOAD';
const VIEW = 'VIEW';
const VIEW_PHI = 'VIEW_PHI';

const ALL = 'ALL';
const ALL_RUNNING = 'ALL_RUNNING';

// A permission is an array, each element of which is a string or an array of strings
export type Permission = (string | string[])[];

// noinspection JSUnusedGlobalSymbols
export class Permissions {

    public static userAdmin(): Permission {
        return [USER, ADMIN];
    }

    public static userCreate(): Permission {
        return [USER, CREATE];
    }

    public static userView(userId: string): Permission {
        return [USER, VIEW, userId];
    }

    public static userModify(userId: string, apiNames: string[]): Permission {
        return [USER, MODIFY, userId, apiNames];
    }

    public static userDelete(userId: string): Permission {
        return [USER, DELETE, userId];
    }

    public static userChangePassword(userId: string): Permission {
        return [USER, CHANGE_PASSWORD, userId];
    }

    public static userForcePassword(userId: string): Permission {
        return [USER, FORCE_PASSWORD, userId];
    }

    public static userAuthCode(userId: string): Permission {
        return [USER, AUTH_CODE, userId];
    }

    public static listAllUsers(): Permission {
        return [USER, LIST, ALL];
    }

    public static listAccountUsers(accountId: string): Permission {
        return [USER, LIST, ACCOUNT, accountId];
    }

    public static invitationCreate(groupId: string = null): Permission {
        return [INVITATION, CREATE, groupId];
    }

    public static invitationAccept(invitationId: string): Permission {
        return [INVITATION, ACCEPT, invitationId];
    }

    public static groupCreate(): Permission {
        return [GROUP, CREATE];
    }

    public static groupView(groupId: string): Permission {
        return [GROUP, VIEW, groupId];
    }

    public static groupModify(groupId: string): Permission {
        return [GROUP, MODIFY, groupId];
    }

    public static groupDelete(groupId: string): Permission {
        return [GROUP, DELETE, groupId];
    }

    public static groupAdmin(groupId: string): Permission {
        return [GROUP, ADMIN, groupId];
    }

    public static groupViewAll(): Permission {
        return [GROUP, VIEW, ALL];
    }

    public static groupInvite(groupId: string, userId?: string): Permission {
        if (userId) {
            return [GROUP, INVITE, groupId, userId];
        }
        else {
            return [GROUP, INVITE, groupId];
        }
    }

    public static groupCandidates(groupId: string): Permission {
        return [GROUP, CANDIDATES, groupId];
    }

    public static caseCreateForUser(userId): Permission {
        return [CASE, CREATE, USER, userId];
    }

    public static caseCreateForGroup(groupId): Permission {
        return [CASE, CREATE, GROUP, groupId];
    }

    public static caseView(caseId: string): Permission {
        return [CASE, VIEW, caseId];
    }

    public static caseViewPHI(caseId: string): Permission {
        return [CASE, VIEW_PHI, caseId];
    }

    public static listUserCases(userId: string): Permission {
        return [CASE, LIST, USER, userId];
    }

    public static listGroupCases(groupId: string): Permission {
        return [CASE, LIST, GROUP, groupId];
    }

    public static listAccountCases(accountId: string): Permission {
        return [CASE, LIST, ACCOUNT, accountId];
    }

    public static listArchivedCases(): Permission {
        return [CASE, LIST_ARCHIVED];
    }

    public static caseModify(caseId, apiNames: string[]): Permission {
        return [CASE, MODIFY, caseId, apiNames];
    }

    public static caseUpload(caseId: string, elementName: string): Permission {
        return [CASE, UPLOAD, caseId, elementName];
    }

    public static caseElementDownload(caseId: string, elementId: string): Permission {
        return [CASE, DOWNLOAD_ELEMENT, caseId, elementId];
    }

    public static caseSubmit(caseId: string): Permission {
        return [CASE, SUBMIT, caseId];
    }

    public static caseSubmitTransformation(caseId: string): Permission {
        return [CASE, SUBMIT_TRANSFORM, caseId];
    }

    public static caseTrigger(caseId: string): Permission {
        return [CASE, TRIGGER, caseId];
    }

    public static caseArchive(caseId: string): Permission {
        return [CASE, ARCHIVE, caseId];
    }

    public static caseRestore(caseId: string): Permission {
        return [CASE, RESTORE, caseId];
    }

    public static caseDelete(caseId: string): Permission {
        return [CASE, DELETE, caseId];
    }

    public static caseShareWithGroup(caseId: string, groupId: string | null) {
        return [CASE, SHARE, caseId, GROUP, groupId];
    }

    public static caseTransfer(caseId: string, userId: string) {
        return [CASE, TRANSFER, caseId, userId];
    }

    public static anyCaseTransfer() {
        return [CASE, TRANSFER];
    }

    public static caseLogEvent(caseId: string) {
        return [CASE, LOG_EVENT, caseId];
    }

    public static caseReRunFlow(caseId: string) {
        return [CASE, FORCE_SUBMIT, caseId];
    }

    public static caseReview(caseId: string) {
        return [CASE, REVIEW, caseId];
    }

    public static caseUnpublish(caseId: string) {
        return [CASE, UNPUBLISH, caseId];
    }

    public static flowPublish(flowId: string): Permission {
        return [FLOW, PUBLISH, flowId];
    }

    public static listFlows(): Permission {
        return [FLOW, LIST, ALL_RUNNING];
    }

    public static accountCreate() {
        return [ACCOUNT, CREATE];
    }

    public static accountView(accountId: string) {
        return [ACCOUNT, VIEW, accountId];
    }

    public static accountViewAll() {
        return [ACCOUNT, VIEW, ALL];
    }

    public static accountModify(accountId: string) {
        return [ACCOUNT, MODIFY, accountId];
    }

    public static accountDelete(accountId: string) {
        return [ACCOUNT, DELETE, accountId];
    }

    public static accountInvite(accountId: string) {
        return [ACCOUNT, INVITE, accountId];
    }

    public static anyAccountInvite() {
        return [ACCOUNT, INVITE];
    }

    public static featureAvailable(feature: Feature) {
        return [FEATURE, feature.name];
    }

    public static auditQuery() {
        return [AUDIT, QUERY];
    }

    public static configListRegistrationMethods() {
        return [CONFIG, LIST, REGISTRATION_METHODS];
    }

    static reportsQuery() {
        return [REPORT, QUERY];
    }
}
